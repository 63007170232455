import { Pipe, PipeTransform } from '@angular/core';
import { ICategory } from 'wz-types';

import { ProductUrlPipe } from '../product-url/product-url.pipe';

@Pipe({
  name: 'categoryUrl'
})
export class CategoryUrlPipe implements PipeTransform {

  transform(c: ICategory): any {
    return `category/${c.id}/${new ProductUrlPipe().processName(c.name)}`;
  }

}
