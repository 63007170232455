import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderId'
})
export class OrderIdPipe implements PipeTransform {

  transform(id: string, args?: any): any {
    return id.match(/.{1,4}/g).join('-');
  }

}
