import { Component, Input, OnChanges, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { IListing } from 'wz-types/listings.d';
import { Globals } from '~shared/classes';

@Component({
  selector: 'wz-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductCarouselComponent implements OnInit, OnChanges {
  @Input() products: IListing[] = [];  
  @Input() listingId: string;
  @Input() hideNavigation: boolean;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  loadSlider = false;
  config: any;

  constructor() { }

  ngOnInit() {
    this.loadSlider = false;
    const config = {...Globals.defaultSwiperConfig};
    config.breakpoints = {
      280 : { slidesPerView: 1.8},
      320 : { slidesPerView: 2.2},
      350 : { slidesPerView: 2.2},
      411 : { slidesPerView: 2.2},
      540 : { slidesPerView: 3.2}, 
      768 : { slidesPerView: 3.5},
      1024: { slidesPerView: 4.9},
      1366 : { slidesPerView: 6.3}
    };
    this.config = config;
    if (this.listingId) {
      this.products = this.products.filter(p => p.id !== this.listingId);
    }
    this.loadSlider = true;
  }

  ngOnChanges() {
    this.loadSlider = false;
    if (this.listingId) {
      this.products = this.products.filter(p => p.id !== this.listingId);
    }
    this.loadSlider = true;
  }

}
