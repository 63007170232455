<div class="wedzee-mailing-list">
  <span class="mailing-bg-left"></span>
  <div class="wedzee-mailing-left">
    <h2>Signup for Wedzee’s Mailing List</h2>
    <p>Get updates on the latest and hottest trends</p>
  </div>
  <div class="wedzee-mailing-right">
    <form *ngIf="helpForm as form" [formGroup]="helpForm">
      <ng-container *ngIf="form.get('email') as ctrl">
        <input
          [formControl]="ctrl"
          placeholder="Enter your email address..."
          type="text"
        />
        <mat-error *ngIf="!ctrl.valid" style="color: #eb7d7e; padding-top: 5px;">Email address is required</mat-error>
      </ng-container>
      <button class="btn btn-danger btn-md" [disabled]="form.invalid" (click)="joinMailingList()">Sign Me Up!</button>
    </form>
  </div>
</div>