import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wza-two-options-dialog',
  templateUrl: './two-options-dialog.component.html',
  styleUrls: ['./two-options-dialog.component.scss']
})
export class TwoOptionsDialogComponent implements OnInit {
  title: string;
  message: string;
  options: string[];

  public static open(title: string, message: string, options: string[], matDialog: MatDialog): Observable<string> {
    const dialogRef = matDialog.open(TwoOptionsDialogComponent, {
      data: {
        title,
        message,
        options
      }
    });

    return dialogRef.afterClosed().pipe(
      take(1),
      filter((res: any) => !!res)
    );
  }

  constructor(
    public dialogRef: MatDialogRef<TwoOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.options = this.data.options;
  }

  dismiss(option?: string) {
    return this.dialogRef.close(option);
  }

}
