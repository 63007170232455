import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationEnd, Router, ActivatedRoute, Params, NavigationStart, RouterEvent } from '@angular/router';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { ICategory, ICategoryWithSubcategories } from 'wz-types/categories';
import { IInitialData } from 'wz-types/page-data.d';
import { IWedzeeSiteSettings } from 'wz-types/pages-policies';
import { FirestoreRefs, Globals } from '~shared/classes';
import { LoggingService, CommonService } from '~shared/services';
import { ListingsStore } from '~shared/stores';

const PRIMARY_COLOR_THEME_1 = '#47294F';
const LIGHT_PRIMARY_COLOR_THEME_1 = '#C9C2E0';
const OFFSET_COLOR_THEME_1 = '#F07D80';
const BTN_LIGHT_BG_THEME_1 = '#C9C2E0';
const BTN_LIGHT_COLOR_THEME_1 = '#47294F';
const PRIMARY_COLOR_THEME_2 = '#00B0C7';
const LIGHT_PRIMARY_COLOR_THEME_2 = '#FFF3F4';
const OFFSET_COLOR_THEME_2 = '#FF8395';
const BTN_LIGHT_BG_THEME_2 = '#FF8395';
const BTN_LIGHT_COLOR_THEME_2 = '#FFFFFF';
const PRIMARY_COLOR_THEME_3 = '#F77B9E';
const LIGHT_PRIMARY_COLOR_THEME_3 = '#FFD1CF';
const OFFSET_COLOR_THEME_3 = '#F77B9E';
const BTN_LIGHT_BG_THEME_3 = '#F77B9E';
const BTN_LIGHT_COLOR_THEME_3 = '#FFFFFF';
const PRIMARY_COLOR_THEME_4 = '#040707';
const LIGHT_PRIMARY_COLOR_THEME_4 = '#F9AEC0';
const OFFSET_COLOR_THEME_4 = '#F9AEC0';
const BTN_LIGHT_BG_THEME_4 = '#F9AEC0';
const BTN_LIGHT_COLOR_THEME_4 = '#FFFFFF';
const PRIMARY_COLOR_THEME_5 = '#F5AE93';
const LIGHT_PRIMARY_COLOR_THEME_5 = '#F6EDE9';
const OFFSET_COLOR_THEME_5 = '#F1B14D';
const BTN_LIGHT_BG_THEME_5 = '#F5AE93';
const BTN_LIGHT_COLOR_THEME_5 = '#FFFFFF';
const PRIMARY_COLOR_THEME_6 = '#C4B5A2';
const LIGHT_PRIMARY_COLOR_THEME_6 = '#D69E8F';
const OFFSET_COLOR_THEME_6 = '#D69E8F';
const BTN_LIGHT_BG_THEME_6 = '#C4B5A2';
const BTN_LIGHT_COLOR_THEME_6 = '#000000';
@Component({
  selector: 'wz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isInitializing = true;
  isLoading = Globals.loader.isLoading;
  isLoading$ = Globals.isLoading$.pipe(debounceTime(500));
  loadingMessage = undefined;
  loadingMsg = Globals.loader.message;
  siteSettings: IWedzeeSiteSettings;
  currentRoute: any;
  private lastNavigationTrigger: string;

  constructor(
    // Do not remove unused classes here. Some are imported just so they can be instantiated.
    private commonService: CommonService,
    private firestoreRefs: FirestoreRefs,
    private router: Router,
    private http: HttpClient,
    private loggingSrv: LoggingService,
    private firestore: AngularFirestore,
    private listingsStore: ListingsStore,
    private route: ActivatedRoute
  ) {
    let theme: string;
    if (this.isInIframe()) {
      theme = localStorage.getItem('theme');
    }
    this.initTheme(theme);
  }

  private checkContainerHeightInIframe(delay: number): void {
    setTimeout(function() {
      const pageContainerIframe = document.getElementsByClassName('page-container-iframe');
      if (pageContainerIframe.length !== 0) {
        const containerHeight = pageContainerIframe[0].clientHeight;
        if (isNaN(containerHeight) === false && containerHeight !== 0 && containerHeight <= 100) {
          window['parentIFrame'].sendMessage({ 'resize': 'true' });
        }
      }
    }, delay);
  }

  ngOnInit() { 
    Globals.isLoading$.pipe(
      debounceTime(500),
      map(() => this.loadingMessage = Globals.loader.message),
      takeUntil(Globals.destroy$)
    ).subscribe();

    this.route.queryParams.subscribe((params: Params) => {
      const theme = params['theme'];
      if (theme !== undefined) {
        localStorage.setItem('theme', theme);
      }
      this.initTheme(theme);

      const aff_uid = params['aff'];
      if (aff_uid !== undefined) {
        localStorage.setItem('aff_uid', aff_uid);
      }
      const id_user = params['id_user'];
      if (id_user !== undefined) {
        localStorage.setItem('id_user', id_user);
      }
      const id_token = params['id_token'];
      if (id_token !== undefined) {
        localStorage.setItem('id_token', id_token);
      }
      console.log(`app.comp aff_uid=${aff_uid} id_user=${id_user} id_token=${id_token}`);
    });
    
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd || e instanceof NavigationStart),
      map((e: RouterEvent) => {
        if (e instanceof NavigationStart) {
          this.lastNavigationTrigger = e.navigationTrigger;
          if (this.isInIframe() && 'parentIFrame' in window) {
            const url = this.getCleanedNavigationUrl(e.url);
            window['parentIFrame'].sendMessage({ 'navigation': 'start', 'url': url, 'scrollY': window.scrollY });
          }
        } else if (e instanceof NavigationEnd) {
          if (this.isInIframe()) {
            const theme = localStorage.getItem('theme');
            this.initTheme(theme);

            if ('parentIFrame' in window) {
              const url = this.getCleanedNavigationUrl(e.url);
              window['parentIFrame'].sendMessage({ 'navigation': 'end', 'url': url, 'fromBackButton': this.lastNavigationTrigger === 'popstate', 'scrollY': window.scrollY });

              this.checkContainerHeightInIframe(500);
              this.checkContainerHeightInIframe(1000);
              this.checkContainerHeightInIframe(1500);
            }
          }

          window.scroll(0, 0);
          this.currentRoute = e.url;
          this.loggingSrv.trackPageView(e.url);
        }
      }),
      takeUntil(Globals.destroy$)
    ).subscribe();

    Globals.isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    this.http.get(`${Globals.environment.apiUrl}page-data/init`).pipe(
      take(1),
      map((r: IInitialData) => {
        Globals.initialLoadData = r;
        Globals.siteSettings = r.settings;
        this.siteSettings = r.settings;
        Globals.colors = r.weddingColors;
        const lookup = {};
        const cats = r.categories;
        Globals.categories = cats
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map((c: ICategoryWithSubcategories) => {
          c.subCategories = <any>cats.filter(cat => cat.parentCategoryId === c.id);
          return c;
        });
        cats.forEach(c => lookup[c.id] = c);
        Globals.categoriesLookup = lookup;
        Globals.menuNavLinks = Globals.categories
          .filter(c => c.isInMainMenu)
          .sort((a: ICategory, b: ICategory) => {
            const isOther = (c) => c.name.indexOf('Other') > -1;
            return (a.name < b.name && !isOther(a)) || isOther(b) ? -1 : 1;
          })
          .map(c => {
            return { name: c.name, routerLink: ['products', 'category', c.id], doc: c };
          });
        Globals.stopLoading();
        this.isInitializing = false;
      })
    ).subscribe();
  }

  ngOnDestroy() {
    Globals.destroy$.next();
    Globals.destroy$.complete();
  }

  private getCleanedNavigationUrl(url): string {
    if (url.substring(0, 1) === '/') {
      url = url.substring(1);
    }
    const themeParam = '?theme';
    if (url.indexOf(themeParam) !== -1) {
      url = url.substring(0, url.indexOf(themeParam));
    }
    return url;
  }

  private initTheme(theme: string): void {
    if (theme !== undefined) {
      this.setTheme(theme);
    } else {
      this.setTheme('1');
    }
  }

  isLoadingFn(): boolean {
    return !!Globals.loader && !!Globals.loader.isLoading;
  }

  public isInIframe(): boolean {
    return this.commonService.isInIframe();
  }

  private setColors(primaryColor: string, lightPrimaryColor: string, offsetColor: string, btnLightBg: string, btnLightColor: string): void {
    document.documentElement.style.setProperty('--primary-color', primaryColor);
    document.documentElement.style.setProperty('--light-primary-color', lightPrimaryColor);
    document.documentElement.style.setProperty('--offset-color', offsetColor);
    document.documentElement.style.setProperty('--btn-light-bg', btnLightBg);
    document.documentElement.style.setProperty('--btn-light-color', btnLightColor);
  }

  private setTheme(theme: string): void {
    if (theme === '2' || theme === 'bsb') {
      this.setColors(PRIMARY_COLOR_THEME_2, LIGHT_PRIMARY_COLOR_THEME_2, OFFSET_COLOR_THEME_2, BTN_LIGHT_BG_THEME_2, BTN_LIGHT_COLOR_THEME_2);
    } else if (theme === '3') {
      this.setColors(PRIMARY_COLOR_THEME_3, LIGHT_PRIMARY_COLOR_THEME_3, OFFSET_COLOR_THEME_3, BTN_LIGHT_BG_THEME_3, BTN_LIGHT_COLOR_THEME_3);
    } else if (theme === '4') {
      this.setColors(PRIMARY_COLOR_THEME_4, LIGHT_PRIMARY_COLOR_THEME_4, OFFSET_COLOR_THEME_4, BTN_LIGHT_BG_THEME_4, BTN_LIGHT_COLOR_THEME_4);
    } else if (theme === '5') {
      this.setColors(PRIMARY_COLOR_THEME_5, LIGHT_PRIMARY_COLOR_THEME_5, OFFSET_COLOR_THEME_5, BTN_LIGHT_BG_THEME_5, BTN_LIGHT_COLOR_THEME_5);
    } else if (theme === '6') {
      this.setColors(PRIMARY_COLOR_THEME_6, LIGHT_PRIMARY_COLOR_THEME_6, OFFSET_COLOR_THEME_6, BTN_LIGHT_BG_THEME_6, BTN_LIGHT_COLOR_THEME_6);
    } else {
      this.setColors(PRIMARY_COLOR_THEME_1, LIGHT_PRIMARY_COLOR_THEME_1, OFFSET_COLOR_THEME_1, BTN_LIGHT_BG_THEME_1, BTN_LIGHT_COLOR_THEME_1);
    }
  }
}
