
import { Component, Input, OnInit } from '@angular/core';
import { ICategory } from 'wz-types/categories';

@Component({
  selector: 'lib-wz-allcategories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.scss']
})
export class AllCategoriesComponent implements OnInit {
  @Input() doc: ICategory = {} as any;
  constructor() { }

  ngOnInit() {
  }

}
