import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { delay, filter, map, mergeMap, take } from 'rxjs/operators';
import { IListing } from 'wz-types/listings';

import { Globals } from '../../classes/global.class';
import { User } from '../../classes/user.class';
import { ProductUrlPipe } from '../../pipes/product-url/product-url.pipe';
import { AlertService } from '../../services/alert/alert.service';
import { LikesByListingStore } from '../../stores/likes-by-listing.store';
import { ShareDialogComponent } from './../../dialogs/share-dialog/share-dialog.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-product-thumb',
  templateUrl: './product-thumb.component.html',
  styleUrls: ['./product-thumb.component.scss']
})
export class ProductThumbComponent implements OnChanges {
  @Input() isOnSellerPage: boolean;
  @Input() isSkeleton?: boolean;
  @Input() doc?: IListing = <any>{};
  @Input() isSwiper?: boolean;
  @Input() isAdminSite = false;
  listingLikeId: string;
  disableLike = false;
  canDelete$: Observable<boolean>;
  numLikes = '';
  minPriceFromVariation = 0;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private likesStore: LikesByListingStore,
    private alertSrv: AlertService
  ) {  }

  ngOnChanges() {
    if (this.doc && this.isOnSellerPage) {
      this.canDelete$ = Globals.user.SellerAccount.canDeleteListing(this.doc.id);
    } else if (this.doc && this.doc.numOfLikes > 0) {
      this.numLikes = `${this.doc.numOfLikes}`;
    }
  }


  getParentCategoryNames() {
    if (!(this.doc && this.doc.categoryIds)) {
      return [];
    }
    return this.doc.categoryIds.map((id) => Globals.categoriesLookup[id].name);
  }

  navigateToProductPage($event) {
    if (!!this.doc && !!this.doc.name && !this.isAdminSite && $event.target.className.indexOf('btnLikeIcon') === -1) {
      this.router.navigateByUrl(new ProductUrlPipe().transform(this.doc));
    }
  }

  toggleLike() {
    return () => {
      this.disableLike = true;
      if (this.isLiked()) {
        this.doc.numOfLikes--;
      } else {
        this.doc.numOfLikes = this.doc.numOfLikes || 0;
        this.doc.numOfLikes++;
      }
      this.likesStore.dispatch('toggleLike', this.doc.id).pipe(
        take(1),
        delay(500)
      ).subscribe(() => {
        this.disableLike = false;
      });
    };
  }

  isLiked() {
    return !!this.doc && !!User.doesLike[this.doc.id];
  }

  getLikeText() {
    return this.isLiked() ? 'Unlike' : 'Like';
  }

  comment() {
    return () => this.router.navigateByUrl(
    this.router.createUrlTree(
      [new ProductUrlPipe().transform(this.doc)], { queryParams: { comment: true } }
    ));
  }

  addToCart() {
    if (!this.doc.customizationNotes) {
      Globals.user.ShoppingCart.addUpdateItem({
        listingId: this.doc.id,
        qty: 1
      });
    } else {
      this.router.navigateByUrl(new ProductUrlPipe().transform(this.doc));
    }
  }

  removeFromCart() {
    Globals.user.ShoppingCart.remove(this.doc.id);
  }

  share() {
    ShareDialogComponent.open(`Share ${this.doc.name}`, new ProductUrlPipe().transform(this.doc), this.dialog).pipe(
      take(1)
    ).subscribe();
  }

  isInCart() {
    return !!Globals.user && Globals.user.isitemInCart(this.doc.id);
  }

  deleteListing() {
    this.alertSrv.confirm('Delete listing?', 'This listing will be gone forever.').pipe(
      filter((r: boolean) => r),
      mergeMap(() => Globals.user.SellerAccount.deleteListing(this.doc.id)),
      map(() => this.alertSrv.successToast('Listing deleted')),
      take(1)
    ).subscribe();
  }

  getMinimumPriceFromVariation(doc: IListing) {
    if (doc.minPriceIncludingVariation !== undefined) {
      return doc.minPriceIncludingVariation;
    }

    // minPriceIncludingVariation should exist and below should be removed; leaving for now as initial dev listings do not have this value
    let variationMinPrice = 1;
    let isVariation1VariesByPrice: any;
    let isVariation2VariesByPrice: any;
    this.minPriceFromVariation = variationMinPrice;
    const price: any[] = [];
    if (doc !== undefined && doc !== null) {
      if (doc.variation !== undefined && doc.variation !== null) {
        if (doc.variation.mergeGrid) {
          if (doc.variation.gridValues[0] !== undefined) {
            const obj = doc.variation.gridValues[0];
            const count = Object.keys(obj).length;
            for (let i = 0; i < count; i++) {
              const key = Object.keys(obj)[i];
              const value = obj[key];
              price.push(parseFloat(value[2].Price).toFixed(2));
            }
          }
        } else {
          if (doc.variation.gridValues !== undefined) {
            for (let i = 0; i < doc.variation.selectedVariations[0].variationTypeOptions.length; i++) {
              if (doc.variation.selectedVariations[0].variationTypeOptions[i].name === 'Price') {
                isVariation1VariesByPrice = true;
              }
            }
            if (doc.variation.selectedVariations.length > 1) {
              for (let j = 0; j < doc.variation.selectedVariations[1].variationTypeOptions.length; j++) {
                if (doc.variation.selectedVariations[1].variationTypeOptions[j].name === 'Price') {
                  isVariation2VariesByPrice = true;
                }
              }
            }
            if (isVariation1VariesByPrice) {
              if (doc.variation.gridValues[0] !== undefined) {
                const obj = doc.variation.gridValues[0];
                const count = Object.keys(obj).length;
                for (let i = 0; i < count; i++) {
                  const key = Object.keys(obj)[i];
                  const value = obj[key];
                  price.push(parseFloat(value[2].Price).toFixed(2));
                }
              }
            }
            if (isVariation2VariesByPrice) {
              if (doc.variation.gridValues[1] !== undefined) {
                const obj = doc.variation.gridValues[1];
                const count = Object.keys(obj).length;
                for (let i = 0; i < count; i++) {
                  const key = Object.keys(obj)[i];
                  const value = obj[key];
                  price.push(parseFloat(value[2].Price).toFixed(2));
                }
              }
            }
          }
        }
      }
    }
    if (price.length > 0) {
      const minPrice = Math.min.apply(Math, price);
      if (!Number.isNaN(minPrice)) {
        variationMinPrice = minPrice;
      }
    }
    return variationMinPrice;
  }

}
