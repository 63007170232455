import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-create-edit-item-page',
  templateUrl: './create-edit-item-page.component.html',
  styleUrls: ['./create-edit-item-page.component.scss']
})
export class CreateEditItemPageComponent implements OnInit {
  isEditing: boolean;
  itemId: string;
  itemForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private firestore: AngularFirestore,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    this.isEditing = !!routeParams && !!routeParams.itemId;
    this.itemId = this.isEditing ? routeParams.itemId : this.firestore.createId();

    this.itemForm = this.formBuilder.group({
      id: [this.itemId, Validators.required],
      primaryImgUrl: [undefined, Validators.required],
      images: [[], Validators.required],
      name: [undefined, Validators.required],
      categoryIds: [undefined, Validators.required],
      colorId: [undefined],
      description: [undefined, Validators.required],
      tagIds: [undefined],
      price: [undefined, Validators.required],
      totalQtyAvailable: [undefined, Validators.required],
      sku: [undefined],
      variations: [undefined],
      shipFromAddressId: [undefined, Validators.required],
      daysProcessingTime: [undefined, Validators.required],
      isFreeShipping: [undefined],
      handlingFeeInDollars: [undefined],
      weightInOz: [undefined, Validators.required],
      lengthInches: [undefined, Validators.required],
      widthInches: [undefined, Validators.required],
      heightInches: [undefined, Validators.required]
    });
  }

  /**
   * Gets a form for a single image.
   */
  getImageForm(data?: any) {
    const imgUrl = !!data && data.imgUrl ? data.imgUrl : undefined;
    const description = !!data && data.description ? data.description : undefined;
    return this.formBuilder.group({
      imgUrl: [imgUrl, Validators.required],
      description: [description]
    });
  }

  /**
   * Gets the form for a single variation
   */
  getVariationForm(data?: any) {
    // const type = !!data && !!data.type ? data.type : undefined;
    // const qtyByOptionName = !!data && data.qtyByOptionName ? data.qtyByOptionName : undefined;
    // return this.formBuilder.group({
    //   type: [type, Validators.required],
    //   qtyByOptionName: [qtyByOptionName, Validators.required]
    // });
  }

  addImage(imgUrl: string) {
    const imagesCtrl = this.itemForm.get('images');
    const currentImgs = imagesCtrl.value;
    currentImgs.push({ imgUrl: imgUrl, description: undefined });
    imagesCtrl.setValue(currentImgs);
  }

  updateImage(imgUrl: string, index: number) {

  }
}
