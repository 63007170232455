import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { ICroppedImage } from 'wz-types';

import { IAspectRatio } from '../../components';
import { ImageCompressionService } from '../../services/image-compression/image-compression.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-img-cropper-dialog',
  templateUrl: './img-cropper-dialog.component.html',
  styleUrls: ['./img-cropper-dialog.component.scss']
})
export class ImgCropperDialogComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  imageFile: Blob | File;
  croppedImg: ICroppedImage = <any>{};
  ratio: IAspectRatio;
  isBase64: boolean;
  base64Img: string;
  hasImgLoaded = false;

  constructor(
    public dialogRef: MatDialogRef<ImgCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private imageComprSrv: ImageCompressionService
  ) { }

  async ngOnInit() {
    this.ratio = this.data.aspectRatio;
    this.isBase64 = !!this.data.isBase64;
    if (!this.isBase64) {
      // this.imageFile = this.data.fileChangeEvent.target.files[0];
      const dataUri = await this.imageComprSrv.resizeImage(this.data.fileChangeEvent.target.files[0]);
      this.base64Img = dataUri;
    } else {
      this.base64Img = this.data.base64Img;
    }
    this.isBase64 = true;
  }

  imageCropped(event: ICroppedImage) {
    this.croppedImg = event;
  }

  imageLoaded() {
    this.hasImgLoaded = true;
  }

  loadImageFailed() {
    console.error('Load image failed!');
  }

  saveCroppedImage() {
    this.dialogRef.close(this.croppedImg);
    this.base64Img = null;
  }

  async rotate(direction: 'right' | 'left') {
    const degrees = direction === 'right' ? 90 : 270;
    this.base64Img = await this.imageComprSrv.rotateBase64Image(this.base64Img, degrees);
  }

}
