import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class WzTooltipComponent {
  @ViewChild('tooltip') tooltip: MatTooltip;
  @Input() matTooltip: string;
  @Input() matTooltipPosition = 'below';

  constructor() { }

  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if (this.tooltip && this.tooltip.hide) {
      this.tooltip.hide();
    }
  }
}
