import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { IAddress } from 'wz-types/address';
import { IStripePaymentToken } from 'wz-types/stripe';

import { Globals } from '../../classes';

declare const StripeCheckout: any;

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzStripePayment]'
})
export class StripePaymentDirective implements OnInit, OnDestroy {
  @Input() amountInDollars: number;
  @Input() shippingAddress?: IAddress;
  @Output() stripeToken: EventEmitter<IStripePaymentToken> = new EventEmitter();
  destroy$: Subject<void> = new Subject();
  stripeHandler: any;

  constructor(
  ) { }

  async ngOnInit() {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://checkout.stripe.com/checkout.js';
    document.head.appendChild(scriptTag);
    await new Promise((resolve, reject) => {
      scriptTag.onload = () => resolve();
    });
    this.stripeHandler = StripeCheckout.configure({
      key: Globals.environment.stripe.apiKey,
      image: 'assets/icon.svg',
      locale: 'auto',
      token: token => this.stripeToken.emit(token)
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:popstate')
  onpopstate() {
    if (!!this.stripeHandler) {
      this.stripeHandler.close();
    }
  }

  @HostListener('click', ['$event'])
  onClick() {
    // TODO: Come back here and see if we can use Stripe elements
    const options: any = {
      name: 'Wedzee',
      currency: 'USD',
      description: 'Pay for your cart with credit card.',
      zipCode: true,
      amount: (this.amountInDollars * 100),
      panelLabel: 'Place order',
    };
    if (!!this.shippingAddress.email) {
      options.email = this.shippingAddress.email;
    }
    this.stripeHandler.open(options);
  }
}
