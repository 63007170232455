import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[routerLinkNewTab]'
})
export class RouterLinkNewTabDirective {
  @Input() routerLinkNewTab: string | string[];

  constructor() { }

  @HostListener('click', ['$event']) onClick($event) {
    const host = `${location.host.indexOf('localhost') > -1 ? 'http://' : 'https://'}${location.host}/`;
    let url;
    if (Array.isArray(this.routerLinkNewTab)) {
      url = host + (<any>this.routerLinkNewTab).split('/').filter(s => !!s).join('/');
    } else {
      const path = this.routerLinkNewTab[0] === '/' ? this.routerLinkNewTab.substr(1) : this.routerLinkNewTab;
      url = host + path;
    }
    const el = document.createElement('a');
    el.href = url;
    el.target = '_blank';
    el.click();
  }

}
