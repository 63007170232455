import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[NoAutoComplete]',
})
export class NoAutoCompleteDirective implements AfterViewInit {
  private _chrome = navigator.userAgent.indexOf('Chrome') > -1;

  constructor(private renderer: Renderer2, private _el: ElementRef) {}

  ngAfterViewInit() {
    const that = this;
    setTimeout(() => {
      if (that._chrome) {
        that.renderer.setAttribute(that._el.nativeElement, 'autocomplete', 'off');
      } else {
        that.renderer.setAttribute(that._el.nativeElement, 'autocomplete', 'off_' + that.getRandomString(10));
      }
    }, 0);
  }

  getRandomString(length: number) {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
}
