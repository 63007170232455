import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'wz-impersonate-handler',
  templateUrl: './impersonate-handler.component.html',
  styleUrls: ['./impersonate-handler.component.scss']
})
export class ImpersonateHandlerComponent implements OnInit {

  constructor(
    private fbAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    const token = this.route.snapshot.queryParams.token;
    if (token) {
      await this.fbAuth.signInWithCustomToken(token);
      this.router.navigateByUrl('');
    }
  }

}
