<div class="product-category-container">
  <div class="product-title-row">
    <div class="mat-display-1 header">Popular Listings</div>
  </div>
  <div class="product-list-section">
    <div class="product-list-container">
      <div class="product-item" *ngFor="let c of featured">
        <wz-product [doc]="c" [isSwiper]="true"></wz-product>
      </div>
    </div>
  </div>
</div>