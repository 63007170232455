import { Component, Input, OnInit } from '@angular/core';
import { ICategory } from 'wz-types/categories';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  @Input() doc: ICategory = {} as any;

  constructor() { }

  ngOnInit() {
  }

}
