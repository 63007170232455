import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzScrollable]'
})
export class ScrollableDirective {
  public static scrolledToFooter$: Subject<void> = new Subject();
  @Input() scrollName: string;
  didEmit = false;

  footerPreviousHeight: number;

  constructor(
    private el: ElementRef
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    try {
      const top = event.target.scrollingElement.scrollTop; // CHANGES
      const height = event.target.scrollingElement.scrollHeight; //
      const offset = event.target.scrollingElement.offsetHeight; // ALWAYS THE SAME

      const isInsideFooter = this.scrollName === 'footer' && top > (height - top - offset);
      if (isInsideFooter && height !== this.footerPreviousHeight) {
        ScrollableDirective.scrolledToFooter$.next();
        this.footerPreviousHeight = height;
      }
    } catch (e) {

    }
  }

}
