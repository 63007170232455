import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IBannerNotification,
    IUserBannerNotification
} from 'wz-types';

import { Globals } from '../classes';
import { AppStore } from './../classes';

export type TBannerNotificationAction = 'getBannerNotifications' | 'getUserBannerNotification' | 'getUserBannerNotifications' | 'addUpdateUserBannerNotification';

@Injectable()
export class BannerNotificationsStore extends AppStore<IBannerNotification, TBannerNotificationAction> {
    fileName = 'banner-notifications.store.ts';
    cacheHeaders: HttpHeaders;

    constructor(
        private http: HttpClient
    ) {
        super({
            objectName: 'banner-notification',
            objectIdKey: 'id',
            objectGetFn: (id: string) => this.http.get(`${Globals.environment.apiUrl}banner-notifications/${id}`) as any
        });
        const apiUrl = Globals.environment.apiUrl;
        this.cacheHeaders = new HttpHeaders({ cacheInMemory: '1' });

        this.registerAction('getBannerNotifications', {
            type: 'get-nocache',
            dispatch: () => this.http.get(`${apiUrl}banner-notifications`) as any,
            map: (d: IBannerNotification) => d
        });

        this.registerAction('getUserBannerNotifications', {
            type: 'get-nocache',
            dispatch: (userId: string) => this.http.get(`${apiUrl}banner-notifications/${userId}`) as any,
            map: (d: IUserBannerNotification[]) => d
        });

        this.registerAction('getUserBannerNotification', {
            type: 'get-nocache',
            dispatch: (bannerNotificationId: string, userId: string) => this.http.get(`${apiUrl}banner-notifications/${bannerNotificationId}/${userId}`) as any,
            map: (d: IUserBannerNotification) => d
        });

        this.registerAction('addUpdateUserBannerNotification', {
            type: 'change',
            dispatch: (userBannerNotification: IUserBannerNotification) =>
                this.http.post(`${Globals.environment.apiUrl}banner-notifications/addUpdateUserBannerNotification`, {
                    userBannerNotification
                }),
            map: (l: IUserBannerNotification) => l,
        });
    }
}
