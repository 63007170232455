import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IDispute, IDisputeWithOrderAndListing } from 'wz-types/disputes';
import { IListing } from 'wz-types/listings';
import { IWedzeeOrder } from 'wz-types/orders';

import { AppStore, FirestoreRefs, Globals } from '../classes';
import { wzCatchObservableError } from '../services/logging/logging.service';
import { ListingsStore } from './listings.store';
import { OrdersStore } from './orders.store';

type TDisputeAction = 'getDisputes' | 'markDisputeAsResolved' | 'archiveDispute';

@Injectable()
export class DisputesStore extends AppStore<IDisputeWithOrderAndListing, TDisputeAction> {
    fileName = 'disputes.store.ts';


    constructor(
        private http: HttpClient,
        private listingsStore: ListingsStore,
        private ordersStore: OrdersStore
    ) {
        super({
            objectName: 'dispute',
            objectIdKey: 'id',
            objectGetFn: () => of(undefined)
        });

        this.registerAction('getDisputes', {
            type: 'get',
            dispatch: (isArchived?: boolean) => this.getDisputes(isArchived).pipe(
                map((r: any[]) => <any>{ disputes: r.map(d => <any>{ ...d, isArchived: !!isArchived }), isArchived })
            ),
            reduce: r => r.disputes,
            map: (r: { disputes: any[]; isArchived: boolean; }, state) =>
                Object.values(state).filter((d: IDispute) => !!(<any>d).isArchived === !!r.isArchived).sort((a, b) => b.createdTimestamp - a.createdTimestamp)
        });

        this.registerAction('archiveDispute', {
            type: 'change',
            dispatch: (disputeId: string) => from(FirestoreRefs.disputes.doc(disputeId).get()).pipe(
                mergeMap((snap: any) => {
                  const dispute: IDispute = snap.data();
                  return FirestoreRefs.archiveDisputes.doc(dispute.id).set(dispute);
                }),
                mergeMap(() => FirestoreRefs.disputes.doc(disputeId).delete()),
                map(() => disputeId)
            ),
            map: r => r,
            reduce: (id: string, state) => <any>{ ...state[id], isArchived: true }
        });

        this.registerAction('markDisputeAsResolved', {
            type: 'change',
            dispatch: (disputeId: string) => this.http.get(`${Globals.environment.apiUrl}dispute/${disputeId}/resolve`).pipe(
                map(() => disputeId)
            ),
            reduce: (id: string, state) => <any>{ ...state[id], isResolved: true },
            map: r => r
        });
    }

    private getDisputes(isArchived?: boolean): Observable<IDisputeWithOrderAndListing[]> {
        let disputes: IDispute[];
        return from((isArchived ? FirestoreRefs.archiveDisputes : FirestoreRefs.disputes).ref.orderBy('createdTimestamp', 'desc').get()).pipe(
          mergeMap((qSnap: any) => {
            disputes = qSnap.docs.map(d => d.data());
            const listingIds = disputes.map(d => d.listingId);
            const orderIds = disputes.map(d => d.orderId);
            const nextWithDispute = forkJoin([this.listingsStore.dispatch('getListingsByIds', listingIds), this.ordersStore.dispatch('getOrdersByIds', orderIds)]);
            return disputes.length > 0 ? nextWithDispute : of([[], []]);
          }),
          map((r: [IListing[], IWedzeeOrder[]]) => disputes.map((d: IDispute) => <IDisputeWithOrderAndListing>{
            ...d,
            listing: r[0].find(l => (l || {} as any).id === d.listingId),
            order: r[1].find(o => (o || {} as any).id === d.orderId),
          })),
          wzCatchObservableError(this.fileName, 'getDisputes()', true)
        );
      }
}
