import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-img-preview',
  templateUrl: './img-preview.component.html',
  styleUrls: ['./img-preview.component.scss']
})
export class ImgPreviewComponent implements OnInit {
  imgUrl: string;
  imgName: string;
  imgType: string;
  closeBtn: boolean;

  isListingImg: boolean;
  isListingPrimaryImg: boolean;

  constructor(
    public dialogRef: MatDialogRef<ImgPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  /**
   * Returns an observable of whether the user clicked to change the image.
   */
  public static open(imgUrl: string, matDialog: MatDialog, imgType?: string, imgName?: string, closeBtn?: boolean): Observable<any> {
    const dialogRef = matDialog.open(ImgPreviewComponent, {
      width: '90%',
      maxWidth: '1200px',
      height: 'auto',
      data: { imgUrl, imgType, closeBtn }
    });

    return dialogRef.afterClosed();
  }

  ngOnInit() {
    this.imgUrl = this.data.imgUrl;
    this.imgName = this.data.imgName;
    this.imgType = this.data.imgType;
    this.isListingImg = this.imgType === 'listingPrimary';
    this.isListingImg = this.imgType === 'listing';
    this.closeBtn = this.data.closeBtn;
  }

  closeWithAction(actionName: string) {
    this.dialogRef.close(actionName);
  }

}
