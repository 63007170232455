import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from 'wz-types/address';

import { ShoppingCart } from '../../classes';

@Pipe({
  name: 'streetAdress'
})
export class UiAdressPipe implements PipeTransform {

  transform(a: IAddress): any {
    return ShoppingCart.getUiAddress(a);
  }

}
