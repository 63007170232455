<ng-container>
    <div class="text-center cta buy-sell-tab-content" ngStyle.lt-md="height:370px !important" ngStyle.lt-md ="height:850px !important" >
      <div style="font-size: 22px; color:#000000; font-family:century_gothicregular !important; margin-bottom:45px; margin-top:20px;font-weight: bold;" ngStyle.xs="font-size:20px;margin-bottom:25px; margin-top:0px; padding: 0 20px; ">How Wedzee’s Affiliate Program Works</div>
      <div>
        <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column"  style="min-width: 200px !important;">
          <div fxFlex="calc(33%-20px)"  fxFlex.lt-sm="100" class="rsn">
            <span class="how-it-work-icon">1</span>
    
            <div style="width: 265px; float:left; position: relative; display: block;  height: 12px; margin-top : -100px; margin-bottom:  50px; margin-right:  15px; margin-left:  290px;"  class="dotted-line"
            fxShow.gt-sm="true" fxHide.lt-sm  fxHide.lt-md fxHide.lt-lg>        
            </div>              
            <div class="rsn-title">Apply for the Program</div>
            <div class="mat-subheading-2 rsn-detl" >
              Sign up today to get started. Once approved, our team will reach out to kick-off your onboarding. 
            </div>
          </div>
          <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
            <span class="how-it-work-icon">2</span>
            <div style="width: 265px; float:left; position: relative; display: block;  height: 12px; margin-top : -100px; margin-bottom:  50px; margin-right:  15px; margin-left:  290px;" class="dotted-line"
            fxShow.gt-sm="true" fxHide.lt-sm fxHide.lt-md fxHide.lt-lg >
            </div>
            <div class="rsn-title">Discover & Share Wedzee Items</div>
            <div class="mat-subheading-2 rsn-detl">
              Use your personalized affiliate link or direct users to your Wedzee storefront to share Wedzee items.
            </div>
          </div>
          <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
            <span class="how-it-work-icon">3</span>
            <div class="rsn-title">Get Paid</div>
            <div class="mat-subheading-2 rsn-detl">
              Earn 28% commission from Wedzee’s commission on each item sold.
            </div>
          </div>
        </div>
        <br>
        <button mat-flat-button color="accent" (click)="applyNow()"
          style="display: inline-block; font-family:century_gothicregular!important; margin:20px auto 40px;" class="bg-offset-color" ngStyle.xs="margin:0px auto 35px">
          Apply Today
        </button>
      </div>
    </div>
    <br>
  </ng-container>
