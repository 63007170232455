<div class="swiper-main" *ngIf="loadSlider">
<div class="swiper-container" [swiper]="config">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let c of colors">
      <wz-wedding-color [doc]="c" (click)="goToColorProducts(c.id)"></wz-wedding-color>
    </div>
  </div>

  <div class="swiper-scrollbar"></div>
  <div class="swiper-pagination"></div>
<!-- 
  <div style="filter: grayscale(100%);" class="swiper-button-prev"></div>
  <div style="filter: grayscale(100%);" class="swiper-button-next"></div> -->
</div>
</div>