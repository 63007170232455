import { Component, Input, OnInit } from '@angular/core';
import { IWeddingColor } from 'wz-types/home-page';

@Component({
  selector: 'lib-wz-allcolors',
  templateUrl: './all-colors.component.html',
  styleUrls: ['./all-colors.component.scss']
})
export class AllColorsComponent implements OnInit {
  @Input() doc: IWeddingColor;

  constructor() { }

  ngOnInit() {
  }

}
