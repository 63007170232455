<mat-card class="color-card slim-category-card">
  <div mat-card-image class="product-image" [style.background]="'url(' + doc.imgUrl + ')'"></div>
  <mat-card-footer class="text-left" style="padding-left: 0px; ">
    <mat-card-title class="text-left text-overflow-ellipsis colorTitle">
      {{doc.name}}
      <div
        class="coloredcircle"
        [ngStyle]="{ 'background-color': doc?.hexColor}"
      >
      </div>
    </mat-card-title>
  </mat-card-footer>
</mat-card>
