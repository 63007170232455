import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    const self = this;
    self.title = !!self.data ? self.data.title : undefined;
    self.message = !!self.data ? self.data.message : undefined;
  }

  close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

}
