import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { IPayPalCheckoutParams, IShoppingCartTotals } from 'wz-types';
import { IAddress } from 'wz-types/address';

import { Globals } from '../../classes';


declare const paypal: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.scss']
})
export class PaypalButtonComponent implements OnInit {
  private static paypalScript: BehaviorSubject<'notLoaded' | 'loaded' | 'loading'> = new BehaviorSubject('notLoaded');
  paypalConfig: any;
  buttonActions: any;
  paypalScriptSrc = 'https://www.paypalobjects.com/api/checkout.js';
  @Input() cartTotals: IShoppingCartTotals;
  @Input() shippingAddress: IAddress;
  @Output() paypalData: EventEmitter<IPayPalCheckoutParams> = new EventEmitter();
  @Output() complete: EventEmitter<void> = new EventEmitter();

  constructor(
  ) { }

  async ngOnInit() {
    PaypalButtonComponent.paypalScript.pipe(
      filter(val => val === 'loaded'),
      take(1)
    ).subscribe(val => {
      this.initConfig();
    });

    if (!(window as any).paypal && PaypalButtonComponent.paypalScript.value === 'notLoaded') {
      PaypalButtonComponent.paypalScript.next('loading');
      const scriptTag = document.createElement('script');
      scriptTag.src = this.paypalScriptSrc;
      document.head.appendChild(scriptTag);
      await new Promise((resolve, reject) => scriptTag.onload = () => resolve());
      PaypalButtonComponent.paypalScript.next('loaded');
    }
  }

  private initConfig(): void {
    const self = this;
    const amountObj = {
      total: self.cartTotals.totalWithShippingAndTax.toFixed(2),
      currency: 'USD',
      details: {
        subtotal: self.cartTotals.subTotal.toFixed(2),
        tax: self.cartTotals.tax.toFixed(2),
        shipping: self.cartTotals.consumerShipping.toFixed(2),
      }
    };

    paypal.Button.render({
      env: Globals.environment.development ? 'sandbox' : 'production',
      client: {
        sandbox: Globals.environment.paypal.sandboxClientId,
        production: Globals.environment.paypal.productionClientId
      },
      locale: 'en_US',
      style: {
        size: 'responsive',
        color: 'gold',
        shape: 'rect',
        tagline: false
      },
      payment: function(data, actions) {
        return actions.payment.create({
          transactions: [{
            amount: amountObj,
            description: `Shopping cart checkout for items on Wedzee.com. Order identifier ${self.cartTotals.orderId}.`,
            custom: self.cartTotals.orderId
          }],
        });
      },
      onAuthorize: function(data: any, actions) {
        self.paypalData.next(data);
      },
      onCancel: function(data, actions) {
        // If the user cancels the flow.
      }
    }, '#paypal-button');
  }

}
