<form
  *ngIf="!!searchForm"
  [formGroup]="searchForm"
  fxLayout="row"
  fxLayoutAlign="center"
  fxLayoutGap="8px">
  <mat-form-field
  style="width:calc(100% - 109px)!important;"
    appearance="outline" class="search-form-field mini-input"
    [ngClass]="mobile ? 'full-width' : ''"
    (click)="searchBoxClicked$.next()"
    (keydown.enter)="clickSearchBtn()"
  >
    
  <input class="search-input searchtxt" [matAutocomplete]="searchAutocomplete" type="search" formControlName="searchText" matInput placeholder="Search anything">
  </mat-form-field>
  <button class="wdz-btn" mat-flat-button (click)="$event.stopPropagation(); clickSearchBtn()">Search</button>
</form>
<mat-autocomplete #searchAutocomplete [panelWidth]="searchPanelWidth">
  <mat-option
    *ngFor="let category of searchCategories$ | async"
    (click)="clearForm()"
    [routerLink]="['category', category.id]"
    routerLinkActive="active-link"
    class="wz-menu-item"
  >
    <b>{{category.name}}</b>
  </mat-option>
  <ng-container *ngIf="searchListings$ | async as listings">
    <h3 class="search-header">Items matching "{{searchTextCtrl().valueChanges | async}}"</h3>
    <mat-option
      *ngFor="let listing of listings"
      style="height: 72px;"
      fxLayout="row"
      (click)="clearForm()"
      [routerLink]="['product', listing.id]"
      routerLinkActive="active-link"
      class="wz-menu-item"
    >
      <img fxFlex="0 0 40px" class="search-item-img" [src]="listing.thumbnailImg" alt="">
      <div fxFlex="calc(100% - 56px)" style="margin-left:16px !important; line-height: 22px;">
        <b>{{listing.name}}</b>
        <br>
      </div>
    </mat-option>
  </ng-container>
</mat-autocomplete>
