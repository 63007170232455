<form *ngIf="!!usernameForm" [formGroup]="usernameForm" style="height: 60px;">
  <mat-form-field class="full-width" [ngSwitch]="!!isValidating">
    <div matPrefix class="prefix-wrapper">
      <mat-spinner diameter="25" *ngSwitchCase="true"></mat-spinner>
      <mat-icon *ngSwitchCase="false">alternate_email</mat-icon>
    </div>
    <input
      #tooltip="matTooltip"
      (click)="tooltip.show()"
      formControlName="username"
      matInput placeholder="Username/StoreName"
      type="text"
      [matTooltip]="usernameDescription"
      autocomplete="off"
    >
  </mat-form-field>
  <mat-error class="username-error" *ngIf="isTaken">*This username isn't available</mat-error>
  <mat-error class="username-error" *ngIf="!isFormatValid">*Username must be at least 3 characters and cannot include any spaces, hyphens or special characters</mat-error>
  <mat-error class="username-error" *ngIf="(username | async)?.length > maxLength">*Username must be less than {{maxLength}} characters</mat-error>
</form>
