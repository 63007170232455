
import { Component, OnInit } from '@angular/core';
import { Globals } from '~shared/classes';
import { IInitialData } from 'wz-types/page-data.d';

@Component({
  selector: 'wz-featured-listings-page',
  templateUrl: './featured-listings-page.component.html',
  styleUrls: ['./featured-listings-page.component.scss']
})

export class FeaturedListingsPageComponent implements OnInit {

  featured: any[];
  homePageData: IInitialData = Globals.initialLoadData;

  constructor() { }

  ngOnInit() {
    this.featured = this.homePageData?.featuredListings;
  }
}
