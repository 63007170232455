import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-add-address-dialog',
  templateUrl: './add-address-dialog.component.html',
  styleUrls: ['./add-address-dialog.component.scss']
})
export class AddAddressDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AddAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  /**
   * Returns an observable of whether the user clicked to change the image.
   */
  public static open(matDialog: MatDialog): Observable<any> {
    const dialogRef = matDialog.open(AddAddressDialogComponent, {
      width: '90%',
      maxWidth: '750px',
      data: {}
    });

    return dialogRef.afterClosed();
  }


}
