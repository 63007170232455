import { Injectable } from '@angular/core';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {
  maxWidth = 950;
  maxHeight = 950;

  constructor() { }

  public async resizeImage(file: File): Promise<string> {
    const self = this;
    return <any>new Promise((resolve, reject) => {
      if (!(/image/i).test(file.type)) {
        console.error('NOT AN IMAGE');
        return undefined;
      }
      // read the files
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function (event: any) {
        // blob stuff
        const blob = new Blob([event.target.result], <BlobPropertyBag>{ type: file.type}); // create blob...
        window.URL = window.URL || window.webkitURL;
        const blobURL = window.URL.createObjectURL(blob); // and get it's URL

        // helper Image object
        const image = new Image();
        image.src = blobURL;
        image.onload = async function() {
          // have to wait til it's loaded
          let compressedBase64 = self.htmlImageToBase64Uri(image); // send it to canvas
          if (window.innerWidth < 960) {
            compressedBase64 = await self.rotateBase64Image(compressedBase64);
          }
          resolve(compressedBase64);
        };
      };
    });
  }

  public rotateBase64Image(base64Data: string, degrees: number = 90): Promise<string> {
    return new Promise((resolve: any, reject: any) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = base64Data;
      image.onload = function() {
        if (degrees === 180) {
          canvas.width = image.width;
          canvas.height = image.height;
        } else {
          canvas.width = image.height;
          canvas.height = image.width;
        }
        ctx.rotate(degrees * Math.PI / 180);
        if (degrees === 90) {
          ctx.translate(0, -canvas.width);
        } else if (degrees === 180) {
          ctx.translate(-canvas.width, -canvas.height);
        } else if (degrees === 270) {
          ctx.translate(-canvas.height, 0);
        }
        ctx.drawImage(image, 0, 0);
        const newData = canvas.toDataURL();
        resolve(newData);
      };
    });
  }

  private htmlImageToBase64Uri(img: HTMLImageElement): string {
    const canvas = document.createElement('canvas');
    let width: number = img.width as number;
    let height: number = img.height as number;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > this.maxWidth) {
        // height *= max_width / width;
        height = Math.round(height *= this.maxWidth / width);
        width = this.maxWidth;
      }
    } else {
      if (height > this.maxHeight) {
        // width *= max_height / height;
        width = Math.round(width *= this.maxHeight / height);
        height = this.maxHeight;
      }
    }

    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL('image/jpeg', 0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
  }
}
