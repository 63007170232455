import { Directive, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { delay, filter, map, takeUntil } from 'rxjs/operators';

import { Globals } from '../../classes';
import { LoginDialogComponent } from './../../dialogs';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzMustSignIn]'
})
export class MustSignInDirective implements OnDestroy {
  @Input() onSignedIn: () => void;
  @Output() signedInOutput: EventEmitter<void> = new EventEmitter();
  @Input() mustSignInDisabled?: boolean;
  @Input() activity?: string; // The name of the thing to say after 'You must be signed in...' Ex: 'like posts'
  destroy$: Subject<void> = new Subject();

  constructor(
    private matDialog: MatDialog,
    private zone: NgZone
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isLoggedIn(): boolean {
    return Globals.user && Globals.user.isLoggedIn() && !!Globals.user.email;
  }

  triggerSignedIn() {
    if (this.onSignedIn) {
      this.onSignedIn();
    }
    this.signedInOutput.emit();
  }

  @HostListener('click', ['$event']) onClick($event) {
    const self = this;
    if (!this.mustSignInDisabled) {
      if (this.isLoggedIn()) {
        this.triggerSignedIn();
      } else {
        LoginDialogComponent.open(true, this.matDialog).pipe(
          delay(1000),
          filter((isLoggedIn?: boolean) => self.isLoggedIn()),
          map(() => {
            this.triggerSignedIn();
          }),
          takeUntil(Globals.destroy$)
        ).subscribe();
      }
    }
  }

}
