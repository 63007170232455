<!-- Filter Container Start Here -->
<div class="filter-container accordian-container" fxLayout="column">

  <!-- Filter Action -->
  <div class="filter-action" *ngIf="isBottomSheet">
    <img src="/assets/images/small-logo.png" />
    <button fxFlex="1 1 auto" class="btn-text-bold">Refine</button>
    <button fxFlex="1 1 auto" class="btn-text" (click)="resetFilter()">Reset</button>
  </div>

  <!-- Filter Header -->
  <div class="filter-header" *ngIf="!isBottomSheet" fxLayout="row">
    <h2 fxFlex="1 1 100%">Filter By</h2>
    <button fxFlex="1 1 auto" class="btn btn-danger" (click)="resetFilter()">Reset</button>
  </div>

  <!-- Filter Body -->
  <div class="filter-body">
    <mat-accordion>
      <!-- Accordion -->
      <mat-expansion-panel (opened)="expansionPanelOpened('Keyword')" [expanded]="expandKeyword">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Keyword
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <div class="search-bar-field">
          <mat-icon aria-hidden="false" aria-label="Example search icon">search</mat-icon>
          <input type="text" *ngIf="isBottomSheet" [(ngModel)]="searchKeyword" placeholder="Enter keyword" (keyup)="updateQueryParams({ searchText: searchKeyword })" />
          <input type="text" *ngIf="!isBottomSheet" [(ngModel)]="searchKeyword" placeholder="Enter keyword" (keyup.enter)="updateQueryParams({ searchText: searchKeyword })" />
        </div>
      </mat-expansion-panel>

      <!-- Accordion -->
      <mat-expansion-panel (opened)="expansionPanelOpened('Price')" [expanded]="expandPrice">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Price
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <div class="filter-list">
          <mat-radio-group #priceRadioGroup [(ngModel)]="selectedPriceOption" 
            (change)="$event.value !== 'custom' ? updateQueryParams({ priceHigh: $event.value.split('_')[1], priceLow: $event.value.split('_')[0] }) : undefined">
            <ul>
              <li *ngFor="let btn of priceRadioButtons">

                <mat-radio-button style="margin-bottom: 5px; display: block;" [value]="btn.val"
                  [checked]="btn.checked">
                  {{btn.label}}
                </mat-radio-button>

              </li>
            </ul>
          </mat-radio-group>
          <form [formGroup]="priceForm">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutGap.lt-sm="40px" class="custom-price-row">
              <div fxFlex="1 1 33%" fxFlex.lt-md="1 1 50%">
                <input (click)="selectCustomPriceRadioBtn()" matInput class="gray-input" formControlName="priceLow"
                  type="number" wzDebounceKeyup="1000" placeholder="Min">
              </div>
              <div fxFlex="1 1 33%" fxFlex.lt-md="1 1 50%">
                <input (click)="selectCustomPriceRadioBtn()" matInput class="gray-input" formControlName="priceHigh"
                  type="number" wzDebounceKeyup="1000" placeholder="Max">
              </div>
              <div fxFlex="1 1 33%" class="text-left" *ngIf="!isBottomSheet">
                <button class="btn btn-transparent apply-filter-btn" #goButton
                  (click)="updateQueryParams({ priceHigh: priceForm.get('priceHigh').value, priceLow: priceForm.get('priceLow').value })">
                  Apply
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-expansion-panel>

      <!-- Accordion -->
      <mat-expansion-panel *ngIf="!initialParams?.categoryId" (opened)="expansionPanelOpened('Category')" [expanded]="expandCategory">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Category
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <div class="filter-list">
          <ul>
            <li *ngFor="let category of mainMenuCategories">
              <div [routerLinkAbsolute]="category | categoryUrl" class="category-breadcrumb">{{category.name}}</div>
            </li>
          </ul>
        </div>
      </mat-expansion-panel>

      <!-- Accordion -->
      <mat-expansion-panel *ngIf="initialParams?.categoryId" id="categoryExpansionPanel" (opened)="expansionPanelOpened('Category')" [expanded]="expandCategory">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Category
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <div class="filter-list">
          <ul>
            <li *ngFor="let category of mainMenuCategories">
              <div [routerLinkAbsolute]="category | categoryUrl" class="category-breadcrumb" [ngClass]="{'bold': category.id == selectedCategory?.id}"
                (click)="selectCategory(category)">
                {{category.name}}
              </div>
              <ul class="sub" *ngIf="category.id == selectedCategory?.id || category.id == selectedCategory?.parentCategoryId" style="margin-top: 10px;">
                <li *ngFor="let subcategory of getSubcategories()">
                  <div class="category-breadcrumb" [ngClass]="{'bold': subcategory.id == selectedCategory?.id}"
                    (click)="selectCategory(subcategory)">
                    {{subcategory.name}}
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </mat-expansion-panel>

      <!-- Accordion -->
      <mat-expansion-panel *ngIf="isFilteringDresses" (opened)="expansionPanelOpened('Silhouette')" [expanded]="expandSilhouette">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Silhouette
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <div [style.height]="expanded.silhouette ? 'auto' : '65px'"
          [style.overflow]="expanded.silhouette ? 'auto' : 'hidden'" class="expander-container">
          <div *ngFor="let s of dressOptions.silhouette"
            [style.background-color]="(initialParams.dressSilhouette || []).indexOf(s) > -1 ? 'black' : 'white'"
            [style.color]="(initialParams.dressSilhouette || []).indexOf(s) > -1 ? 'white' : 'black'"
            (click)="toggleDressParams('dressSilhouette', s)" class="option-button">
            {{s}}
          </div>
        </div>
        <div class="full-width text-center" [ngSwitch]="!!expanded.silhouette">
          <a (click)="expanded.silhouette = !expanded.silhouette" class="expander-button" *ngSwitchCase="false">Show
            more
            <mat-icon>keyboard_arrow_down</mat-icon>
          </a>
          <a (click)="expanded.silhouette = !expanded.silhouette" class="expander-button" *ngSwitchCase="true">Show less
            <mat-icon>keyboard_arrow_up</mat-icon>
          </a>
        </div>
      </mat-expansion-panel>

      <!-- Accordion -->
      <mat-expansion-panel *ngIf="isFilteringDresses" (opened)="expansionPanelOpened('Fabric')" [expanded]="expandFabric">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Fabric
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <div [style.height]="expanded.fabric ? 'auto' : '75px'" [style.overflow]="expanded.fabric ? 'unset' : 'hidden'"
          class="expander-container">
          <div *ngFor="let s of dressOptions.fabric"
            [style.background-color]="(initialParams.dressFabric || []).indexOf(s) > -1 ? 'black' : 'white'"
            [style.color]="(initialParams.dressFabric || []).indexOf(s) > -1 ? 'white' : 'black'"
            (click)="toggleDressParams('dressFabric', s)" class="option-button">
            {{s}}
          </div>
        </div>
        <div class="full-width text-center" [ngSwitch]="!!expanded.fabric">
          <a (click)="expanded.fabric = !expanded.fabric" class="expander-button" *ngSwitchCase="false">Show more
            <mat-icon>keyboard_arrow_down</mat-icon>
          </a>
          <a (click)="expanded.fabric = !expanded.fabric" class="expander-button" *ngSwitchCase="true">Show less
            <mat-icon>keyboard_arrow_up</mat-icon>
          </a>
        </div>
      </mat-expansion-panel>

      <!-- Accordion -->
      <mat-expansion-panel *ngIf="isFilteringDresses" (opened)="expansionPanelOpened('Size')" [expanded]="expandSize">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Size
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <div [style.height]="expanded.size ? 'auto' : '100px'" [style.overflow-y]="expanded.size ? 'auto' : 'hidden'"
          class="expander-container">
          <mat-chip-list>
            <mat-chip *ngFor="let c of dressOptions.sizeCategories" [selected]="selectedSizeCategory === c"
              (click)="selectedSizeCategory = c">
              {{c}}
            </mat-chip>
          </mat-chip-list>
          <div mat-stroked-button *ngFor="let s of dressOptions.sizesLookup[selectedSizeCategory]"
            [style.background-color]="(initialParams.dressSize || []).indexOf(s) > -1 ? 'black' : 'white'"
            [style.color]="(initialParams.dressSize || []).indexOf(s) > -1 ? 'white' : 'black'"
            (click)="toggleDressParams('dressSize', s)" class="option-button">
            {{s}}
          </div>
        </div>
        <div class="full-width text-center" [ngSwitch]="!!expanded.size">
          <a (click)="expanded.size = !expanded.size" class="expander-button" *ngSwitchCase="false">Show more <mat-icon>
              keyboard_arrow_down</mat-icon></a>
          <a (click)="expanded.size = !expanded.size" class="expander-button" *ngSwitchCase="true">Show less <mat-icon>
              keyboard_arrow_up</mat-icon></a>
        </div>
      </mat-expansion-panel>

      <!-- Accordion -->
      <mat-expansion-panel (opened)="expansionPanelOpened('Color')" [expanded]="expandColor">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Color
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <ng-container *ngIf="weddingColors$ | async as colors">
          <ng-container *ngFor="let c of colors"
            [ngSwitch]="!!(selectedColor$ | async) && (selectedColor$ | async)?.id === c?.id">
            <button *ngSwitchCase="true" mat-icon-button [style.background-color]="c.hexColor"
              style="box-shadow: 0 0 2.5px 2.5px black;" class="color-swatch-btn selected"
              (click)="updateQueryParams({ colorId: 'none' })" matTooltip="Deselect">
              <mat-icon style="color: black;">done</mat-icon>
            </button>
            <button *ngSwitchCase="false" mat-icon-button [style.background-color]="c.hexColor" class="color-swatch-btn"
              (click)="updateQueryParams({ colorId: c.id })" [matTooltip]="c.name">
              <mat-icon [style.color]="c.hexColor">done</mat-icon>
            </button>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>

      <!-- Accordion -->
      <mat-expansion-panel (opened)="expansionPanelOpened('Condition')" [expanded]="expandConditions">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Condition
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Body -->
        <div class="filter-list">
          <mat-radio-group #conditionRadioGroup [(ngModel)]="selectedConditionOption" (change)="updateQueryParams({ condition: $event.value})">
            <ul>
              <li *ngFor="let o of conditionOptions">

                <mat-radio-button [value]="o" [checked]="initialParams?.condition === o">
                  {{o}}
                </mat-radio-button>
              </li>
            </ul>
          </mat-radio-group>
        </div>
      </mat-expansion-panel>

      
    </mat-accordion>
  </div>

  <!-- Filter Footer -->
  <div class="filter-footer" *ngIf="isBottomSheet">
    <button class="btn btn-purple" (click)="bottomSheetDone()">Show Items</button>
    <button class="btn btn-gray" (click)="bottomSheetCancel()">Cancel</button>
  </div>
</div>
