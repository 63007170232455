import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { IHelpCenterRequest, ISellOnWedzeeData } from 'wz-types';
import { Globals } from '~shared/classes';
import { AlertService, CommonService } from '~shared/services';

@Component({
  selector: 'wz-consumer-sell-on-wedzee',
  templateUrl: './sell-on-wedzee.component.html',
  styleUrls: ['./sell-on-wedzee.component.scss']
})
export class ConsumerSellOnWedzeeComponent implements OnInit, AfterViewInit {
  data$: Observable<ISellOnWedzeeData>;
  @Input() withTabs = false;
  helpForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private alertSrv: AlertService,
    private commonService: CommonService,
    private http: HttpClient,
    public router: Router,
    private route: ActivatedRoute
  ) { 
    this.route.params.subscribe(params => {
      console.log(params);
      if (params['register']) {
        this.openAuthentication(true);
      }
    });
  }

  ngOnInit() {
    this.helpForm = this.formBuilder.group({
      email: [undefined, [Validators.required, Globals.emailValidator()]],
    });
  }

  ngAfterViewInit(): void {
    for (let i = 0; i < this.route.snapshot.url.length; i++) {
      if (this.route.snapshot.url[i].path === 'register') {
        this.openAuthentication(true);
        break;
      }
    }
  }

  becomeASeller() {
    this.router.navigateByUrl('/become-a-seller');
  }

  isASeller() {
    return !!Globals.user && !!Globals.user.SellerAccount && !!Globals.user.SellerAccount.hasAcknowledgedSellerAgreement;
  }

  openAuthentication(isRegistration?: boolean) {
    this.commonService.openAuthentication(isRegistration);
  }

  askForHelp() {
    const userId = Globals.user && Globals.user.id ? Globals.user.id : null;
    this.alertSrv.confirm('Ready to get started?', '').pipe(
      filter(r => !!r),
      mergeMap(() => this.http.post(`${Globals.environment.apiUrl}users/help-sell`, { ...this.helpForm.getRawValue(), userId })),
      map((r: IHelpCenterRequest) => {
        this.alertSrv.alert('Request submitted', `We\'ll reach out to you shortly to help you get started!`);
        this.router.navigateByUrl('/');
      }),
      take(1)
    ).subscribe();
  }

}
