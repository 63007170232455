import { Component, Input, OnInit } from '@angular/core';
import { ITrustIcon } from 'wz-types/home-page';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-trust-icon',
  templateUrl: './trust-icon.component.html',
  styleUrls: ['./trust-icon.component.scss']
})
export class TrustIconComponent implements OnInit {
  @Input() trustIcon: ITrustIcon;

  iconImgSrcs = {
    secure: 'assets/img/WZSecureIcon.png',
    price: 'assets/img/WZPriceIcon.png',
    shipping: 'assets/img/WZShippingIcon.png'
  };

  icons = [
    { title: 'SELL IT', text: 'Listings are free, quick & easy. Add photos, description and set a price.', imgSrc: this.iconImgSrcs.secure },
    { title: 'SHIP IT', text: 'Discounted pre-paid USPS label emailed to seller. Tracking updates sent to buyer.', imgSrc: this.iconImgSrcs.shipping },
    { title: 'GET PAID', text: 'Money is directly deposited to you, minus a flat 8% fee.', imgSrc: this.iconImgSrcs.price }
  ];

  constructor() { }

  ngOnInit() {
  }

}
