<a>
  <mat-card class="product-card" [ngClass]="{ 'is-swiper' : isSwiper }" [ngSwitch]="!!isSkeleton">
    <div *ngSwitchCase="false" (click)="navigateToProductPage($event)" mat-card-image class="product-image"
      [style.background]="'url(' + doc.thumbnailImg + ')'" alt="" style="margin-bottom: 2px !important;"
      ngStyle.xs="width:180px;"></div>
    <div *ngSwitchCase="true" (click)="navigateToProductPage($event)" mat-card-image class="product-image loading-animation"
      alt=""></div>
    <mat-card-footer class="text-left">
      <mat-card-title *ngSwitchCase="false" (click)="navigateToProductPage($event)" class="text-left productitle">
        <div class="text-overflow-ellipsis" style="max-width: 250px;">{{ doc.name}}</div>
      </mat-card-title>
      <mat-card-title *ngSwitchCase="true" class="text-left text-overflow-ellipsis" style="font-size: 14px;">
        <div class="loading-animation" style="width: 70%; height: 25px;"></div>
      </mat-card-title>
      <mat-card-subtitle *ngSwitchCase="false" class="text-overflow-ellipsis price pricevalue"
        (click)="navigateToProductPage($event)">
        <div style="display:flex;">
          <div>
             {{(doc.price !== undefined && doc.price !== null) ? ('$ ' + (doc.price | number : '1.2-2') ):('$ ' + (this.getMinimumPriceFromVariation(doc) | number : '1.2-2') )}}             
          </div>
          <div *ngIf="!isAdminSite" [ngSwitch]="!!isOnSellerPage" class="text-center product-bottom-buttons-container btnLikeContainer" style="position:absolute;right:0;" ngStyle.xs="margin-right:-5px;">
            <button
              *ngSwitchCase="false"
              [disabled]="isSkeleton || disableLike"
              [matTooltip]="getLikeText()"
              wzMustSignIn
              activity="like posts"
              [onSignedIn]="toggleLike()"
              mat-icon-button
              [ngSwitch]="isLiked()"
              class="btnLike"
            >
            <mat-icon color="primary" *ngSwitchCase="false" class="btnLikeIcon">favorite_border</mat-icon>
            <mat-icon color="primary" *ngSwitchCase="true" class="btnLikeIcon">favorite</mat-icon>
          </button>
        </div>
      </div>      
      <div style="height: 30px; padding-top: 11px; margin-top: -4px;" class="children-inline-block">
          <!-- <wz-free-shipping [customText]="doc.qtyAvailable > 0 ? 'FREE shipping' : 'SOLD'"
            *ngIf="doc.isFreeShipping || (!!isOnSellerPage && doc.qtyAvailable === 0)"></wz-free-shipping> -->
            <wz-free-shipping 
              *ngIf="doc.isFreeShipping || (!!isOnSellerPage && doc.qtyAvailable === 0)"></wz-free-shipping>
        </div>
      </mat-card-subtitle>

      <ng-container *ngIf="!isAdminSite" [ngSwitch]="!!isOnSellerPage">
          <div class="product-panel" *ngSwitchCase="true">
            <div class="product-view-btn">
              <button [disabled]="isSkeleton" matTooltip="View in new tab"
                [routerLinkNewTab]="doc | productUrl">
                View
              </button>
            </div>
            <div class="product-edit-btn" *ngIf="variationOptions?.length > 0">
              <span>
                <a *ngFor="let v of variationOptions" [routerLinkAbsolute]="'seller/edit-listing/' + doc.id">
                  {{v.text}}
                </a>
              </span>
            </div>
            <div class="product-edit-btn">
              <button [disabled]="isSkeleton" matTooltip="Edit listing"
                [routerLinkAbsolute]="'seller/edit-listing/' + doc.id">
                Edit
              </button>
            </div>
            <div class="product-add-btn">
              <button [disabled]="isSkeleton" matTooltip="Create in a new size or color"
                [routerLinkAbsolute]="'seller/create-listing?copyOf=' + doc.id">
                <mat-icon class="hide-mobile">add</mat-icon>
                Copy
              </button>
            </div>
            <!-- <div *ngIf="!!doc.canNoLongerEdit && (canDelete$ | async)" class="product-delete">
              <button [disabled]="isSkeleton"
                matTooltip="Delete listing" (click)="deleteListing()">
                Delete
              </button>
            </div> -->
          </div>
          <!-- <ng-container *ngIf="!doc.canNoLongerEdit">
            <button [disabled]="isSkeleton" matTooltip="Edit listing"
              [routerLinkAbsolute]="'seller/edit-listing/' + doc.id" mat-icon-button>
              <mat-icon class="default-product-icon">create</mat-icon>
            </button>
          </ng-container>
          <button *ngIf="!!doc.canNoLongerEdit && (canDelete$ | async)" [disabled]="isSkeleton"
            matTooltip="Edit listing" (click)="deleteListing()" mat-icon-button>
            <mat-icon class="default-product-icon">delete</mat-icon>
          </button>
          <mat-menu #variationSelect>
            <a mat-menu-item *ngFor="let v of variationOptions" [routerLinkAbsolute]="'seller/edit-listing/' + doc.id">
              {{v.text}}
            </a>
          </mat-menu>
          <button [disabled]="isSkeleton" matTooltip="Create in a new size or color"
            [routerLinkAbsolute]="'seller/create-listing?copyOf=' + doc.id" mat-icon-button>
            <mat-icon class="default-product-icon">add</mat-icon>
          </button> -->

        </ng-container>
    </mat-card-footer>
  </mat-card>
</a>
