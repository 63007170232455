import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { ICategory } from 'wz-types/categories';
import { Globals } from '~shared/classes';

@Component({
  selector: 'wz-category-carousel',
  templateUrl: './category-carousel.component.html',
  styleUrls: ['./category-carousel.component.scss']
})
export class CategoryCarouselComponent implements OnInit {
  @Input() categories: ICategory[] = [];
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  loadSlider = false;
  config: any;
  iterateCategories = [];

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.loadSlider = false;
    const config = {...Globals.defaultSwiperConfig};
    config.breakpoints = {
      280 : { slidesPerView: 1.8},
      320 : { slidesPerView: 2.1},
      350 : { slidesPerView: 2.1},
      411 : { slidesPerView: 2.1},
      540 : { slidesPerView: 3}, 
      768 : { slidesPerView: 3.5},
      1024: { slidesPerView: 4.9},
      1366 : { slidesPerView: 6.3}
    };
    this.config = config;
    this.iterateCategories = this.categories.filter(c => c.name !== 'Other Items');
    this.loadSlider = true;
  }

  goToCategoryProducts(id: string) {
    this.router.navigateByUrl(`category/${id}`);
  }

}
