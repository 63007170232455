import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-buy-on-wedzee',
  templateUrl: './buy-on-wedzee.component.html',
  styleUrls: ['./buy-on-wedzee.component.scss']
})
export class BuyOnWedzeeComponent implements OnInit {
  @Input() withTabs = false;

  constructor(
  ) { }

  ngOnInit() {
  }
}
