import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzDebounceKeyup]'
})
export class DebounceKeyupDirective implements OnInit, OnDestroy {
  @Input() wzDebounceKeyup = 250;
  @Output() debounceKeyup: EventEmitter<KeyboardEvent> = new EventEmitter();
  events$: Subject<KeyboardEvent> = new Subject();
  destroy$: Subject<void> = new Subject();

  constructor() {}

  ngOnInit() {
    this.events$.pipe(
      debounceTime(this.wzDebounceKeyup),
      map((e: KeyboardEvent) => this.debounceKeyup.emit(e)),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('keyup', ['$event'])
  keyupEvent(e: KeyboardEvent) {
    this.events$.next(e);
  }

}
