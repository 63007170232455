import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { from as fromPromise, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ICategory } from 'wz-types/categories';

import { FirestoreRefs } from './../../classes';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wza-select-category',
  templateUrl: './select-category.component.html',
  styleUrls: ['./select-category.component.scss']
})
export class SelectCategoryComponent implements OnInit, OnDestroy {
  @ViewChild('categoriesMatSelectList') categoriesMatSelectList: MatSelectionList;
  allCategories: ICategory[] = [];
  selectedCategories: ICategory[] = [];
  destroy$: Subject<void> = new Subject();
  isLoading = true;
  title = 'Select Categories';

  constructor(
    public dialogRef: MatDialogRef<SelectCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private firestore: AngularFirestore
  ) { }

  public static open(dialog: MatDialog, isSingleSelect: boolean, excludeIds?: string[], previouslySelectedIds?: string[], title?: string): Observable<ICategory[]> {
    const dialogRef = dialog.open(SelectCategoryComponent, {
      hasBackdrop: true,
      width: '90%',
      maxWidth: '350px',
      data: {
        excludeIds,
        previouslySelectedIds,
        isSingleSelect,
        title
      }
    });

    return dialogRef.afterClosed();
  }

  ngOnInit() {
    const self = this;
    this.title = this.data && this.data.title ? this.data.title : this.title;
    fromPromise(FirestoreRefs.categories.ref.orderBy('name').get()).pipe(
      map((categoriesSnapshot: QuerySnapshot<ICategory>) => {
        self.isLoading = false;
        const categoryDocs = categoriesSnapshot.docs.map((d) => d.data());
        const exludeIds = !!this.data.excludeIds && this.data.excludeIds.length > 0 ? this.data.excludeIds : [];
        self.allCategories = categoryDocs
          .filter((c: ICategory) => exludeIds.indexOf(c.id) === -1);
      }),
      takeUntil(self.destroy$)
    ).subscribe();

    self.categoriesMatSelectList.selectionChange.pipe(
      map((selectionResults: MatSelectionListChange) => {
        if (this.data && this.data.isSingleSelect) {
          this.categoriesMatSelectList.deselectAll();
          selectionResults.option.selected = true;
        }
        if (selectionResults.source.selectedOptions.selected) {
          this.selectedCategories = selectionResults.source.selectedOptions.selected.map((option: any) => option.value);
        }
      }),
      takeUntil(self.destroy$)
    ).subscribe();

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isPreviouslySelected(categoryId: string) {
    const prevIds = this.data.previouslySelectedIds;
  return !!prevIds && prevIds.length > 0 && prevIds.indexOf(categoryId) > -1;
  }

  submitSelections() {
    this.dialogRef.close(this.selectedCategories);
  }

}
