import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAboutPageSection } from 'wz-types';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-about-section-form-item',
  templateUrl: './about-section-form-item.component.html',
  styleUrls: ['./about-section-form-item.component.scss']
})
export class AboutSectionFormItemComponent implements OnInit {
  @Input() section: IAboutPageSection;
  @Output() updated: EventEmitter<IAboutPageSection> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Output() moveUp: EventEmitter<void> = new EventEmitter();
  @Output() moveDown: EventEmitter<void> = new EventEmitter();

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.instantiateBlankForm();
    if (!!this.section) {
      this.fillFormFromObject(this.section);
    }
  }

  instantiateBlankForm() {
    this.form = this.formBuilder.group({
      headline: [undefined, [Validators.required, Validators.maxLength(100), Validators.minLength(2)]],
      headlinePosition: ['left', [Validators.required]],
      longText: [undefined, [Validators.required, Validators.maxLength(275), Validators.minLength(2)]],
      backgroundImgUrl: [undefined]
    });
  }

  fillFormFromObject(o: IAboutPageSection) {
    const copyVal = (ctrlName: string) => this.getCtrl(ctrlName).setValue(o[ctrlName]);
    copyVal('headline');
    copyVal('headlinePosition');
    copyVal('longText');
    if (o.backgroundImgUrl) {
      copyVal('backgroundImgUrl');
    }
  }

  getCtrl(ctrlName: string) {
    return this.form.get(ctrlName);
  }

  saveUpdated() {
    this.updated.emit(this.form.getRawValue());
    this.form = undefined;
    setTimeout(() => {
      this.instantiateBlankForm();
    }, 500);
  }

}
