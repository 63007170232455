import { Component, Input, OnChanges, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { IListing } from 'wz-types/listings.d';
import { Globals } from '~shared/classes';

@Component({
  selector: 'wz-review-carousel',
  templateUrl: './review-carousel.component.html',
  styleUrls: ['./review-carousel.component.scss']
})
export class ReviewCarouselComponent implements OnInit, OnChanges {
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  loadSlider = false;
  config: any;

  constructor() { }

  ngOnInit() {
    this.loadSlider = false;
    const config = {...Globals.defaultSwiperConfig};
    config.spaceBetween = 15,
    config.breakpoints = {
      280 : { slidesPerView: 1.1 },
      320 : { slidesPerView: 1.1 },
      350 : { slidesPerView: 1.1 },
      411 : { slidesPerView: 1.1 },
      540 : { slidesPerView: 1.1 },
      768 : { slidesPerView: 2 },
      1024: { slidesPerView: 3, spaceBetween: 30 },
      1366 : { slidesPerView: 3 }
    };
    this.config = config;
    this.loadSlider = true;
  }

  ngOnChanges() {
  }

}
