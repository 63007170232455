import { Globals } from '~shared/classes/global.class';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-how-affiliate-works',
  templateUrl: './how-affiliate-works.component.html',
  styleUrls: ['./how-affiliate-works.component.scss']
})
export class HowAffiliateWorksComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

  public applyNow(): void {
    window.open(Globals.affiliateUrl, '_blank');
  }
}
