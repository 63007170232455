<mat-dialog-content style="text-align: center!important; vertical-align: middle">
  <h2 *ngIf="!!imgName" mat-dialog-title>{{imgName}}</h2>
  <button class="close-button" *ngIf="closeBtn" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <img [src]="imgUrl" style="margin: auto; max-width: 100%; max-height: 65vh; display: block;" alt="">
</mat-dialog-content>
<mat-dialog-actions *ngIf="isListingImg || isListingPrimaryImg">
  <button (click)="closeWithAction('makePrimary')" *ngIf="!isListingPrimaryImg" mat-flat-button color="accent">
    Make primary
  </button>
</mat-dialog-actions>
