import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { Globals } from '../../classes';
import { AuthService } from './../../services/auth/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  isFormSubmitted: boolean;
  noEmailError: string;

  constructor(
    private formBuilder: FormBuilder,
    private authSrv: AuthService
  ) { }

  ngOnInit() {
    const self = this;
    self.resetPasswordForm = self.formBuilder.group({
      email: [undefined, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/)]]
    });
  }

  submitResetPasswordForm(): void {
    const self = this;
    self.authSrv.sendPasswordResetEmail(self.resetPasswordForm.get('email').value).pipe(
      map(() => self.isFormSubmitted = true),
      catchError((error: any) => {
        if (error.code === 'auth/invalid-email' || error.code === 'auth/user-not-found') {
          self.noEmailError = `There\'s no account under the email address \'${self.resetPasswordForm.get('email').value}\'. ` +
          `For more information, please contact <a href="mailto:${Globals.environment.supportEmail}">${Globals.environment.supportEmail}</a>`;
        }
        return observableOf(undefined);
      }),
      take(1)
    ).subscribe();
  }
}
