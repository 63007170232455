<div class="affiliate-section">
  <div class="affiliate-content general-content-style">
    <h2>Join Wedzee’s Affiliate Program</h2>
    <p>Earn commission on every Wedzee item you sell when you join as a Wedzee affiliate. Click Apply Now to join via our Affiliatly page.</p> 
    <a (click)="applyNow($event)" style="cursor: pointer;">Apply Now <i class="fas fa-arrow-right"></i></a> 
  </div>
  <div class="affiliate-image">
    <img src="/assets/images/affiliate-top-banner.png" />
  </div>
</div>

<div class="ready-to-list-section mobile-white-bg affiliate-member">
  <div class="list-your-item-on-wedzee general-content-style">
    <h2>Perks of a Wedzee Affiliate Member</h2>
  </div>
  
  <div class="perks-list">
    <div id="divReviewCarousel" class="swiper-main" *ngIf="loadSlider">
      <div class="swiper-container" [swiper]="config">
        <div class="swiper-wrapper">
          <div class="swiper-slide">          
            <div class="perk-box">
              <div class="perk-image">
                <span>Earn<br/>Commission</span>
                <p>Discover and share<br>all things wedding</p>
              </div>
              <h2>
                Discover and share<br/>all things wedding
              </h2>
            </div>
          </div>
          <div class="swiper-slide">          
            <div class="perk-box">
              <div class="perk-image">
                <span>Join a Community</span>
                <p>Become a Member of<br>Wedzee's Affiliate Community</p>
              </div>
              <h2>
                Become a Member of<br/>Wedzee’s Affiliate Community
              </h2>
            </div>
          </div>
          <div class="swiper-slide">          
            <div class="perk-box">
              <div class="perk-image">
                <span>Inspire your Audience</span>
                <p>Stay on top of wedding<br>trends and items</p>
              </div>
              <h2>
                Stay on top of wedding<br/>trends and items
              </h2>
            </div>
          </div>
        </div>
        <div class="swiper-scrollbar"></div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="swiper-btn review-swipper-btn swiper-button-prev"><img src="/assets/images/LeftArrow_Active.png" /></div>
      <div class="swiper-btn review-swipper-btn swiper-button-next"><img src="/assets/images/RightArrow_Active.png" /></div>
    </div>
  </div>
</div>

<div class="list-your-item-on-wedzee general-content-style commision-container">
   <h2>Two Ways to Earn Commission</h2>
   <div class="commission-section">
     <div class="commission-box">
       <h2>Personalized<br/>Affiliate Link</h2>
       <img src="/assets/images/commission1.png" />
       <p>Earn commission when you share products from Wedzee using your personalized affiliate link</p>
       <button class="btn btn-purple btn-xxl" (click)="applyNow($event)">Apply Now</button>
     </div>
     <div class="commission-box">
      <h2>Embedded Wedzee<br/>Storefront on Your Website</h2>
      <img src="/assets/images/commission2.png" />
      <p>Easily embed a Wedzee storefront on your website. We manage the logistics. You earn commission when users shop Wedzee products from your website.</p>
      <button class="btn btn-purple btn-xxl" (click)="applyNow($event)">Apply Now</button>
    </div>
   </div>
</div>

<div class="wedzee-mailing-container">
  <div class="general-wrapper">
    <wz-subscribe-mailing></wz-subscribe-mailing>
  </div>
</div>

<div class="ready-to-list-section">
  <div class="general-wrapper no-padding">
    <wz-how-affiliate-works></wz-how-affiliate-works>
  </div>
</div>
<div class="general-wrapper no-padding">
  <div class="faq-section">
    <div class="list-your-item-on-wedzee general-content-style">
      <h2>Frequently Asked Questions</h2>
    </div>
    <div class="faq-list">
      <div class="faq-item">
        <div class="faq-box">
          <h2>What is Wedzee?</h2>
          <p>Wedzee is the only smart marketplace focused on selling new, custom and new-to-you wedding items. Each seller is verified by Wedzee and all purchases are protected by the Wedzee guarantee.</p>
        </div>
      </div>
      <div class="faq-item">
        <div class="faq-box">
          <h2>Who is eligible to become a Wedzee Affiliate Member?</h2>
          <p>All affiliates are welcomed to apply. Wedzee’s items are more suited for platforms and communities that are focused on weddings. However, Cashback sites and voucher sites are not eligible for the program.</p>
        </div>
      </div>
      <div class="faq-item">
        <div class="faq-box">
          <h2>How do commissions work?</h2>
          <p>Commission is earned on each qualifying sale (excluding tax, shipping, returns and cancellations). Our cookie period is 30 days; affiliates will receive a commission sale attributed to their account during this period.</p>
        </div>
      </div>
      <div class="faq-item">
        <div class="faq-box">
          <h2>How does Wedzee support affiliates?</h2>
          <p>As an affiliate member you will receive access to Wedzee marketing materials, images, curated lists to help affiliates discover new products and regular email communications about updates.</p>
        </div>
      </div>
      <div class="faq-item">
        <div class="faq-box">
          <h2>Do I need to sign a contract?</h2>
          <p>There is no contract to join Wedzee’s affiliate program. You will be required to agree to Wedzee’s Affiliate Policy and the Terms and Conditions of our third-party affiliate network.</p>
        </div>
      </div>
      <div class="faq-item">
        <div class="faq-box">
          <h2>How much do I earn?</h2>
          <p>Wedzee Affiliate Members earn 28% commission from Wedzee’s commission on each item sold. Commissions are paid out every 30 days via a PayPal account or bank transfer from our third-party affiliate network.</p>
        </div>
      </div>
    </div>
  </div> 
  <div class="feature-review-wrapper">
    <ng-container>
      <div ngStyle.xs="margin-top: 0px;" style="margin-top: 20px!important;" class="section-header-newarrival home-title full-width text-left children-inline-block  listings-header">
        <h2 class="mat-headline carousel-header" style="font-size: 23px !important; font-weight: bold !important; color:#524F4A !important; ">Recent Reviews</h2>
        <br />
      </div>
      <div class="review-carousel-wrapper">
        <wz-review-carousel></wz-review-carousel>
      </div>
    </ng-container>
    <div fxFlexOrder.gt-xs="4" fxFlexOrder.xs="4" ngStyle.xs="margin-top: 0px;" style="margin-top: 25px!important;">
      <ng-container>
        <div id="divInThePress" class="section-header home-title full-width text-left children-inline-block">
          <h2 class="mat-headline carousel-header" style="font-size: 23px !important; font-weight: bold !important; color:#524F4A !important; margin-bottom: 20px;">Featured In</h2>
          <div class="in-the-press" style="display:flex;flex-wrap: wrap;">
            <div class="in-the-press-image-container">
              <span class="helper"></span>
              <a href="https://www.stylemepretty.com/2021/07/24/find-everything-you-need-for-your-wedding-on-wedzee-the-online-marketplace-exclusively-for-buying-and-selling-wedding-items/" target="_new"><img src="/assets/images/style-me-pretty.png" class="in-the-press-images" /></a>
            </div>
            <div class="in-the-press-image-container">
              <span class="helper"></span>
              <a href="https://www.floridaweddingpodcast.com/episodes/episode-14-jennifer-burns" target="_new"><img src="/assets/images/florida-wedding-podcast.jpg" class="in-the-press-images" /></a>
            </div>
            <div class="in-the-press-image-container">
              <span class="helper"></span>
              <a href="https://thebudgetsavvybride.com/wedzee-wedding-marketplace/" target="_new"><img src="/assets/images/budget-savy-bride.png" class="in-the-press-images" /></a>
            </div>
            <div class="in-the-press-image-container">
              <span class="helper"></span>
              <a href="https://www.bridalguide.com/planning/etiquette-advice/tips-tricks/wedzee" target="_new">
                <img src="/assets/images/bridal-guide.png" class="in-the-press-images" />
              </a>
            </div>
            <div class="in-the-press-image-container">
              <span class="helper"></span>
              <a href="https://emmalinebride.com/where-to/sell-used-wedding-stuff/" target="_new">
                <img src="/assets/images/emmaline-bride.png" class="in-the-press-images" />
              </a>
            </div>
            <div class="in-the-press-image-container">
              <span class="helper"></span>
              <a href="https://bridalmusings.com/176606/wedzee-wedding-resale-platform/" target="_new"><img src="/assets/images/bridal-musings.png" class="in-the-press-images" /></a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
