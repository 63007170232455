import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { Globals } from '../../classes';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private meta: Meta,
    private title: Title
  ) { }


  generateTags(config?: any) {

    // default values
    config = {
      title: 'Wedzee',
      url: Globals.environment.siteUrl + '/',
      description: 'The Wedding Marketplace for New, Used & Custom Wedding Items',
      image: 'https://firebasestorage.googleapis.com/v0/b/wedzee-dev.appspot.com/o/wedee-logo.png?alt=media&token=a84b3bb4-741d-4983-8e60-5b802e844b8c',
      type: 'website',
      slug: '',
      ...(config || {})
    };
    this.title.setTitle(config.title !== 'Wedzee' ? `Wedzee - ${config.title}` : config.title);
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@wedzee' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: config.type });
    this.meta.updateTag({ property: 'og:site_name', content: 'Wedzee' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: `https://wedzee.com/${config.slug}` });
  }
}
