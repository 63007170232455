import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { Globals } from '../../classes';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzDistributedWidth]'
})
export class DistributedWidthDirective implements OnInit {
  /**
   * The number of columns to distribute the width over
   */
  @Input() wzDistributedWidth: number;

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {
    if (Globals.screenWidth() > 600) {
      this.el.nativeElement.style.display = 'inline-block';
      this.el.nativeElement.style.width = `calc((100% / ${this.wzDistributedWidth}) - 30px)`;
      this.el.nativeElement.style.marginRight = 'auto';
      this.el.nativeElement.style.marginLeft = 'auto';
    }
  }

}
