import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


/**
 * The app state interface. Required to instantiate a state.
 */
interface IAppStore<DataInterface, TActionName extends string, DataClass = any> {
  objectName: string; // The name of the objects being contained in the state. ('article', 'content', etc)
  objectIdKey: string; // The id key of the object. 'id', for example.
  objectGetFn: (...params) => Observable<DataInterface>; // The observable function used to get a single object.
  objectMapFn?: (obj: DataInterface) => any; // How a single object can be mapped. This is helpful is objects must be mapped into classes.
  expirationMs?: number; // If the default expiration (5 minutes) isn't applicable, set this to a different number of milliseconds
  actions?: { [actionName in TActionName]?: IAction<DataInterface> };
}

type StoreResponse<DataInterface> = (dispatchResponse: any, state?: { [objId: string]: DataInterface }, expirationLookup?: { [objId: string]: number }) => any;

type TActionType = 'get' | 'get-nocache' | 'change' | 'delete';

/**
 * The definition of an action.
 */
export interface IAction<DataInterface, ResponseInterface = any> {
  type: TActionType; // This is required. If the type is 'get' the data from the action will be cached.
  dispatch: (...params) => Observable<ResponseInterface>; // The server request to be made when the action is dispatched.
  reduce?: StoreResponse<DataInterface>; // Return the objects of this state to be parsed from the dispatch function so they can be saved to the state
  // Map the dispatch response to the correct data using the state and expirations objects.
  map?: StoreResponse<DataInterface>;
  discardResponse?: boolean;
}

/**
 * This is the format of the app state's static property which is stored in memory. It includes the current state information, including cached action data.
 */
interface IStateInMemory {
  [objectName: string]: { // This is the same as 'objectName' in the IAppStore interface.
    array$?: BehaviorSubject<any[]>; // A BehaviorSubject which emits all of the latest values.
    completedActions: { // A map of completed action information and when it was executed
      [actionName: string]: { timestamp: number; data: any; };
    };
    map: { // A map of the individual objects and a timestamp of when the object expires in state
      [id: string]: {
        object: any; // The db object.
        appStateExpires: number; // Timestamp at which object expires
      }
    }
  };
}

export class AppStore<DataInterface, TActionName extends string, DataClass = any> {

  private static localStoragePrefix = 'angularAppStore_';
  private static state: IStateInMemory = {};

  // See IAppStore comments for information about the properties below.
  private objectName: string;
  private objectIdKey: string;
  private actions: { [actionName in TActionName]?: IAction<DataClass | DataInterface> } = {};
  private objectGetFn: (...params) => Observable<any>;
  private objectMapFn?: (obj: DataClass) => any;
  private expirationMs = 300000 ; // Defaults the expirationMs for each app state



  /**
   * Removes the entire app state, forcing everything to be refreshed from the server.
   * @returns void
   */
  public static flush(): void {
    for (const key of Object.keys(AppStore.state)) {
      localStorage.removeItem(`${AppStore.localStoragePrefix}${key}`);
      AppStore.state[key].map = {};
      AppStore.state[key].completedActions = {};
    }
  }

  constructor(
    config: IAppStore<DataClass, TActionName> // Pass in an IAppStore interface to instantiate an app state
  ) {
    Object.keys(config).forEach(k => this[k] = config[k]);
    AppStore.state[this.objectName] = {
      array$: new BehaviorSubject(Object.values({}).map((o: any) => o.object)),
      map: {},
      completedActions: {}
    };

    if (!this.objectMapFn) {
      this.objectMapFn = (data: any) => data;
    }
  }

  /**
   * Registers an action
   */
  public registerAction(name: TActionName, action: IAction<DataInterface>): void {
    this.actions[name] = action;
  }

  /**
   * Gets the object for the specified id. If no object exists in state, or the object in state is expired, requests it from the server.
   */
  public get(id: string): Observable<DataClass | DataInterface> {
    const timestamp = new Date().getTime();
    const mapObj = AppStore.state[this.objectName].map[id];
    let getDoc = () => this.objectGetFn(id).pipe(tap((doc: any) => this.updateStore(doc)));
    if (!!mapObj && mapObj.appStateExpires > timestamp - this.expirationMs) {
      getDoc = () => of(mapObj.object);
    }
    return getDoc().pipe(
      map((doc: any) => this.objectMapFn(doc))
    );
  }

  /**
   * Inserts objects into the store
   */
  public insert(data: any) {
    this.updateStore(data);
  }

  /**
   * Gets an observable stream of an array of the values in state. This observable will emit automatically update as the state is changed.
   * @returns Observable
   */
  public getState(): Observable<DataInterface[]> {
    return AppStore.state[this.objectName].array$.pipe(
      map((r: DataClass[]) => r.map((o: DataClass) => this.objectMapFn(o)))
    );
  }

  /**
   * Gets the initialValue of the state, in the form of an array of objects
   * @returns T[]
   */
  public getArrayValue(): DataInterface {
    return AppStore.state[this.objectName].array$.value.map((o: any) => this.objectMapFn(o)) as any;
  }

  /**
   * Gets all local values based on their expiration timestamp.
   */
  public getByExpirationTimestamp(operator: '>' | '<' | '>=' | '<=' | '===' | '!==', timestamp: Date | number = new Date().getTime() - this.expirationMs): DataInterface[] {
    return Object.values(AppStore.state[this.objectName].map).filter((o: { appStateExpires: number, object: DataInterface }) => {
      // tslint:disable-next-line:no-eval
      return eval(`o.appStateExpires ${operator} ${timestamp}`);
    }).map(o => this.objectMapFn(o.object));
  }

  /**
   * Dispatches an action. If the action is a 'get' type, is cached and is not expired, uses the cached data.
   */
  public dispatch(actionName: TActionName, ...args): Observable<any> {
    const action: IAction<DataClass> = this.actions[actionName as string];
    const actionIndex = this.getActionIndex(actionName, action.type, args);
    const previouslyCompleted = AppStore.state[this.objectName].completedActions[actionIndex];
    const timestamp = new Date().getTime();
    const isFromCache = action.type === 'get' && !action.discardResponse && !!previouslyCompleted && previouslyCompleted.timestamp > (timestamp - this.expirationMs);

    const dispatchFn = () => isFromCache ? of(previouslyCompleted.data) : action.dispatch(...args);

    return dispatchFn().pipe(
      map((data: any) => {
        if (!isFromCache) {
          if (action.type === 'get') {
            AppStore.state[this.objectName].completedActions[actionIndex] = { timestamp, data };
          }
          let reducedData = [];
          if (action.type === 'delete') {
            const ids = Array.isArray(args[0]) ? args[0] : [args[0]];
            const newMap = AppStore.state[this.objectName].map;
            for (const id of ids) {
              if (!!id) {
                delete newMap[id];
              }
            }
            AppStore.state[this.objectName].map = newMap;
          } else {
            const mapParams = this.getMapParams(!isFromCache);
            reducedData = action.reduce ? action.reduce(data, ...<any>mapParams) : data;
          }
          this.updateStore(reducedData);
        }
        const mapFn = action.map ? action.map : (r: any | any[], lookup) => (Array.isArray(r) ? r :  [r]).filter(d => !!d).map(d => lookup[d[this.objectIdKey]]);
        return mapFn(data, ...<any>this.getMapParams(!isFromCache));
      })
    );
  }

  /**
   * Gets the action's index to be stored in the completed actions.
   */
  private getActionIndex(actionName: TActionName, type: TActionType, args: any[]): string {
    const stringSuffix = type === 'get' ? args.map(a => typeof a !== 'string' && typeof a !== 'number' ? JSON.stringify(a) : String(a)).join('_') : '';
    return `${actionName}${stringSuffix}`;
  }

  /**
   * Saves and object<T> or an array of objects in the local store.
   */
  private updateStore(data: any | any[]): void {
    const appStateExpires = new Date().getTime() + this.expirationMs;
    const newMapObjects = (Array.isArray(data) ? data : [data]).map(object => <any>{ object, appStateExpires });
    for (const newMapObject of newMapObjects) {
      if (!!newMapObject && !!newMapObject.object) {
        AppStore.state[this.objectName].map[newMapObject.object[this.objectIdKey]] = newMapObject;
      }
    }
  }

  /**
   * Gets the local state lookup object, which is just a map of <T> objects indexed by their IDs, and a map of object expirations.
   */
  private getMapParams(isStoreUpdated?: boolean): [Map<string | number, DataInterface>, Map<string | number, number>]  {
    const latestMapVals: any = Object.values(AppStore.state[this.objectName].map);
    if (!!isStoreUpdated) {
      AppStore.state[this.objectName].array$.next(latestMapVals.map(o => o.object));
    }
    const stateLookup = latestMapVals.reduce((p, c) => <any>{ ...p, [c.object[this.objectIdKey]]: c.object }, {});
    const expirationMap = latestMapVals.reduce((p, c) => <any>{ ...p, [c.object[this.objectIdKey]]: c.appStateExpires }, {});
    return [stateLookup, expirationMap];
  }

}
