
<div [ngSwitch]="!!isFormSubmitted" style="margin: 50px auto 50px auto; text-align: left; max-width: 500px;padding: 0 20px;">
  <h1 *ngSwitchCase="false" class="mat-headline">Password Reset</h1>

  <ng-container *ngSwitchCase="false">
      <mat-label>Enter the email address you use for Wedzee and we'll send you a link to reset your password.</mat-label>
      <br><br>
      <form *ngIf="!!resetPasswordForm" [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
        <mat-form-field style="min-width: 300px;">
          <mat-label>Email address</mat-label>
          <input formControlName="email" matInput placeholder="Enter email" type="text">
          <mat-error *ngIf="!resetPasswordForm.get('email').valid">Please enter a valid email adress</mat-error>
        </mat-form-field>

        <br>

        <mat-error *ngIf="!!noEmailError" [innerHtml]="noEmailError"></mat-error>
        <br *ngIf="!!noEmailError">

        <button mat-raised-button
          color="accent"
          [disabled]="!resetPasswordForm.valid"
          type="submit"
        >
          Submit
        </button>
      </form>
  </ng-container>

  <ng-container *ngSwitchCase="true">
      <h3 class="mat-headline">Email sent!</h3>
      <mat-label>We sent an email to {{resetPasswordForm.get('email').value}}. Please follow the instructions in the email to reset your password.</mat-label>
      <br><br>
      <button mat-raised-button color="accent" routerLink="/">Shop Wedzee</button>
  </ng-container>

</div>

