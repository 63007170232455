import { Directive, HostListener, Input } from '@angular/core';

import { AlertService } from '../../services/alert/alert.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzCopyToClipboard]'
})
export class CopyToClipboardDirective {
  @Input() copyText: string;

  constructor(
    private alertSrv: AlertService
  ) { }

  @HostListener('click', ['$event']) onClick($event) {
    this.copyToClipboard(this.copyText);
  }

  copyToClipboard(copyText: string) {
    const textInput: HTMLInputElement = <HTMLInputElement>document.createElement('input');
    textInput.value = copyText;
    document.body.appendChild(textInput);
    textInput.select();
    document.execCommand('copy');
    this.alertSrv.successToast('Copied!');
    document.body.removeChild(textInput);
  }

}
