<div style="margin-top: 50px;" ngStyle.xs="margin-left:20px;margin-right:20px;">
  <div class="videoheader">Check out our video to see just how easy it is to use Wedzee.</div>
</div>
<div style="margin-top: 30px;">
  <iframe src="https://player.vimeo.com/video/537852552" style="border: 1px solid #CCC;" width="640" height="360" ngStyle.xs="width:320px;height:180px;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
</div>


<ng-container [ngSwitch]="isASeller()">
  <div class="text-center cta buy-sell-tab-content"> 
    <div style="font-size: 22px; color:#000000; font-weight: bold; font-family: 'century_gothicregular'; margin-bottom:45px; margin-top:20px;" ngStyle.xs="font-size:20px;margin-bottom:25px; margin-top:0px;font-weight: bold;">Sell it. Ship it. Get Paid.</div>
    <div>
      <div fxLayout="row wrap"  fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column">
        <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
          <img  src="./../../../../assets/img/Sell_SellIt.png" />
          <div style="width: 265px; float:left; position: relative; display: block;  height: 12px; margin-top : -100px; margin-bottom:  50px; margin-right:  15px; margin-left:  290px;" class="dotted-line"
          fxShow.gt-sm="true" fxHide.lt-sm fxHide.lt-md fxHide.lt-lg>
          </div>
          <div class="rsn-title">Sell It</div>
          <div class="mat-subheading-2 rsn-detl">
            Listings are free. Take good photos, clearly describe your item, and set a price.
          </div>
        </div>
        <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
          <img  src="./../../../../assets/img/Sell_ShipIt.png" />
          <div style="width: 265px; float: left; position: relative; display: block; height: 12px; margin-top: -100px; margin-bottom: 50px; margin-right: 15px; margin-left: 290px;"  class="dotted-line"
          fxShow.gt-sm="true" fxHide.lt-sm fxHide.lt-md fxHide.lt-lg>
          </div>
          <div class="rsn-title">Ship It</div>
          <div class="mat-subheading-2 rsn-detl">
            After your item sells, a shipping label will be emailed to you. Carefully pack it, and ship it.
          </div>
        </div>
        <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
          <img  src="./../../../../assets/img/Sell_GetPaid.png" />
          <div class="rsn-title">Get Paid</div>
          <div class="mat-subheading-2 rsn-detl">
            After the item is successfully delivered funds will be directly deposited, minus an 8% fee.
          </div>
        </div>
      </div>
      <br>
      <button *ngSwitchCase="false" wzMustSignIn activity="enroll as a seller." (signedInOutput)="becomeASeller()"
        mat-flat-button color="accent" style="margin:20px auto 40px" class="bg-offset-color" ngStyle.xs="margin:0px auto">
        Start Selling
      </button>
      <button *ngSwitchCase="true" mat-flat-button color="accent" routerLinkAbsolute="seller/home"
      style="font-family: 'century_gothicregular' !important; margin:20px auto 40px;" class="bg-offset-color">
        Go to Seller Account
      </button>
    </div>
  </div>
  <br> 
</ng-container>