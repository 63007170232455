<div mat-dialog-content *ngIf="!!imageFile || !!base64Img" [ngSwitch]="!!isBase64">
  <mat-hint>Please crop your image so it looks great on the site</mat-hint>
  <br><br>


  <div *ngIf="!hasImgLoaded" style="height: 100%; width: 100%; min-width: 200px; min-height: 200px;">
    <mat-spinner style="position: absolute; left: 50%; margin-left: -82px; top: 50%; margin-top: -82px;" color="accent" diameter="150"></mat-spinner>
  </div>


  <image-cropper *ngSwitchCase="false" [attr.hidden]="true"
    [maintainAspectRatio]="!!ratio"
    [aspectRatio]="!!ratio ? ratio.width / ratio.height : null"
    outputType="file"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>

  <image-cropper *ngSwitchCase="true"
    [imageBase64]="base64Img"
    [maintainAspectRatio]="!!ratio"
    [aspectRatio]="!!ratio ? ratio.width / ratio.height : null"
    outputType="file"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (cropperReady)="imageLoaded()"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>

</div>
<div mat-dialog-actions>
  <button (click)="saveCroppedImage()" mat-flat-button color="accent">Save</button>
  <button style="margin-left: 10px;" mat-stroked-button mat-dialog-close>Cancel</button>
  <ng-container style="float: right">
    <button matTooltip="Rotate right" mat-icon-button (click)="rotate('right')">
      <mat-icon>rotate_right</mat-icon>
    </button>
    <button matTooltip="Rotate left" mat-icon-button (click)="rotate('left')">
      <mat-icon>rotate_left</mat-icon>
    </button>
  </ng-container>
</div>
