<div mat-dialog-title class="children-inline-block full-width">
  <div class="text-overflow-ellipsis" style="width: 85%!important;">{{title}}</div>
  <button style="float: right;" mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-actions id="share-actions-element" fxLayout="row" fxLayoutAlign="space-evenly">
  <a [href]="facebookUrl" target="_blank">
    <img matTooltip="Share on Facebook" (click)="close()" src="assets/img/social-logos/facebook.svg" alt="facebook">
  </a>
  <a [href]="twitterUrl" (click)="close()" target="_blank">
    <img matTooltip="Share on Twitter" src="assets/img/social-logos/twitter.svg" alt="twitter">
  </a>
  <!-- <a [href]="instagramUrl" (click)="close()" target="_blank">
    <img matTooltip="Share on Instagram" src="assets/img/social-logos/instagram.svg" alt="instagram">
  </a> -->
  <a [href]="pinterestUrl" (click)="close()" target="_blank" data-pin-custom="true">
    <img matTooltip="Share on Pinterest" src="assets/img/social-logos/pinterest.svg" alt="pinterest">
  </a>
  <br>
  <input style="margin-top: 10px; margin-bottom: 10px;" matInput (click)="$event.target.setSelectionRange(0, url.length)" [value]="url" />
</mat-dialog-actions>
