import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, Subject } from 'rxjs';
import { ICategory } from 'wz-types/categories';

import { Globals, IMenuNavLink } from '../../../classes';
import { CategoryUrlPipe } from '../../../pipes/category-url/category-url.pipe';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss']
})
export class NavbarMenuComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  destroy$: Subject<void> = new Subject();
  megaMenuChunks$: BehaviorSubject<{ name: string; id: string; class: 'child' | 'grandchild' }[][]> = new BehaviorSubject([]);
  allColors = Globals.initialLoadData.weddingColors;
  menuNavLinks: IMenuNavLink[] = [];
  @HostListener('window:scroll', [])
  onscroll() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }

  ngOnInit() {
    const links = Globals.menuNavLinks || [];
    links.pop();
    links.splice(4, 1);
    // Below is a hack Michael asked for https://github.com/JoeOsterfeld/wedzee/issues/156
    // const paperItems = links[4];
    // const receptionDecor = links[5];
    // links[4] = receptionDecor;
    // links[5] = paperItems;
    // this.menuNavLinks = [...links.slice(0, links.length - 1), { name: 'Shop by Color', routerLink: ['wedding-colors'] }, links[links.length - 1]];
    this.menuNavLinks = [...links.slice(0, links.length - 1),  links[links.length - 1]];
    this.menuNavLinks = links;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * This function is very important; deals with Angular material menu classes and sets them as the menu opens. Do not change or remove.
   */
  openMegaMenu(category: ICategory, trigger: MatMenuTrigger, event?) {
    if (!this.trigger) {
      this.trigger = trigger;
    }
    this.getMegaMenuChunks(!!category ? category.id : 'Shop by color');
    trigger.openMenu();
    const  pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
         if (el.children.length > 0) {
           if (el.children[0].classList.contains('mega-menu')) {
             el.classList.add('mega-menu-pane');
           }
         }
    });
  }

  mouseLeftButton(e: MouseEvent) {
    if (e.movementY < 0) {
      this.trigger.closeMenu();
    }
  }

  getMegaMenuChunks(id: string) {
    const isColors = id === 'Shop by color';
    let items = [];
    if (!isColors) {
      const children = Globals.categories.filter(c => c.parentCategoryId === id);
      for (const c of children) {
        items.push({ name: c.name, routerLink: new CategoryUrlPipe().transform(c), class: 'child' });
        const grandChildren = Globals.categories.filter(ch => ch.parentCategoryId === c.id);
        for (const g of grandChildren) {
          items.push({ name: g.name, routerLink: new CategoryUrlPipe().transform(g), class: 'grandchild' });
        }
      }
    } else {
      this.megaMenuChunks$.next([items]);
      items = this.allColors
        .map(c => <any>{ name: c.name, routerLink: `color/${c.id}`, class: 'child' })
        .sort((a, b) => a.name < b.name ? -1 : 1);
    }
    const numOfColumns = 4;
    let chunks = [];
    const colLength = items.length < 16 ? numOfColumns : Math.ceil(items.length / numOfColumns);

    if (!isColors) {
      for (let i = 0; i < numOfColumns; i++) {
        const numPrevItems = chunks.filter((v, index) => index < i).reduce((p, c) => p + c.length, 0);
        const startIndex = numPrevItems;
        let endIndex = startIndex + colLength;
        while (items[endIndex] && items[endIndex].class !== 'child') {
          endIndex++;
        }
        const newChunk = items.slice(startIndex, endIndex);
        chunks.push(newChunk);
      }
    } else {
      const getChunks = (columnLength: number) => new Array(numOfColumns).fill(undefined)
        .map((v, i) => items.slice(i * columnLength, (i * columnLength) + columnLength));
      chunks = getChunks(colLength);
    }
    this.megaMenuChunks$.next(chunks);
  }

  getSubcategories(id: string) {
    return Globals.categories.filter(c => c.parentCategoryId === id);
  }

  categoryHasNestedSubcategories(id: string) {
    let hasNestedSubcategories = false;
    const categories = Globals.categories.filter(c => c.parentCategoryId === id);
    for (const category of categories) {
      if (category && category['subCategories'] && category['subCategories'].length > 0) {
        hasNestedSubcategories = true;
      }
    }
    return hasNestedSubcategories;
  }

  getOtherItemsLink(links: IMenuNavLink[]): ICategory {
    const result = (links.find(l => l && l.doc && l.doc.name === 'Other items') || <any>{}) as any;
    return result;
  }


}
