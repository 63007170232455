import { Component, Input, OnInit } from '@angular/core';
import { IListing } from 'wz-types/listings';

@Component({
  // selector: 'wz-featured-listing',
  templateUrl: './featured-listings.component.html',
  styleUrls: ['./featured-listings.component.scss']
})
export class FeaturedListingsComponent implements OnInit {
  @Input() doc?: IListing = <any>{};

  constructor() { }

  ngOnInit() {
  }

}
