import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { IPolicy } from 'wz-types/pages-policies';
import { Globals } from '~shared/classes';
import { SeoService } from '~shared/services';

@Component({
  selector: 'wz-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent implements OnInit {
  termsHtml: string;

  constructor(
    private http: HttpClient,
    private seoSrv: SeoService
  ) { }

  ngOnInit() {
    this.seoSrv.generateTags({ title: 'Privacy' });
    this.http.get(`${Globals.environment.apiUrl}pages-policies/privacyPolicy`).pipe(
      map((policy: IPolicy) => {
        this.termsHtml = policy.html;
      })
    ).subscribe();
  }
}
