import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { IListing } from 'wz-types/listings';
import { IShoppingCartItem } from 'wz-types/shopping-cart';

import { Globals } from '../../../classes';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-price-qty-shipping',
  templateUrl: './price-qty-shipping.component.html',
  styleUrls: ['./price-qty-shipping.component.scss']
})
export class PriceQtyShippingComponent implements OnInit, OnChanges {
  @Input() listing: IListing;
  @Input() item: IShoppingCartItem;
  @Output() isChangingQuantity: EventEmitter<boolean> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();
  @Input() removeButtonPadding = '';
  @Input() isEditDisable;

  isCalculating$ = Globals.user.ShoppingCart.isCalculating$;

  isChangingQty: boolean;
  availableQuantity: number;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges () {
    this.availableQuantity = this.getQuantityAvailable();
  }

  changeQuantity(qty: number) {
    Globals.user.ShoppingCart.addUpdateItem({ ...this.item, qty });
  }

  toNumber(str: string): number {
    return Number(str);
  }

  getQuantityAvailable(): number {
    let qtyAvailable = 0;
    if (this.listing.qtyAvailable) {
       qtyAvailable = this.listing.qtyAvailable;
    } else {
      if (this.listing.variation.mergeGrid) {
        const v1Name = this.listing.variation.selectedVariations[0].name;
        const v2Name = this.listing.variation.selectedVariations[1].name;
        const obj = this.listing.variation.gridValues[0];
        const count = Object.keys(obj).length;
        for (let i = 0; i < count; i++) {
          const key = Object.keys(obj)[i];
          const value = obj[key];
          const opt1 = value[1][v1Name];
          const opt2 = value[1][v2Name];
          if (this.item.variations[0].variationOption !== undefined && this.item.variations[0].variationOption !== '') {
            if (opt1 === this.item.variations[0].variationOption && opt2 === this.item.variations[1].variationOption) {
              qtyAvailable = Number(value[2].Quantity);
            }
          }
        }
      } else {
        const v2Name = this.listing.variation.selectedVariations[0].name;
        const obj = this.listing.variation.gridValues[0];
        const count = Object.keys(obj).length;
        for (let i = 0; i < count; i++) {
          const key = Object.keys(obj)[i];
          const value = obj[key];
          const opt1 = value[1][v2Name];
          if (opt1 === this.item.variations[0].variationOption) {
            qtyAvailable = Number(value[2].Quantity);
          }
        }
      }
    }
    return qtyAvailable;
  }

}
