  <div style="min-height: 600px!important">

    <div>
      <h1 mat-dialog-title style="font-weight: bold; float: left">
        Get delivery cost
      </h1>
      <button mat-icon-button mat-dialog-close style="float: right;">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <form [formGroup]="deliveryCostForm">

      <mat-form-field class="full-width">
        <mat-label>Country</mat-label>
        <select formControlName="CountryCode" matNativeControl required>
          <option *ngFor="let ctr of country" [value]="ctr.value">{{ctr.viewValue}}</option>
        </select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Zip or postal code</mat-label>
        <input formControlName="PostCode" matInput placeholder="Post Code"   type="text"   />      
        
         <mat-error *ngIf="!deliveryCostForm.get('PostCode').valid">Please enter a valid post code</mat-error>  
      </mat-form-field>

      <mat-dialog-actions>
   
      <button mat-raised-button color="accent"  [disabled]="!deliveryCostForm.valid" (click)="submitdeliveryCostForm()">
                  
        {{submitButtonText}}
      </button>
      </mat-dialog-actions>

    </form>
  </div>
