import { Component, ElementRef, OnDestroy, OnInit, ViewChild, HostListener, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, UrlSegment } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { filter, map, take, takeUntil, takeWhile } from 'rxjs/operators';
import { ICategory } from 'wz-types/categories';
import { IWeddingColor } from 'wz-types/home-page';
import { IProductListPageQueryParams } from 'wz-types/listings';
import { Globals } from '~shared/classes';
import { ScrollableDirective } from '~shared/directives';
import { SeoService, CommonService } from '~shared/services';
import { IListingQueryResult, ListingsStore } from '~shared/stores';

import { ProductListFilterComponent } from '../../components/product-list-filter/product-list-filter.component';

export interface IProductListParams {
  categories: ICategory[];
  weddingColorId: string;
  price: {
    low: number;
    high: number;
  };
}

@Component({
  selector: 'wz-product-list-page',
  templateUrl: './product-list-page.component.html',
  styleUrls: ['./product-list-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductListPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('productListContainer') productListContainer: ElementRef;
  @ViewChild('productListFilter') productListFilter: ProductListFilterComponent;
  listingQueryResult: IListingQueryResult;
  filterForm: FormGroup;
  filterOn = false;
  categories: ICategory[] = [];
  isNewArrivals: boolean;
  searchText: string;
  mainColor$: Observable<IWeddingColor>;
  selectedFilterColor$: Observable<IWeddingColor>;
  weddingColors$: Observable<IWeddingColor[]>;
  selectedOrderBy = 'relevance';
  scrolledToFooter$ = ScrollableDirective.scrolledToFooter$;
  destroy$: Subject<void> = new Subject();
  mainMenuCategories = Globals.categories.filter(c => c.isInMainMenu);
  screenWidth: any;

  queryParams$: BehaviorSubject<IProductListPageQueryParams> = new BehaviorSubject({
    orderBy: <'relevance'>'relevance',
    pageSize: 50,
    pageIndex: 0
  });

  private _previousPageIndex: number = null;
  private _previousOrderBy: string = null;
  private _lastPoppedUrl = '';
  private _scrollInterval = 500;
  private _subListenToNavigationChanges: any;
  private _scrollEventsSubscribed = false;
  private _yScrollStack: number[] = [];

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private listingsStore: ListingsStore,
    private bottomSheet: MatBottomSheet,
    private firestore: AngularFirestore,
    private seoSrv: SeoService,
    private location: Location
  ) { 
    if (this._scrollEventsSubscribed === false) {
      this.location.subscribe((ev: PopStateEvent) => {
        this._lastPoppedUrl = ev.url;
      });
      this.router.events.subscribe((ev: any) => {
        if (ev instanceof NavigationStart) {
          if (ev.url !== this._lastPoppedUrl) {
            this._yScrollStack.push(window.scrollY);
          } else {
            this._lastPoppedUrl = undefined;
            const yposition = this._yScrollStack.pop();
            let maxInterval = 0; // used to stop subscription
            interval(this._scrollInterval)
              .pipe(
                takeWhile(_ => window.scrollY < yposition && maxInterval < 5000)
              )
              .subscribe(_ => {
                maxInterval += this._scrollInterval;
                window.scrollTo({
                  top: yposition,
                  left: 0,
                  behavior: 'smooth',
                });
              });
          }
        }
      });
      this._scrollEventsSubscribed = true;
    }
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      const previousProductListPageQueryParams = this.listingsStore.getProductListPageQuery();
      if (!!this.searchText && previousProductListPageQueryParams === undefined) {
        if (this.searchText.toLowerCase() === 'new') {
          this.productListFilter.setConditionOptionAndExpandPanel('New', previousProductListPageQueryParams?.expandedPanel === undefined);
        } else if (this.searchText.toLowerCase() === 'handcrafted') {
          this.productListFilter.setConditionOptionAndExpandPanel('Custom made', previousProductListPageQueryParams?.expandedPanel === undefined);
        } else if (this.searchText.toLowerCase() === 'used') {
          this.productListFilter.setConditionOptionAndExpandPanel('Gently used', previousProductListPageQueryParams?.expandedPanel === undefined);
        }
      }
    }, 100);
  }

  private listenForNavigationChanges(): void {
    // Listens for navigation changes that stay on this component and triggers ngOnInit to load the latest data
    if (this._subListenToNavigationChanges === undefined) {
      this._subListenToNavigationChanges = this.router.events.pipe(
        filter((e: any) => {
          const isNavEnd = e instanceof NavigationEnd;
          const baseName = e.url ?  e.url.split('/').filter(p => !!p)[0] : undefined;
          const isThisPage = baseName === 'category' || baseName === 'color' || baseName === 'search';

          if (e.url === '/' || (isThisPage === false && baseName !== undefined && baseName !== 'product')) {
            this.listingsStore.clearProductListPageQuery();
          } else if (baseName === 'search') {
            const previousProductListPageQueryParams = this.listingsStore.getProductListPageQuery();
            if (previousProductListPageQueryParams !== undefined) {
              const urlSplit = e.url.split('/').filter(p => !!p);
              if (urlSplit.length > 1 && previousProductListPageQueryParams.searchText !== urlSplit[1]) {
                this.listingsStore.clearProductListPageQuery();
              }
            }
          }

          return isNavEnd && isThisPage;
        }),
        map((event: NavigationEnd) => {
          this.ngOnInit();
        }),

        takeUntil(this.destroy$)
      ).subscribe();
    }
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.seoSrv.generateTags({ title: 'Shop items' });
    this.listenForNavigationChanges();

    const previousProductListPageQueryParams = this.listingsStore.getProductListPageQuery();
    let usePreviousProductListPageQueryParams = previousProductListPageQueryParams !== undefined;

    const pathArray = this.route.snapshot.url.map((u: UrlSegment) => u.path);
    if (!!this.route.snapshot.params.searchText) this.searchText = this.route.snapshot.params.searchText;
    const isCategoryProducts = pathArray[0] === 'category';
    const isWeddingColorProducts = pathArray[0] === 'color';
    this.isNewArrivals = pathArray[0] === 'new-arrivals';
    this.updateQueryParams({ isNewArrivals: this.isNewArrivals });
    if (isCategoryProducts) {
      const categoryId = pathArray[1];
      this.updateQueryParams({ categoryId });

      if (usePreviousProductListPageQueryParams) {
        this._previousPageIndex = previousProductListPageQueryParams.pageIndex;
        this._previousOrderBy = previousProductListPageQueryParams.orderBy;
        previousProductListPageQueryParams.categoryId = categoryId; // reset to latest selected
        const selectedCategory = Globals.categoriesLookup[categoryId];
        if ((this.searchText && !this.searchText.toLowerCase().includes('dress')) || selectedCategory.name.toLowerCase().indexOf('wedding dress') === -1) {
          this.updateQueryParams({ dressSilhouette: null, dressFabric: null, dressSize: null });
        }
      }
    } else if (isWeddingColorProducts) {
      const mainColorId = pathArray[1];
      this.mainColor$ = of(Globals.colors.find(c => c.id === mainColorId));
      this.updateQueryParams({ colorId: mainColorId });

      if (usePreviousProductListPageQueryParams && previousProductListPageQueryParams.colorId === mainColorId) {
        this._previousPageIndex = previousProductListPageQueryParams.pageIndex;
        this._previousOrderBy = previousProductListPageQueryParams.orderBy;
      } else {
        usePreviousProductListPageQueryParams = false;
      }
    } else if (this.isNewArrivals) {
      if (usePreviousProductListPageQueryParams && previousProductListPageQueryParams.isNewArrivals) {
        this._previousPageIndex = previousProductListPageQueryParams.pageIndex;
        this._previousOrderBy = previousProductListPageQueryParams.orderBy;
      } else {
        this.updateQueryParams({ orderBy: 'mostRecent' });
        this.selectedOrderBy = 'mostRecent';
        usePreviousProductListPageQueryParams = false;
      }
    } else if (!!this.searchText && previousProductListPageQueryParams === undefined) {
      if (this.searchText.toLowerCase() === 'new') {
        this.setParamsAndOrderByFromSpecialSearchText('New', previousProductListPageQueryParams);
        usePreviousProductListPageQueryParams = usePreviousProductListPageQueryParams && previousProductListPageQueryParams.condition === 'New';
      } else if (this.searchText.toLowerCase() === 'handcrafted') {
        this.setParamsAndOrderByFromSpecialSearchText('Custom made', previousProductListPageQueryParams);
        usePreviousProductListPageQueryParams = usePreviousProductListPageQueryParams && previousProductListPageQueryParams.condition === 'Custom made';
      } else if (this.searchText.toLowerCase() === 'used') {
        this.setParamsAndOrderByFromSpecialSearchText('Gently used', previousProductListPageQueryParams);
        usePreviousProductListPageQueryParams = usePreviousProductListPageQueryParams && previousProductListPageQueryParams.condition === 'Gently used';
      } else {
        this.updateQueryParams({ searchText: this.searchText });
        usePreviousProductListPageQueryParams = usePreviousProductListPageQueryParams && previousProductListPageQueryParams.searchText === this.searchText;
      }
    }
    
    if (usePreviousProductListPageQueryParams) {
      this.filterOn = true;
      this._previousPageIndex = previousProductListPageQueryParams.pageIndex;
      this._previousOrderBy = previousProductListPageQueryParams.orderBy;
      let priceLow = null, priceHigh = null;

      for (const key in previousProductListPageQueryParams) {
        if (previousProductListPageQueryParams.hasOwnProperty(key)) {
            const value = previousProductListPageQueryParams[key];
            if (key === 'priceLow') {
              priceLow = value;
            } else if (key === 'priceHigh') {
              priceHigh = value;
            } else {
              this.updateQueryParams({ [key]: value });
            }
        }
      }

      if (priceLow !== null && priceHigh !== null) {
        this.updateQueryParams({ priceLow: priceLow, priceHigh: priceHigh });
      }
    } else {
      this.listingQueryResult = undefined;
      this.listingsStore.clearProductListPageQuery();
    }

    if (!this.listingQueryResult) {
      this.listingQueryResult = this.listingsStore.productListPageQuery(this.queryParams$);
    }

    if (this._previousPageIndex !== null) {
      this.selectedOrderBy = this._previousOrderBy;
    }

    this.weddingColors$ = of(Globals.colors);
  }
  
  private setParamsAndOrderByFromSpecialSearchText(condition: string, previousProductListPageQueryParams: IProductListPageQueryParams) {
    this.updateQueryParams({ condition: condition, orderBy: 'mostRecent' });
    this.selectedOrderBy = 'mostRecent';
    if (previousProductListPageQueryParams !== undefined) { previousProductListPageQueryParams.orderBy = 'mostRecent'; }
  }

  @HostListener ('window:resize', ['$event'])
  onResize (event) {
    this.screenWidth = 0;
    setTimeout(() => {
      this.screenWidth = event.target.innerWidth;
    }, 0);
  }

  activeTab(item) {
    this.mainMenuCategories.forEach((val) => {
      val['active'] = false;
    });
    item.active = true;
  }

  ngOnDestroy() {
    this._subListenToNavigationChanges.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSkeletonProducts(): undefined[] {
    return new Array(20);
  }

  updateQueryParams(newParams: { [propertyName: string]: string | number | boolean }) {
    if (!!newParams.resetToCategoryPath) {
      const previousProductListPageQueryParams = this.listingsStore.getProductListPageQuery();
      previousProductListPageQueryParams.pageIndex = 0;
      previousProductListPageQueryParams.orderBy = 'relevance';
      this.router.navigateByUrl(`/category/${newParams.resetToCategoryPath}`);
    } else {
      const prevParams = this.queryParams$.value;
      const { priceHigh, priceLow } = this.queryParams$.value;
      if (newParams.colorId) this.selectedFilterColor$ = of(Globals.colors.find(c => c.id === newParams.colorId));
      if (newParams.orderBy === 'mostRecent' && !!priceHigh && !!priceLow) {
        newParams.priceHigh = null;
        newParams.priceLow = null;
      } else if (newParams.orderBy) {
        const previousProductListPageQueryParams = this.listingsStore.getProductListPageQuery();
        if (previousProductListPageQueryParams !== undefined && 
          (newParams.orderBy === 'priceLowToHigh' || newParams.orderBy === 'priceHighToLow' || newParams.orderBy === 'mostRecent' || newParams.orderBy === 'relevance')) {
          previousProductListPageQueryParams.orderBy = newParams.orderBy;
        }
      }
      console.log({...prevParams, ...newParams});
      this.queryParams$.next({...prevParams, ...newParams});
    }
  }


  getCategory(id: string): ICategory {
    return Globals.categoriesLookup[id];
  }

  openBottomSheetFilter() {
    ProductListFilterComponent.presentBottomSheet(this.queryParams$.value, this.bottomSheet).pipe(
      take(1),
      filter((res: any) => !!res),
      map((res: any) => {
        this.updateQueryParams(res);
      })
    ).subscribe();
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  public isInIframe(): boolean {
    return this.commonService.isInIframe();
  }
}
