import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayOfNumbers'
})
export class ArrayOfNumbersPipe implements PipeTransform {

  transform(value: number): any {
    const result = [];
    for (let i = 1; i <= value; i++) {
      result.push(i);
    }
    return result;
  }

}
