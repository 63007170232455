import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '~shared/classes';
import { SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'wz-affiliate-program',
  templateUrl: './affiliate-program.component.html',
  styleUrls: ['./affiliate-program.component.scss']
})
export class AffiliateProgramComponent implements OnInit {

  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  loadSlider = false;
  config: any;

  constructor() { }

  ngOnInit() {
    this.loadSlider = false;
    const config = {...Globals.defaultSwiperConfig};
    config.spaceBetween = 20;
    config.slidesPerView = 3;
    config.breakpoints = {
      280 : { slidesPerView: 1, spaceBetween: 0 },
      320 : { slidesPerView: 1, spaceBetween: 0 },
      350 : { slidesPerView: 1, spaceBetween: 0 },
      411 : { slidesPerView: 1, spaceBetween: 0 },
      540 : { slidesPerView: 1, spaceBetween: 0 },
      768 : { slidesPerView: 3, spaceBetween: 0 },
      1024: { slidesPerView: 3, spaceBetween: 0 },
      1366 : { slidesPerView: 3 }
    };
    this.config = config;
    this.loadSlider = true;
  }

  public applyNow($event): void {
    window.open(Globals.affiliateUrl, '_blank');
    $event.stopPropagation();
  }
}
