import 'firebase/auth';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { combineLatest, forkJoin, from as fromPromise, Observable, of as observableOf, of, Subject, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { IUser } from 'wz-types/user';

import { Globals, User } from '../../classes';
import { LikesByListingStore, ListingsStore } from '../../stores';
import { AlertService } from '../alert/alert.service';
import { wzCatchObservableError } from '../logging/logging.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * The navbar component listens to this subject and will open the login dialog when it's triggered.
   */
  public static presentLoginDialog$: Subject<'signIn' | 'register'> = new Subject();

  fileName = 'auth.service.ts';
  firebaseAuth: AngularFireAuth;

  constructor(
    private http: HttpClient,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private listingsStore: ListingsStore,
    private likesStore: LikesByListingStore,
    private router: Router,
    private storage: AngularFireStorage,
    private alertSrv: AlertService
  ) {
    this.firebaseAuth = angularFireAuth;

    this.listenToAuthState();
  }

  listenToAuthState() {
    const self = this;
    this.angularFireAuth.authState.pipe(
      delay(500),
      mergeMap((firebaseAuthObj: firebase.User) => {
        let nextStep = () => observableOf([undefined, undefined]);
        if (!!firebaseAuthObj && !!firebaseAuthObj.uid) {
          nextStep = () => combineLatest([
            observableOf(firebaseAuthObj),
            this.firestore.doc(`users/${firebaseAuthObj.uid}`).valueChanges().pipe(takeUntil(Globals.signOut$))
          ]);
        }
        return nextStep();
      }),
      map((data: [firebase.User, IUser]) => {
        const userDoc = data[1];
        const firebaseUser = data[0];
        if (!Globals.user) {
          Globals.setUser(new User(self.http, self.router, self.firestore, this.storage, self, self.listingsStore, this.likesStore, firebaseUser, userDoc));
        } else {
          Globals.user.refreshUser(userDoc, firebaseUser);
        }
        Globals.userInstantiated$.next();
      }),
      takeUntil(Globals.destroy$),
      catchError((err) => {
        self.listenToAuthState();
        return throwError(err);
      }),
      wzCatchObservableError(this.fileName, 'AuthState listener', true)
    ).subscribe();
  }

  signOut(): Observable<void> {
    Globals.signOut$.next();
    return fromPromise(this.firebaseAuth.signOut()).pipe(
      wzCatchObservableError(this.fileName, 'signOut()')
    );
  }

  signInAnonymously(): Observable<firebase.auth.UserCredential> {
    let userCredential: firebase.auth.UserCredential;
    Globals.startLoading();
    return fromPromise(this.angularFireAuth.signInAnonymously()).pipe(
      mergeMap((user: firebase.auth.UserCredential) => {
        userCredential = user;
        return this.updateUserLoginData(user.user);
      }),
      map(() => {
        Globals.stopLoading();
        return userCredential;
      }),
      wzCatchObservableError(this.fileName, 'singInAnonymously()')
    );
  }

  googleLogin(isRegistration?: boolean): Observable<void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider).pipe(
      wzCatchObservableError(this.fileName, 'googleLogin()')
    );
  }

  facebookLogin(isRegistration?: boolean): Observable<void> {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider).pipe(
      wzCatchObservableError(this.fileName, 'facebookLogin()')
    );
  }

  createAccountWithEmailAndPassword(email: string, password: string, username: string, phone?: string): Observable<void> {
    let user: firebase.User;
    return observableOf(undefined).pipe(
      mergeMap(() => {
        let nextStep = () => fromPromise(this.firebaseAuth.createUserWithEmailAndPassword(email, password)).pipe(
          map((cred: firebase.auth.UserCredential) => cred.user)
        );
        if (!!Globals.user && Globals.user.isLoggedInAnonymously()) {
          const credential = firebase.auth.EmailAuthProvider.credential(email, password);
          nextStep = () => fromPromise(firebase.auth().currentUser.linkWithCredential(credential)) as any;
        }
        return nextStep();
      }),
      mergeMap((credential: firebase.User) => {
        user = credential;
        return this.updateUserLoginData(credential, username, phone);
      }),
      mergeMap(() => fromPromise(user.sendEmailVerification())),
      wzCatchObservableError(this.fileName, 'createAccountWithEmailAndPassword()', true)
    );
  }

  passwordLogin(email: string, password: string, throwOnErr?: boolean): Observable<firebase.auth.UserCredential> {
    return fromPromise(this.firebaseAuth.signInWithEmailAndPassword(email, password)).pipe(
      wzCatchObservableError(this.fileName, 'passwordLogin()', !!throwOnErr)
    );
  }

  sendPasswordResetEmail(emailAddress: string): Observable<void> {
    const self = this;
    return fromPromise(self.firebaseAuth.sendPasswordResetEmail(emailAddress)).pipe(
      wzCatchObservableError(this.fileName, 'sendPasswordResetEmail()', true)
    );
  }

  verifyPasswordResetCode(passwordResetCode: string) {
    const self = this;
    return fromPromise(self.firebaseAuth.verifyPasswordResetCode(passwordResetCode)).pipe(
      wzCatchObservableError(this.fileName, 'verifyPasswordResetCode()')
    );
  }

  resetPassword(passwordResetCode: string, newPassword: string) {
    const self = this;
    return fromPromise(self.firebaseAuth.confirmPasswordReset(passwordResetCode, newPassword)).pipe(
      wzCatchObservableError(this.fileName, 'resetPassword()')
    );
  }

  checkEmailVerificationCode(emailVerificationCode: string): Observable<{ data: { email: string, fromEmail: any }, operation: 'VERIFY_EMAIL' }> {
    const self = this;
    return <any>fromPromise(<any>self.firebaseAuth.checkActionCode(emailVerificationCode)).pipe(
      wzCatchObservableError(this.fileName, 'checkEmailVerificationCode()')
    );
  }

  applyEmailVerificationCode(emailVerificationCode: string) {
    const self = this;
    return fromPromise(self.firebaseAuth.applyActionCode(emailVerificationCode)).pipe(
      wzCatchObservableError(this.fileName, 'applyEmailVerificationCode()')
    );
  }

  private updateUserLoginData(user: firebase.User, username?: string, phone?: string) {
    const newUsername = !!username ? username : !!user.email ?
      user.email.split('@')[0].replace(/[~` .!#@$%\^&*+=\-\[\]\\';,/{}()|\\":<>\?]/g, '') :
      user.uid;
    let updateProfile: any = (latestUsername: string) => fromPromise(user.updateProfile({ displayName: latestUsername, photoURL: null }));
    if (!username) {
      updateProfile = () => observableOf(undefined);
    }
    let isNewUser = false;
    const setOrUpdateUserData = (method: 'set' | 'update', latestUsername: string) => {
      const userData: any = {
        id: user.uid,
        email: user.email,
        username: latestUsername,
        photoURL: user.photoURL,
        lastLoginDate: new Date().getTime(),
      };
      if (phone) {
        userData.phoneNumber = phone;
        userData.optedInForCommentUpdates = true;
        userData.optedInForOrderUpdates = true;
        userData.optedInForPriceUpdates = true;
      }
      if (user.phoneNumber) {
        userData.phoneNumber = user.phoneNumber;
      }
      if (method === 'set') {
        userData.signUpDate = new Date().getTime();
        userData.role = 'consumer';
      }
      return fromPromise(this.firestore.doc(`users/${user.uid}`)[method](userData));
    };

    const setUserData = (latestUsername: string) => fromPromise(this.firestore.doc(`users/${user.uid}`).get()).pipe(
      map((user1: any) => !!user1.data()),
      mergeMap((doesExist: boolean) => {
        isNewUser = doesExist === false;
        return setOrUpdateUserData(doesExist ? 'update' : 'set', latestUsername);
      })
    );

    const validateUsername = () => {
      let obs = () => of(newUsername);
      // If oauth login, must validate username to ensure it's unique.
      if (!username && !!user.email) {
        obs = () => {
          let usernameAttempt = newUsername;
          let attemptCounter = 0;
          const validate = () => <any>this.http.get(`${Globals.environment.apiUrl}users/validate-username/${usernameAttempt}?email=${user.email}`).pipe(
            mergeMap((r: { isValid: boolean; }) => {
              let nextTry = () => of(usernameAttempt);
              if (!r.isValid) {
                attemptCounter++;
                usernameAttempt = newUsername + attemptCounter;
                nextTry = () => validate();
              }
              return nextTry();
            })
          );
          return validate();
        };
      }
      return obs();
    };

    return validateUsername().pipe(
      mergeMap((latestUsername: string) => forkJoin([updateProfile(), setUserData(latestUsername)]).pipe(map(() => latestUsername))),
      mergeMap((latestUsername: string) => {
        username = latestUsername;
        return this.http.get(`${Globals.environment.apiUrl}/users/reserve-username/${latestUsername}/${user.email}`);
      }),
      mergeMap(() => {
        if (isNewUser && user.email !== null) {
          return this.http.post(`${Globals.environment.apiUrl}users/send-buyer-welcome-email`, { userName: username, email: user.email });
        }
        return of(true);
      }),
      wzCatchObservableError(this.fileName, 'updateUserLoginData()')
    );
  }

  private oAuthLogin(provider: any): Observable<void> {
    const self = this;
    return fromPromise(self.firebaseAuth.signInWithPopup(provider)).pipe(
      catchError((e) => {
        if (!!e && e.code === 'auth/account-exists-with-different-credential') {
          this.alertSrv.alert(
            'Account already exists',
            `There's already a Wedzee account associated with that ${provider.providerId} account's email address. Please try logging in with another provider, or with email and password.`
          );
        }
        return throwError(e);
      }),
      map((newCredential: firebase.auth.UserCredential) => {
        this.updateUserLoginData(newCredential.user).pipe(
          take(1),
          wzCatchObservableError(this.fileName, 'oAuthLogin() --> update user data')
        ).subscribe();
        return;
      }),
      wzCatchObservableError(this.fileName, 'oAuthLogin()')
    );
  }
}
