import { HttpClient } from '@angular/common/http';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, from as fromPromise, merge, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import {
    IAddress,
    IItemSold,
    IListing,
    IListingForm,
    ISellerBalanceSummary,
    ISellerNotification,
    ISellerSalesSummary,
    ISellerStripeData,
    ISellerUser,
    IWedzeeOrderWithListings,
    TWedzeeTrackingStatus
} from 'wz-types';

import { AuthService } from '../services/auth/auth.service';
import { wzCatchObservableError } from '../services/logging/logging.service';
import { ListingsStore } from '../stores/listings.store';
import { FirestoreRefs } from './firestore-refs.class';
import { Globals } from './global.class';

const LISTING_BATCH = 12;

export class SellerAccount implements ISellerUser {
    fileName = 'seller-account.ts';
    hasSavedListing$ = new BehaviorSubject(false);
    id: string;
    displayName: string;
    photoURL: string;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    storeName: string;
    aboutMyStoreText: string;
    shipFromAddressId: string;
    hasAcknowledgedSellerAgreement: boolean;
    stripeMerchantId: string; // This is a WIP. Not sure if we'll need more data.
    hasCompletedSellerEnrollment: boolean;
    myItems$: Observable<IListing[]>;
    myArchiveItems$: Observable<IListing[]>;
    stripe?: ISellerStripeData;
    defaultShippingAddress$: Observable<IAddress>;
    salesSummary?: ISellerSalesSummary;
    balanceSummary?: ISellerBalanceSummary;
    documentRef: DocumentReference;
    currentListingBatch = LISTING_BATCH;

    constructor(
        private http: HttpClient,
        private router: Router,
        private firestore: AngularFirestore,
        private authSrv: AuthService,
        private listingsStore: ListingsStore,
        private storage: AngularFireStorage,
        private uid: string,
        private firestoreDoc: ISellerUser
    ) {
        this.refreshSellerAccount(this.firestoreDoc);
    }

    /**
     * Converts Wedzee status code to ui friendly status code.
     */
    public static wedzeeShippingStatusToUiStatus(status: TWedzeeTrackingStatus): string {
        let result = '';
        switch (status) {
            case 'delivered':
                result = 'Delivered';
                break;
            case 'failed':
                result = 'failed';
                break;
            case 'in_transit':
                result = 'In transit';
                break;
            case 'processing':
                result = 'Processing';
                break;
        }
        return result;
    }

    refreshSellerAccount(sellerDoc: ISellerUser) {
        if (!!sellerDoc) {
            Object.keys(sellerDoc).forEach((key: string) => this[key] = sellerDoc[key]);
            this.documentRef = this.firestore.doc(`users/${sellerDoc.id}`).ref;
        } else {
            this.documentRef = undefined;
            Object.keys(this).forEach((key: string) => {
                const val = this[key];
                if ((typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') && val !== this.uid) {
                    this[key] = undefined;
                }
            });
        }

        if (!!sellerDoc && sellerDoc.shipFromAddressId) {
            this.defaultShippingAddress$ = this.firestore.doc(`addresses/${sellerDoc.shipFromAddressId}`).get().pipe(
                map(snap => <IAddress>snap.data())
            );
            this.instantiateMyItems();
        }

        if (!!sellerDoc && !!sellerDoc.stripe) {
            Globals.sellerStripeAccountInstantiated$.next();
        }
        console.log('Latest seller account doc', this);
    }

    showPostItemCornerBtn() {
        const routeObs = merge(this.router.events, of(undefined));
        return combineLatest([this.myItems$, routeObs, this.hasSavedListing$]).pipe( // Kinda hacky...
            map(([items, routeEvent, hasSaved]) => {
                const isOnMyItems = location.href.indexOf('/seller/my-items') > -1;
                const isOnHome = location.href.indexOf('/seller/home') > -1;
                return hasSaved ||
                    ((isOnHome || isOnMyItems) && items.length > 0);
            })
        );
    }

    payOut(): Observable<number> {
        return this.http.get(`${Globals.environment.apiUrl}/seller/payout/${this.id}`).pipe(
            map((r: { success: true; dollarAmount: number; }) => {
                return r.dollarAmount;
            }),
            wzCatchObservableError(this.fileName, 'payOut()', true)
        );
    }


    getSalesSummary(): Observable<ISellerSalesSummary> {
        return this.http.get(`${Globals.environment.apiUrl}seller/get-sales-summary/${this.id}`).pipe(
            wzCatchObservableError(this.fileName, 'getSalesSummary()')
        );
    }

    getItemsSold(): Observable<IItemSold[]> {
        return this.http.get(`${Globals.environment.apiUrl}seller/items-sold/${this.id}`).pipe(
            wzCatchObservableError(this.fileName, 'getItemsSold()')
        );
    }

    getOrdersWithListings(delivered?: boolean, recent?: boolean): Observable<IWedzeeOrderWithListings[]> {
        return this.http.get(
            `${Globals.environment.apiUrl}orders/by-seller/${this.id}?includeListings=${true}&delivered=${!!delivered}&recent=${!!recent}`
        ).pipe(
            wzCatchObservableError(this.fileName, 'getOrdersWithListings()')
        );
    }

    getNotifications(): Observable<ISellerNotification[]> {
        return this.http.get(`${Globals.environment.apiUrl}seller/notifications/${this.id}`).pipe(
            wzCatchObservableError(this.fileName, 'getNotifications()')
        );
    }

    dismissNotification(notificationId: string): Observable<void> {
        return fromPromise(FirestoreRefs.sellerNotifications.doc(notificationId).update({ hasDismissed: true })).pipe(
            map(() => Globals.sellerAccountUpdated$.next()),
            wzCatchObservableError(this.fileName, 'dismissNotification()')
        );
    }

    saveSellerInfo(data: any): Observable<void> {
        let resultObs = () => fromPromise(FirestoreRefs.users.doc(this.uid).update(data));
        if (!!this.documentRef) {
            resultObs = () => fromPromise(this.documentRef.update(data));
        }
        return resultObs() as any;
    }

    registerStripe(authCode: string, stateSellerId: string): Observable<any> {
        if (stateSellerId !== this.id) {
            throw new Error('SELLER ID DOES NOT MATCH STATE');
        }
        return this.http.get(`${Globals.environment.apiUrl}seller/register-stripe/${this.id}/${authCode}`).pipe(
            wzCatchObservableError(this.fileName, 'registerStripe()', true)
        );
    }

    canDeleteListing(listingId: string): Observable<boolean> {
        return this.http.get(`${Globals.environment.apiUrl}listings/can-delete/${listingId}`).pipe(
            map((r: { canDelete: boolean }) => r.canDelete),
            wzCatchObservableError(this.fileName, 'canDeleteListing()', true)
        );
    }

    saveUnfinishedListingForm(formData: IListingForm): void {
        formData = Globals.removeFalsy(formData);
        if (!!formData && Object.keys(formData).length > 0) {
            this.saveListing(formData, undefined, true).pipe(take(1)).subscribe();
        }
    }

    getListingForm(existingListingId?: string): Observable<IListingForm> {
        let uri = `${Globals.environment.apiUrl}listings/get-unfinished-form/${this.id}`;
        if (!!existingListingId) {
            uri = `${Globals.environment.apiUrl}listings/get-form/${existingListingId}`;
        }
        return this.http.get(uri).pipe(
            wzCatchObservableError(this.fileName, 'canDeleteListing()', true)
        );
    }

    saveListing(listingForm: IListingForm, existingListingId?: string, isUnfinishedForm?: boolean): Observable<IListing> {
        const loadingMsg = 'Saving your listing...';
        if (!isUnfinishedForm) {
            Globals.startLoading(loadingMsg);
        }
        return this.listingsStore.dispatch('saveListing', listingForm, existingListingId, this.id, !!isUnfinishedForm).pipe(
            map((l: IListing) => {
                if (!isUnfinishedForm) {
                    this.instantiateMyItems();
                    Globals.stopLoading(loadingMsg);
                }
                return l;
            }),
            wzCatchObservableError(this.fileName, 'saveListing()', true)
        );
    }

    deleteListing(listingId: string): Observable<any> {
        return this.listingsStore.dispatch('deleteListing', listingId).pipe(
            map(() => this.instantiateMyItems()),
            wzCatchObservableError(this.fileName, 'deleteListing()', true)
        );
    }

    cancelOrderItem(orderId: string, listingId: string, reason: string) {
        return this.http.post(`${Globals.environment.apiUrl}order-items/cancel/${orderId}/${listingId}`, { reason }).pipe(
            wzCatchObservableError(this.fileName, 'cancelOrderItem()', true)
        );
    }

    instantiateMyItems() {
        this.myItems$ = this.listingsStore.dispatch('getListingsBySeller', this.id);
        this.myArchiveItems$ = this.listingsStore.dispatch('getListingsBySeller', this.id, true);
    }

    changeProfileImg(img: File) {
        const ref = this.storage.ref(`profile_img/${this.id}_${new Date().getTime()}_${img.name}`);
        return fromPromise(ref.put(img)).pipe(
            mergeMap(() => {
                return fromPromise(ref.getDownloadURL());
            }),
            mergeMap((photoURL: string) => {
                this.photoURL = photoURL;
                return fromPromise(this.saveSellerInfo({ photoURL }));
            })
        );
    }
}
