import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

import { Globals } from './classes';
import { AlertService } from './services/alert/alert.service';
import { wzLogError } from './services/logging/logging.service';

interface ICachedRequest {
    response: HttpResponse<any>;
    timestamp: number;
}

@Injectable()
export class WzInterceptor implements HttpInterceptor {
    maxAge = 100000;
    cache: { [url: string]: ICachedRequest } = {};
    pendingRequestUrls: string[] = [];

    constructor(
        private alertSrv: AlertService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let getTokenObs = () => of(undefined);
        if (Globals.user && !!Globals.user.id && (Globals.user.isLoggedIn() || Globals.user.isLoggedInAnonymously())) {
            getTokenObs = () => from(Globals.user.firebaseUser.getIdToken());
        }
        const hasCustomLoader = !!request.headers.get('customLoader');
        if (!hasCustomLoader) {
            Globals.startLoading('', true);
            this.pendingRequestUrls.push(request.url);
        }
        let resultObservable: any = getTokenObs().pipe(
            mergeMap((token?: string) => {
                if (token) {
                    request = request.clone({
                        setHeaders: {
                            'x-access-token': token,
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, x-access-token, Content-Type, Accept'
                        }
                    });
                }
                return next.handle(request).pipe(
                    tap((event: any) => {
                        if (event instanceof HttpResponse) {
                            this.saveToCache(request, event);
                        }
                    })
                );
            })
        );
        const cachedResponse = this.getFromCache(request);
        if (!!cachedResponse) {
            resultObservable = of(cachedResponse);
        }
        return resultObservable.pipe(
            tap((event: HttpResponse<any>) => {
                this.pendingRequestUrls = this.pendingRequestUrls.filter(s => s !== event.url);
                if (this.pendingRequestUrls.length === 0 && Globals.loader.isLoading && !hasCustomLoader) {
                    Globals.stopLoading();
                }
            }),
            catchError((error: any) => {
                this.handleRequestError(error);
                return throwError(error);
            })
        );
    }

    saveToCache(req: HttpRequest<any>, response: HttpResponse<any>): void {
        if (req.method === 'GET' && req.headers.get('cacheInMemory') === '1') {
            this.cache[req.url] = { response, timestamp: new Date().getTime() };
        }
    }

    getFromCache(req: HttpRequest<any>): any {
        let cachedResponse;
        const cachedRecord = this.cache[req.url];
        if (req.method === 'GET' && !!cachedRecord && cachedRecord.timestamp - new Date().getTime() < this.maxAge) {
            cachedResponse = cachedRecord.response;
        }
        return cachedResponse;
    }

    handleRequestError(error: any) {
        this.pendingRequestUrls = [];
        Globals.stopLoading();
        wzLogError('http-interceptor.interceptor.ts', error.url, error);
    }
}
