import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IHeadlineMessage } from 'wz-types';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-sell-on-wedzee-form-item',
  templateUrl: './sell-on-wedzee-form-item.component.html',
  styleUrls: ['./sell-on-wedzee-form-item.component.scss']
})
export class SellOnWedzeeFormItemComponent implements OnInit {
  @Input() value: IHeadlineMessage;
  @Input() isFaq = false;
  @Output() updated: EventEmitter<IHeadlineMessage> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Output() moveUp: EventEmitter<void> = new EventEmitter();
  @Output() moveDown: EventEmitter<void> = new EventEmitter();
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.instantiateForm();
    if (!!this.value) {
      const set = (ctrlName: string) => this.getCtrl(ctrlName).setValue(this.value[ctrlName]);
      set('headline');
      set('message');
    }
  }

  instantiateForm() {
    this.form = this.formBuilder.group({
      headline: [undefined, [Validators.required]],
      message: [undefined, [Validators.required]]
    });
  }

  getCtrl(ctrlName: string): AbstractControl {
    return this.form.get(ctrlName);
  }

  save() {
    this.updated.emit(this.form.getRawValue());
    this.instantiateForm();
  }
}
