<div class="sticky-container">
  <div class="scrollable-part">
    <mat-accordion style="padding-right:15px!important">
      <mat-expansion-panel  *ngFor="let l of menuNavLinks" class="nav-item-border">
        <mat-expansion-panel-header style="height: 50px;">
          <mat-panel-title>
            {{l?.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="margin-top: -12px;">
          <div [routerLink]="[l?.doc ? 'category' : 'color', subcategory.id]" (click)="close();" style="margin-left:20px;margin-top: 8px;"  *ngFor="let subcategory of getSubcategories(l)">
            <span style="text-decoration: underline; cursor: pointer;">{{subcategory?.name}}</span>
            <div [routerLink]="['category', subSubcategory.id]" (click)="$event.stopPropagation(); close();" style="margin-left:20px; margin-top:4px;" class="subsubcategory" *ngFor="let subSubcategory of getSubcategories(subcategory.id)">
              > {{subSubcategory?.name}}
            </div>
          </div>
          <div style="margin-top:15px; float:right; cursor:pointer;" [routerLink]="l?.doc ? ['category', l.doc?.id] : ['wedding-colors']" (click)="close();">Shop All ></div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    
    <div class="full-width social-logos">
        <a href="https://www.facebook.com/Wedzee-136828397271779/" target="_blank">
          <img [src]="socialLogoSrcs.facebook" alt="">
        </a>
        <a href="https://www.instagram.com/shopwedzee/" target="_blank">
          <img [src]="socialLogoSrcs.instagram" alt="">
        </a>
        <a href="https://twitter.com/shopwedzee" target="_blank">
          <img [src]="socialLogoSrcs.twitter" alt="">
        </a>
        <a href="https://www.pinterest.com/161jc1eqpd5andg0u8g5c8d3fyl6l1/" target="_blank">
            <img [src]="socialLogoSrcs.pinterest" alt="">
        </a>
    </div>
  </div>
  <div class="sticky-part">
    <div class="bottom-sheet-cancel">
      <button class="wdz-btn mat-flat-button" mat-flat-button (click)="close();">Cancel</button>
    </div> 
  </div>
</div>



