import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { from, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { ICroppedImage } from 'wz-types/image-cropper';

import { IAspectRatio } from '../../components/add-image/add-image.component';
import { ImgCropperDialogComponent } from '../../dialogs';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzUploadImg]'
})
export class UploadImgDirective implements OnInit {
  id: string;
  @Input() uploadBeforeEmit?: boolean;
  @Input() multiple?: boolean;
  @Input() aspectRatio:  IAspectRatio = { width: 1, height: 1 };
  @Input() quality = 65;
  @Output() imgAdded: EventEmitter<File | string> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private storage: AngularFireStorage
  ) { }

  ngOnInit() {
    this.id = `wz_img_uploader_${new Date().getTime()}`;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = !!this.multiple;
    input.id = this.id;
    input.setAttribute('style', 'visibility: hidden; position: fixed; bottom: -100px;');
    input.onchange = (e: any) => {
      if (this.multiple) {
        this.imgAdded.emit(e.target.files);
      } else {
        const dialogRef = this.dialog.open(ImgCropperDialogComponent, {
          hasBackdrop: true,
          width: '500px',
          maxWidth: '90%',
          data: { fileChangeEvent: e, aspectRatio: this.aspectRatio, isBase64: false, quality: this.quality }
        });

        dialogRef.afterClosed().pipe(
          mergeMap((cropperResult: ICroppedImage) => {
            let nextStep: any = () => of(cropperResult);
            if (this.uploadBeforeEmit) {
              const ref = this.storage.ref(`${this.id}_${cropperResult.file.name}`);
              nextStep = () => from(ref.put(cropperResult.file)).pipe(
                mergeMap(() => from(ref.getDownloadURL()))
              );
            }
            return nextStep();
          }),
          map((result: any) => {
            this.imgAdded.emit(result);
          }),
          take(1)
        ).subscribe();
      }
    };
    document.body.appendChild(input);
  }

  @HostListener('click', ['$event'])
  onclick(e: MouseEvent) {
    document.getElementById(this.id).click();
  }

}
