<ng-template #guaranteeAnchor >
  <a
    routerLinkAbsolute="faq#what-is-the-wedzee-guarantee"
  >
  <div ngStyle.xs="height: 100%;" class="icon-container primary-color">
    <mat-icon>verified_user</mat-icon>
  </div>
  <div 
    class="guarantee-container"
  >
    <b>
      Wedzee Money Back Guarantee.
    </b>
    <span
      ngStyle.lt-sm="display: none;" 
    >
      {{explanation}}
    </span>
  </div>
  </a>
</ng-template>

<ng-container [ngTemplateOutlet]="guaranteeAnchor"></ng-container>
