import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { IWeddingColor } from 'wz-types/home-page.d';
import { Globals } from '~shared/classes';

@Component({
  selector: 'wz-color-carousel',
  templateUrl: './color-carousel.component.html',
  styleUrls: ['./color-carousel.component.scss']
})
export class ColorCarouselComponent implements OnInit {
  @Input() colors: IWeddingColor[] = [];
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  loadSlider = false;
  // public config: SwiperConfigInterface = {
  //   observer: true,
  //   slidesPerView: 6,
  //   spaceBetween: 40,
  //   keyboard: true,
  //   navigation: true,
  //   pagination: false,
  //   grabCursor: true,
  //   loop: false,
  //   preloadImages: false,
  //   lazy: true,
  //   breakpoints: {
  //     480: {
  //       slidesPerView: 1
  //     },
  //     740: {
  //       slidesPerView: 2,
  //     },
  //     960: {
  //       slidesPerView: 3,
  //     },
  //     1280: {
  //       slidesPerView: 4,
  //     },
  //     1500: {
  //       slidesPerView: 5,
  //     }
  //   }
  // };

  config: any;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.loadSlider = false;
    const config = {...Globals.defaultSwiperConfig};
    config.breakpoints = {
      280 : { slidesPerView: 1.8},
      320 : { slidesPerView: 2.1},
      350 : { slidesPerView: 2.1},
      411 : { slidesPerView: 2.1},
      540 : { slidesPerView: 3},
      768 : { slidesPerView: 3.5},
      1024: { slidesPerView: 4.9}, 
      1366 : { slidesPerView: 6.3}
    };
    this.config = config;
    this.loadSlider = true;
  }

  goToColorProducts(id: string) {
    this.router.navigateByUrl(`color/${id}`);
  }

}
