import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, merge, of } from 'rxjs';
import { concatMap, delay, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { IBannerNotification, IUserBannerNotification } from 'wz-types';
import { Globals } from '~shared/classes';
import { BannerNotificationsStore } from '~shared/stores';
import { userRoles } from '../../common/constants';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-banner-notification',
  templateUrl: './banner-notification.component.html',
  styleUrls: ['./banner-notification.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', zIndex: '1' }),
        animate('500ms ease-in', style({ transform: 'translateY(0%)', zIndex: '1' }))
      ])
    ])
  ]
})
export class BannerNotificationComponent implements OnInit {
  userBannerNotifications: IUserBannerNotification[];
  bannerNotifications: IBannerNotification[];

  @Input() message: string;
  constructor(private bannerNotificationStore: BannerNotificationsStore,
    private router: Router) { }

  ngOnInit() {
    Globals.userInstantiated$.pipe(
      mergeMap(() => this.bannerNotificationStore.dispatch('getBannerNotifications')),
      mergeMap((bannerNotifications: IBannerNotification[]) => {
        Globals.bannerNotifications = bannerNotifications;
        Globals.bannerNotificationsInstantiated$.next();
        return forkJoin([of(bannerNotifications), this.bannerNotificationStore.dispatch('getUserBannerNotifications', Globals.user.id)]);
      }),
      concatMap((results) => {
        const bannerNotifications = results[0];
        this.userBannerNotifications = <IUserBannerNotification[]>results[1];
        if (!!Globals.user && !!Globals.user.SellerAccount && !!Globals.user.SellerAccount.hasAcknowledgedSellerAgreement) {  // checks if user is seller or not.
          this.bannerNotifications = bannerNotifications.filter(f => f.displayToGroups.includes(userRoles.SELLER));
        } else if (!!Globals.user && !!Globals.user.email && !Globals.user.SellerAccount?.hasAcknowledgedSellerAgreement) {
          this.bannerNotifications = bannerNotifications.filter(f => f.displayToGroups.includes(userRoles.BUYER));
        } else {
          this.bannerNotifications = [];
        }
        const newUserBannerNotifications: IUserBannerNotification[] = [];
        if (this.bannerNotifications.length > 0) {
          this.bannerNotifications = this.bannerNotifications.map((bannerNotification) => {
            const currentUserBannerNotification = this.userBannerNotifications.find(f => f.bannerNotificationId === bannerNotification.id);
            if (!!currentUserBannerNotification) {
              bannerNotification.isActive = currentUserBannerNotification.isActive && bannerNotification.isActive;
            } else {
              newUserBannerNotifications.push(this.getUserBannerNotification('0', bannerNotification.id, bannerNotification.isActive, Globals.user.id));
            }
            return bannerNotification;
          });
        }
        return forkJoin(this.getUserBannerNotificationsToAddCalls(newUserBannerNotifications));
      }),
      take(1),
    ).subscribe();
  }

  handleButtonClick(index: number) {
    this.bannerNotifications[index].isActive = false;
    if (this.bannerNotifications[index].buttonNavigationUri) {
      this.router.navigateByUrl(this.bannerNotifications[index].buttonNavigationUri);
    } else {
      const userBannerNotification: IUserBannerNotification = this.getUserBannerNotification(
        this.userBannerNotifications.find(f => f.bannerNotificationId === this.bannerNotifications[index].id).id ?? '0',
        this.bannerNotifications[index].id,
        false,
        Globals.user.id);
      this.bannerNotificationStore.dispatch('addUpdateUserBannerNotification', userBannerNotification).subscribe();
    }
  }

  handleCloseClick(index: number) {
    this.bannerNotifications[index].isActive = false;
  }

  private getUserBannerNotification(userBannerNotificationId: string, bannerNotificationId: string, isActive: boolean, userId: string): IUserBannerNotification {
    const userBannerNotification: IUserBannerNotification = {
      id: userBannerNotificationId,
      bannerNotificationId: bannerNotificationId,
      isActive: isActive,
      userId: userId
    };
    return userBannerNotification;
  }

  private getUserBannerNotificationsToAddCalls(newUserBannerNotifications: IUserBannerNotification[]) {
    const addCalls = [];
    newUserBannerNotifications.forEach(item => {
      addCalls.push(this.bannerNotificationStore.dispatch('addUpdateUserBannerNotification', item));
    });
    return addCalls;
  }
}
