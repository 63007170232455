import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Globals } from '../../classes/global.class';
import environment from '../../environments/public';
import { addAutomationButton, testToken } from './stripe-payment-test-automation';

export declare const Stripe: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit, OnChanges {
  @Output() stripeToken: EventEmitter<string> = new EventEmitter();
  stripe: any;
  elements: any;
  placeholderForm: FormGroup;
  @Input() stripeBackendError: any;
  errorText: string;

  constructor(
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private http: HttpClient
  ) { }

  ngOnChanges() {
    if (this.stripeBackendError) {
      this.errorText = this.stripeBackendError.message;
    }
  }

  ngOnInit() {
    this.placeholderForm = this.formBuilder.group({});
    if (!(typeof Stripe === 'function')) {
      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://js.stripe.com/v3/';
      scriptTag.onload = () => {
        this.instantiateCardForm();
      };
      document.head.appendChild(scriptTag);
    } else {
      this.instantiateCardForm();
    }
  }

  testAutomation() {
    if (environment.development) {
      const buttonId = 'stripe-test-token-generator';
      // document.getElementById('stripe-test-token-generator').click()
      addAutomationButton(buttonId);
      document.getElementById(buttonId).onclick = async () => {
        this.stripeToken.emit(testToken as any);
      };
    }
  }

  instantiateCardForm() {
    const self = this;
    this.stripe = Stripe(Globals.environment.stripe.apiKey);
    this.testAutomation();
    this.elements = this.stripe.elements();
    const options = {
      style: {
        base: {
          // Add your base input styles here. For example:
          fontSize: '18px',
          color: 'black',
          lineHeight: '24px',
          border: '.5px solid lightgrey'
        }
      }
    };

    // Create an instance of the card Element.
    // Add an instance of the card Element into the `stripe-card-element` <div>.
    const cardNumber = this.elements.create('cardNumber', options);
    cardNumber.mount('#stripe-card-number');

    const cardExpiry = this.elements.create('cardExpiry', options);
    cardExpiry.mount('#stripe-card-expiry');

    const cardCvc = this.elements.create('cardCvc', options);
    cardCvc.mount('#stripe-card-cvc');

    cardNumber.addEventListener('change', (data: any) => {
      if (data.error) {
        this.errorText = data.error.message;
      } else {
        this.errorText = '';
      }
    });

    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async (event) => {
      this.errorText = '';
      event.preventDefault();
      const { token, error } = await this.stripe.createToken(cardNumber);
      if (error) {
        // Inform the customer that there was an error.
        this.errorText = error.message;
      } else {
        // Send the token to your server.
        self.zone.run(() => {
          self.stripeToken.emit(token);
        });
      }
    });
  }

}
