import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, DocumentSnapshot } from '@angular/fire/firestore';
import { from as fromPromise, Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IAboutPageSection, IHeadlineMessage, ISellOnWedzeeData } from 'wz-types/page-data';
import { IPolicy, IPolicyName, IWedzeeSiteSettings } from 'wz-types/pages-policies';
import { FirestoreRefs } from '~shared/classes/firestore-refs.class';
import { Globals } from '~shared/classes/global.class';
import { wzCatchObservableError } from '~shared/services/logging/logging.service';


@Injectable({
  providedIn: 'root'
})
export class PagesPoliciesService {
  private fileName = 'pages-policies-service.ts';
  docIdsByPage = {
    termsOfService: 'termsOfService',
    privacyPolicy: 'privacyPolicy',
    sellerAgreement: 'sellerAgreement',
    aboutWedzee: 'aboutWedzee',
    siteSettings: 'siteSettings',
    aboutPage: 'aboutPage',
    sellOnWedzee: 'sellOnWedzee',
    faq: 'faq'
  };

  docRefs: { [docId: string]: AngularFirestoreDocument } = {};

  constructor(
    private http: HttpClient
  ) {
    Object.keys(this.docIdsByPage).forEach(
      (id: string) => this.docRefs[id] = FirestoreRefs.pagesAndPolicies.doc(id)
    );
  }

  savePolicy(policyName: IPolicyName, newHtml: string): Observable<void> {
    return fromPromise(
      this.docRefs[policyName].set({
        id: this.docIdsByPage[policyName],
        html: newHtml,
        lastUpdatedBy: { id: Globals.user.id, email: Globals.user.email },
        lastUpdatedDate: new Date().getTime()
      })
    ).pipe(wzCatchObservableError(this.fileName, 'savePolicy()'));
  }

  getPolicy(policyName: IPolicyName): Observable<IPolicy> {
    return fromPromise(this.docRefs[policyName].get()).pipe(
      map((snapshot: DocumentSnapshot<IPolicy>) => snapshot.data()),
      catchError((notExistError: any) => observableOf(undefined))
    );
  }

  getSiteSettings(): Observable<IWedzeeSiteSettings> {
    return fromPromise(this.docRefs.siteSettings.get()).pipe(
      map((snapshot: DocumentSnapshot<IWedzeeSiteSettings>) => snapshot.data()),
      catchError((notExistError: any) => observableOf(undefined))
    );
  }

  getBannerMessage(): Observable<string> {
    return this.getSiteSettings().pipe(
      map((doc: IWedzeeSiteSettings) => (doc || <any>{}).bannerMessage),
      wzCatchObservableError(this.fileName, 'getBannerMessage()')
    );
  }

  saveBannerMessage(message: string): Observable<void> {
    return fromPromise(this.docRefs.siteSettings.get()).pipe(
      mergeMap((snapshot: DocumentSnapshot<IWedzeeSiteSettings>) => {
        const doc = snapshot.data();
        doc.bannerMessage = message;
        return fromPromise(snapshot.ref.update(doc));
      }),
      catchError((notExistError: any) => observableOf(undefined))
    );
  }

  getAboutSections(refreshCache?: boolean): Observable<IAboutPageSection[]> {
    let url = `${Globals.environment.apiUrl}page-data/about`;
    if (refreshCache) {
      url += '?refreshCache=true';
    }
    return this.http.get(url).pipe(
      wzCatchObservableError(this.fileName, 'getAboutSections()')
    );
  }

  saveAboutSections(sections: IAboutPageSection[]): Observable<void> {
    return fromPromise(this.docRefs.aboutPage.set({ sections })).pipe(
      wzCatchObservableError(this.fileName, 'saveAboutSections()')
    );
  }

  getSellOnWedzeeData(refreshCache?: boolean): Observable<ISellOnWedzeeData> {
    let url = `${Globals.environment.apiUrl}page-data/sell-on-wedzee`;
    if (refreshCache) {
      url += '?refreshCache=true';
    }
    return this.http.get(url).pipe(
      wzCatchObservableError(this.fileName, 'getSellOnWedzeeData()')
    );
  }

  saveSellOnWedzeeData(d: ISellOnWedzeeData): Observable<void> {
    return fromPromise(this.docRefs.sellOnWedzee.set(d)).pipe(
      wzCatchObservableError(this.fileName, 'saveSellOnWedzeeData()')
    );
  }

  getFaqData(refreshCache?: boolean): Observable<IHeadlineMessage[]> {
    let url = `${Globals.environment.apiUrl}page-data/faq`;
    if (refreshCache) {
      url += '?refreshCache=true';
    }
    return this.http.get(url).pipe(
      wzCatchObservableError(this.fileName, 'getSellOnWedzeeData()')
    );
  }

  saveFaqData(d: IHeadlineMessage[]): Observable<void> {
    return fromPromise(this.docRefs.faq.set({ data: d })).pipe(
      wzCatchObservableError(this.fileName, 'saveFaqData()')
    );
  }
}
