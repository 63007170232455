<div class="swiper-main" *ngIf="loadSlider">
  <div class="swiper-container product-carousal" [swiper]="config">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let p of products">
        <wz-product [doc]="p" [isSwiper]="true"></wz-product>     
      </div>
    </div>
    <div class="swiper-scrollbar"></div>
    <div class="swiper-pagination"></div>
  </div>
  <div *ngIf="!hideNavigation" class="swiper-btn swiper-button-prev"><img src="/assets/images/LeftArrow_Active.png" /></div>
  <div *ngIf="!hideNavigation" class="swiper-btn swiper-button-next"><img src="/assets/images/RightArrow_Active.png" /></div>
</div>
