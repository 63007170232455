import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from '~shared/classes';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wza-justification-dialog',
  templateUrl: './justification-dialog.component.html',
  styleUrls: ['./justification-dialog.component.scss'],
})
export class JustificationDialogComponent implements OnInit {
  title: string;
  confirmBtnText = 'Yes';
  justificationLabel = 'Please provide a reason.';
  justificationFormGroup: FormGroup;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<JustificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.confirmBtnText = this.data.confirmBtnText || this.confirmBtnText;
    this.justificationLabel =
    this.data.justificationLabel || this.justificationLabel;
    this.justificationFormGroup = this._fb.group({
      justification: [
        '',
        [
          Validators.required,
          Validators.maxLength(1000),
          Globals.whitespacesOnlyValidator.bind(this),
        ],
      ],
    });
  }

  close(justification?: string) {
    return this.dialogRef.close(justification);
  }
}
