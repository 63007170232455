import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, take, mergeMap } from 'rxjs/operators';
import { IListingComment } from 'wz-types';
import { Globals } from '~shared/classes';
import { CommentsByListingStore } from '~shared/stores';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnDestroy {

  @Input() comment: IListingComment;
  @Input() listingId: string;
  @Input() limitCommentId: number;
  @Input() isRoot: boolean;

  isUserAdmin: boolean;
  newCommentForm: FormGroup;
  commentRepliesMap = {};
  destroy$: Subject<void> = new Subject();

  constructor(
    private commentsStore: CommentsByListingStore,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.newCommentForm = this.formBuilder.group({ commentText: [undefined, []] });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isLoggedIn() {
    return !!Globals && Globals.user ? Globals.user.isLoggedIn() && !!Globals.user.email : false;
  }

  updateCommentForm(text: string): FormGroup {
    return this.formBuilder.group({ commentText: [text, []] });
  }

  replyCommentForm(text?: string): FormGroup {
    return this.formBuilder.group({ commentText: [text, [Validators.required]] });
  }

  getUserId(): string {
    this.isUserAdmin = Globals.user ? Globals.user.isAdmin() : false;
    return !!Globals && Globals.user ? Globals.user.id : undefined;
  }

  updateComment(comment: IListingComment, commentText: string) {
    delete (<any>comment).updateCommentForm;
    delete (<any>comment).replies;
    comment.commentText = commentText;
    this.commentsStore.dispatch('updateComment', comment).pipe(
      map(() => {
        this.newCommentForm.reset();
        this.newCommentForm.markAsPristine();
      }),
      take(1)
    ).subscribe();
  }

  deleteComment(comment: IListingComment, parentComment: IListingComment) {
    if (parentComment) {
      if (parentComment.replyCount > 0) {
        parentComment.replyCount--;
      }
      this.updateRepliesMap(parentComment, [comment], true);
      this.updateComment(parentComment, parentComment.commentText);
    }
    this.commentsStore.dispatch('deleteComment', comment).pipe(
      map(() => {
        this.newCommentForm.reset();
        this.newCommentForm.markAsPristine();
      }),
      take(1)
    ).subscribe();
  }

  replyComment(comment: IListingComment, commentText: string) {
    delete (<any>comment).replyCommentForm;
    delete (<any>comment).replies;

    if (comment.replyCount) {
      comment.replyCount++;
    } else {
      comment.replyCount = 1;
    }
    this.commentsStore.dispatch('replyComment', this.listingId, comment, commentText).pipe(
      map((replyComment: IListingComment) => {
        this.updateRepliesMap(comment, [replyComment]);
        this.newCommentForm.reset();
        this.newCommentForm.markAsPristine();
      }),
      take(1)
    ).subscribe();
  }

  onToggleReplies(comment: IListingComment, flag: boolean) {
    this.commentsStore.dispatch('getCommentReplies', comment.id).subscribe((replies: IListingComment[]) => {
      comment.showReplies = flag;
      if (comment.showReplies) {
        this.updateRepliesMap(comment, replies);
      }
    });
  }

  updateRepliesMap(parentComment: IListingComment, repliedComments: IListingComment[], isDeleted?: boolean) {
    if (isDeleted) {
      this.commentRepliesMap[parentComment.id] = this.commentRepliesMap[parentComment.id].filter(f => !repliedComments.some(s => s.id === f.id));
      return;
    }
    if (this.commentRepliesMap[parentComment.id]) {
      this.commentRepliesMap[parentComment.id] = this.commentRepliesMap[parentComment.id].concat(repliedComments).filter((v, i, a) => a.map(c => c.id).indexOf(v.id) === i);
    } else {
      this.commentRepliesMap[parentComment.id] = repliedComments;
    }
  }

  repliesExceedsMaxAllowedCommentCount(comment: IListingComment) {
    const maxAllowedCommentReplyCount = 15;
    if (this.commentRepliesMap[comment.id]) {
      return; // todo: @PK
    }
  }
}
