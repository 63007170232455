import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IAddress } from 'wz-types';
import { Globals } from '~shared/classes';
import { AlertService, wzCatchObservableError } from '~shared/services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-shipping-estimator',
  templateUrl: './shipping-estimator.component.html',
  styleUrls: ['./shipping-estimator.component.scss'],
})
export class ShippingEstimatorComponent implements OnInit {
  @Input() origin: IAddress;

  formGroup: FormGroup;
  estimates: any;
  isErroneous = false;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private alertService: AlertService) { }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      zip: ['', [Validators.required, Validators.pattern('^[0-9]{5}$')]],
      weight: ['', [Validators.required]],
      length: ['', [Validators.required]],
      width: ['', [Validators.required]],
      height: ['', [Validators.required]],
    });
  }

  setDimensions(weight: number, length: number, width: number, height: number) {
    this.formGroup.get('weight').setValue(weight);
    this.formGroup.get('length').setValue(length);
    this.formGroup.get('width').setValue(width);
    this.formGroup.get('height').setValue(height);
  }

  getEstimates(elementId: string) {
    if (!!this.origin) {
      this.isErroneous = false;
      const payload = this.formGroup.getRawValue();
      this.http.post(
        `${Globals.environment.apiUrl}listings/get-estimates`, { data: payload, sellerId: Globals.user.id }
      )
        .pipe(
          tap(() => {
            const element = document.getElementById(elementId);
            element.scrollIntoView();
          }),
          wzCatchObservableError(
            'shipping-estimator.component',
            'getEstimates()',
            true
          ),
          catchError(e => {
            this.isErroneous = true;
            this.estimates = undefined;
            return throwError(e);
          })
        )
        .subscribe(estimates => {
          this.isErroneous = false;
          this.estimates = estimates;
        });
    } else {
      this.alertService.alert('Ship From Address Required', 'Please confirm the address you\'re going to ship from above.');
      return;
    }
  }

  enableGetEstimatesButton(): boolean {
    return this.formGroup.valid &&
      this.formGroup.get('zip').value &&
      this.formGroup.get('weight').value &&
      this.formGroup.get('length').value &&
      this.formGroup.get('width').value &&
      this.formGroup.get('height').value;
  }
}
