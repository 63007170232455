  <div class="swiper-main" *ngIf="loadSlider">
  <div class="swiper-container" [swiper]="config"> 
    <div class="swiper-wrapper">
     <div class="swiper-slide" *ngFor="let c of iterateCategories"> 
       <wz-category [doc]="c" (click)="goToCategoryProducts(c.id)"></wz-category>
     </div>
    </div> 
 
   <div class="swiper-scrollbar"></div>
   <div class="swiper-pagination"></div>
 </div>
</div>

