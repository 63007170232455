import 'firebase/auth';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore, DocumentReference, DocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { combineLatest, forkJoin, from as fromPromise, Observable, of as observableOf, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, take, takeUntil, filter } from 'rxjs/operators';
import {
  IAddress,
  IDispute,
  IListing,
  IListingLike,
  IListingWithComments,
  ISellerUser,
  IShoppingCart,
  IShoppingCartItem,
  IUser,
  IWedzeeOrder
} from 'wz-types';

import { ICroppedImage } from '../../../../../../types/image-cropper';
import { AuthService } from '../services/auth/auth.service';
import { wzCatchObservableError } from '../services/logging/logging.service';
import { LikesByListingStore, ListingsStore } from '../stores';
import { FirestoreRefs } from './firestore-refs.class';
import { Globals } from './global.class';
import { SellerAccount } from './seller-account.class';
import { ShoppingCart } from './shopping-cart.class';

export class User implements IUser {
  public static doesLike: { [listingId: string]: boolean; } = {};
  private readonly fileName = 'user.class.ts';
  firestoreRef: DocumentReference;
  id: string;
  uid: string;
  displayName: string;
  username: string;
  photoURL: string;
  email: string;
  phoneNumber?: any;
  role: string;
  lastLoginDate: string | number;
  signUpDate?: number;
  isInitialized: boolean;
  ShoppingCart: ShoppingCart;
  SellerAccount: SellerAccount;
  addresses$: Observable<IAddress[]>;
  addresses: IAddress[];
  optedInForCommentUpdates: boolean; // TODO: @PK move this to IUserSettings
  optedInForOrderUpdates: boolean; // TODO: @PK move this to IUserSettings
  optedInForPriceUpdates: boolean; // TODO: @PK move this to IUserSettings
  weddingDate: Date;

  constructor(
    private http: HttpClient,
    private router: Router,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private authSrv: AuthService,
    public listingsStore: ListingsStore,
    private likesStore: LikesByListingStore,
    public firebaseUser?: firebase.User,
    firestoreData?: IUser
  ) {
    this.refreshUser(firestoreData, firebaseUser);
  }

  public static getNumberOfUsers(http: HttpClient, role?: string): Observable<any> {
    const roleObj = !!role ? { role } : {};
    return fromPromise(Globals.user.firebaseUser.getIdToken()).pipe(
      mergeMap((token: string) => {
        const headers = new HttpHeaders({ 'x-access-token': token });
        return http.post(`${Globals.environment.apiUrl}/users/get-number`, roleObj, { headers });
      }),
      map((res: { success: true, numOfUsers: number }) => res.numOfUsers),
      catchError(err => {
        Globals.logError(err, 'ERROR in User Class: ', false);
        return observableOf(undefined);
      })
    );
  }

  public trackOrder(orderId: string, subTotal: number, affiliatly_aff_uid: string, affiliatly_id_user: string, affiliatly_id_token: string): void {
    this.listingsStore.dispatch('track-order', orderId, subTotal, affiliatly_aff_uid, affiliatly_id_user, affiliatly_id_token).subscribe();
  }

  public refreshUser(firestoreData?: IUser, firebaseUser?: firebase.User) {
    const self = this;
    this.firebaseUser = firebaseUser;
    if (!!firestoreData) {
      self.firestoreRef = FirestoreRefs.users.doc(firestoreData.id).ref;
      Object.keys(firestoreData).forEach((userKey: string) => self[userKey] = firestoreData[userKey]);
      if (firebaseUser) {
        console.log('Latest user: ', self);
        this.id = firestoreData.id;
        if (!self.ShoppingCart) {
          self.ShoppingCart = new ShoppingCart(self.http, self.firestore, self.authSrv, self.listingsStore, self.router, <any>{ id: this.id }, self.isLoggedInAnonymously());
        }
        this.initAddresses();

        this.likesStore.dispatch('updateUserLikes', firestoreData.id).pipe(
          map((r: IListingLike[]) => {
            User.doesLike = r.reduce((p: any, c: any) => <any>{ ...p, [c.listingId]: true }, {});
          }),
          take(1)
        ).subscribe();

        FirestoreRefs.userShoppingCarts.doc(firestoreData.id).valueChanges().pipe(
          debounceTime(500),
          takeUntil(Globals.signOut$),
          map((cartDoc: IShoppingCart) => {
            self.ShoppingCart.refreshShoppingCart(cartDoc, this.isLoggedInAnonymously());
            Globals.shoppingCartInstantiated$.next();
            Globals.shoppingCartUpdated$.next();
          }),
          takeUntil(Globals.destroy$)
        ).subscribe();

        if (!!firestoreData) {
          if (!self.SellerAccount) {
            self.SellerAccount = new SellerAccount(
              this.http, this.router, this.firestore, this.authSrv,
              this.listingsStore, this.storage, this.id, firestoreData as ISellerUser
            );
            Globals.sellerAccountInstantiated$.next();
          } else {
            self.SellerAccount.refreshSellerAccount(firestoreData as ISellerUser);
            Globals.sellerAccountUpdated$.next();
          }
        }
      }
    } else {
      self.firebaseUser = undefined;
      if (!self.ShoppingCart) {
        self.ShoppingCart = new ShoppingCart(self.http, self.firestore, self.authSrv, self.listingsStore, self.router, undefined, undefined);
      } else {
        self.ShoppingCart.refreshShoppingCart(undefined, false);
      }
      Globals.shoppingCartInstantiated$.next();
    }
    self.isInitialized = !!self.email && !!self.username;
  }

  initAddresses() {
    this.addresses$ = fromPromise(FirestoreRefs.addresses.ref.where('userId', '==', this.id).get()).pipe(
      map((querySnap: QuerySnapshot<IAddress>) => querySnap.docs.map(d => d.data()))
    );
    this.addresses$.subscribe(addresses => { this.addresses = addresses; });
  }

  deleteAddress(a: IAddress) {
    return fromPromise(FirestoreRefs.addresses.doc(a.id).update({ userId: 'anonymous', name: 'anonymous', email: 'anonymous' })).pipe(
      map(() => this.initAddresses())
    );
  }


  hasOrders(): Observable<boolean> {
    let result = () => observableOf(false);
    if (this.isLoggedIn() || this.isLoggedInAnonymously()) {
      result = () => fromPromise(FirestoreRefs.orders.ref.where('buyerUserId', '==', this.id).limit(1).get()).pipe(
        map((snap: QuerySnapshot<IWedzeeOrder>) => snap.docs.length > 0)
      );
    }
    return result();
  }

  getOrders(): Observable<IWedzeeOrder[]> {
    return fromPromise(FirestoreRefs.orders.ref.where('buyerUserId', '==', this.id)
      .orderBy('createdTimestamp', 'desc')
      .get()).pipe(
        map((snap: QuerySnapshot<IWedzeeOrder>) => snap.docs.map(d => d.data()))
      );
  }

  getOrder(orderId: string): Observable<IWedzeeOrder> {
    return fromPromise(FirestoreRefs.orders.doc(orderId).get()).pipe(
      map((snap: DocumentSnapshot<IWedzeeOrder>) => snap.data())
    );
  }

  isLoggedIn() {
    return !!this.firebaseUser && !!this.username;
  }

  isLoggedInAnonymously() {
    return !!this.firebaseUser && !this.username;
  }

  changeRole(role: 'buyer' | 'seller' | 'admin' | 'superadmin'): Observable<void> {
    const self = this;
    return fromPromise(self.firestoreRef.update({ role })) as any;
  }

  changeFirestoreEmail(email: string) {
    return fromPromise(this.firestoreRef.update({ email }));
  }

  changeDisplayName(newName: string): Observable<void> {
    return <any>forkJoin([
      fromPromise(this.firestoreRef.update({ displayName: newName, username: newName })),
      fromPromise(this.firebaseUser.updateProfile({
        displayName: newName,
        photoURL: this.photoURL
      })),
      this.http.get(`${Globals.environment.apiUrl}users/reserve-username/${newName}/${Globals.user.email}`)
    ]);
  }

  changePhoneNumber(phone: string): Observable<void> {
    return fromPromise(this.firestoreRef.update({ phoneNumber: phone }));
  }

  changeWeddingDate(date: number): Observable<void> {
    return fromPromise(this.firestoreRef.update({ weddingDate: date }));
  }

  updateSmsOptInForCommentUpdates(flag: boolean): Observable<void> {
    return fromPromise(this.firestoreRef.update({ optedInForCommentUpdates: flag }));
  }

  updateSmsOptInForOrderUpdates(flag: boolean): Observable<void> {
    return fromPromise(this.firestoreRef.update({ optedInForOrderUpdates: flag }));
  }

  updateSmsOptInForPriceUpdates(flag: boolean): Observable<void> {
    return fromPromise(this.firestoreRef.update({ optedInForPriceUpdates: flag }));
  }

  isAdmin(): boolean {
    return ['admin', 'superadmin'].includes(this.role);
  }

  disableAccount() {
    return fromPromise(this.firestoreRef.update({ isDisabled: true }));
  }


  enableAccount() {
    return fromPromise(this.firestoreRef.update({ isDisabled: false }));
  }

  delete() {
    return this.http.delete(`${Globals.environment.apiUrl}users/${this.id}`);
  }

  isitemInCart(listingId: string) {
    return !!Globals.user &&
      Globals.user.ShoppingCart &&
      Globals.user.ShoppingCart.items.length > 0 &&
      !!Globals.user.ShoppingCart.items.find((i: IShoppingCartItem) => i.listingId === listingId);
  }

  hasLikedOrCommentedItems(): Observable<boolean> {
    let result = () => observableOf(false);
    if (this.isLoggedIn() || this.isLoggedInAnonymously()) {
      const docsExist = (collectionName: string) => fromPromise(FirestoreRefs[collectionName].where('userId', '==', this.id).limit(1).get()).pipe(
        map((querySnap: QuerySnapshot<any>) => {
          return querySnap.docs.length > 0;
        }),
      );
      result = () => forkJoin([docsExist('listingLikes'), docsExist('listingComments')]).pipe(
        map((r: [boolean, boolean]) => r[0] || r[1])
      );
    }
    return result();
  }

  getMyLikedAndCommentedItems(): Observable<{ liked: IListing[], commented: IListingWithComments[] }> {
    return combineLatest([
      this.listingsStore.dispatch('getUserLikedItems'),
      this.listingsStore.dispatch('getUserCommentedItems')
    ]).pipe(
      map((r: [IListing[], IListing[]]) => <any>{
        liked: r[0].filter(l => !!l),
        commented: r[1].filter((l, i, a) => !!l && a.filter(t => !!t).map(t => t.id).indexOf(l.id) === i)
      })
    );
  }

  public saveAddress(a: IAddress, disableDeleteShippo?: boolean): Observable<IAddress> {
    const existingAddress = this.addresses.filter(
      addr =>
        addr.name === a.name &&
        addr.email === a.email &&
        addr.state === a.state &&
        addr.street1 === a.street1 &&
        (addr.street2 === a.street2 || (addr.street2 === null && a.street2 === undefined) || (addr.street2 === undefined && a.street2 === null)) &&
        addr.street_no === a.street_no &&
        addr.zip === a.zip);
    if (existingAddress.length > 0) {
      return of(existingAddress[0]);
    }

    a.userId = !!Globals.user && !Globals.user.email ? 'anonymous' : Globals.user.id;
    delete a.id;
    if (!disableDeleteShippo) {
      delete a.shippoAddressId;
    }
    const address = { ...a, id: this.firestore.createId() };
    return fromPromise(FirestoreRefs.addresses.doc(address.id).set(address)).pipe(
      map(() => {
        this.initAddresses();
        return address;
      })
    );
  }

  getAddress(id: string): Observable<IAddress> {
    return fromPromise(FirestoreRefs.addresses.doc(id).get()).pipe(
      map((snap: any) => snap.data())
    );
  }

  updateProfilePhoto(img: ICroppedImage) {
    const ref = this.storage.ref(`profile_img/${this.id}_${new Date().getTime()}_${this.username}`);
    const putPromise = img.file ? ref.put(img.file) : ref.putString(img.base64.split(',')[1], 'base64', { contentType: 'image/jpeg' });
    return fromPromise(putPromise).pipe(
      mergeMap(() => {
        return fromPromise(ref.getDownloadURL());
      }),
      mergeMap((url: string) => {
        this.photoURL = url;
        return fromPromise(Promise.all([
          this.firebaseUser.updateProfile({ displayName: this.username, photoURL: url }),
          this.firestore.doc(`users/${this.id}`).update({ photoURL: url })
        ]));
      })
    );
  }

  sendDispute(dispute: IDispute) {
    if (dispute.imageUrls && dispute.imageUrls.length === 0) {
      delete dispute.imageUrls;
    }
    return this.http.post(`${Globals.environment.apiUrl}dispute`, dispute).pipe(
      wzCatchObservableError(this.fileName, 'sendDispute()', true)
    );
  }

}
