import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable()
export class FirestoreRefs {
    public static listings: AngularFirestoreCollection;
    public static archiveListings: AngularFirestoreCollection;
    public static archiveShoppingCarts: AngularFirestoreCollection;
    public static userShoppingCarts: AngularFirestoreCollection;
    public static sellerNotifications: AngularFirestoreCollection;
    public static payments: AngularFirestoreCollection;
    public static addresses: AngularFirestoreCollection;
    public static disputes: AngularFirestoreCollection;
    public static archiveDisputes: AngularFirestoreCollection;
    public static categories: AngularFirestoreCollection;
    public static homePage: AngularFirestoreCollection;
    public static listingComments: AngularFirestoreCollection;
    public static listingLikes: AngularFirestoreCollection;
    public static orders: AngularFirestoreCollection;
    public static pagesAndPolicies: AngularFirestoreCollection;
    public static sellers: AngularFirestoreCollection;
    public static users: AngularFirestoreCollection;
    public static weddingColors: AngularFirestoreCollection;
    public static unfinishedListingForms: AngularFirestoreCollection;
    public static exceptions: AngularFirestoreCollection;

    constructor(
        firestore: AngularFirestore
    ) {
        [
            'addresses', 'archiveDisputes', 'archiveListings', 'archiveShoppingCarts',
            'categories', 'disputes', 'homePage', 'listingComments', 'listingLikes', 'listings',
            'orders', 'pagesAndPolicies', 'payments', 'sellerNotifications', 'sellers', 'users', 'userShoppingCarts',
            'weddingColors', 'unfinishedListingForms', 'exceptions'
        ].forEach((k: string) => FirestoreRefs[k] = firestore.collection(k));
    }
}
