<div class="trust-icon-container">
  <div class="trust-icon-row">
    <div class="trust-icon-col" *ngFor="let icon of icons let i = index">
      <div class="trust-icon-image">
        <img [src]="icon.imgSrc" alt="Secure Transactions Icon" />
      </div>
      <div class="trust-icon-content">
        <h3>
          {{icon.title}}
        </h3>
        <p>
          {{icon.text}} 
        </p>
      </div>
    </div>
  </div>
  <!-- To Be Removed -->
  <!-- <div style="text-align: left; margin:15px 0 0 20px;" ngStyle.xs="margin-left:15px;">
    <mat-hint routerLink="sell-on-wedzee" class="see-more see-more-categorycolor"><span class="see-more-span">Learn more  <img style="vertical-align:middle; margin-left: 5px !important;" src="../../assets/img/HyperlinkArrow.png" /> </span> </mat-hint>
  </div> -->
</div>
