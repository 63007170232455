import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wza-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  confirmBtnText = 'Yes';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.confirmBtnText = this.data.confirmBtnText || this.confirmBtnText;
  }

  dismiss(confirmed?: boolean) {
    return this.dialogRef.close(confirmed);
  }

}
