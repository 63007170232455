import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-info-bullets',
  templateUrl: './info-bullets.component.html',
  styleUrls: ['./info-bullets.component.scss']
})
export class InfoBulletsComponent {
}
