<ng-container>
    <div class="text-center cta buy-sell-tab-content" ngStyle.lt-md="height:370px !important" ngStyle.lt-md ="height:850px !important" >
      <div style="font-size: 22px; color:#000000; font-family:century_gothicregular !important; margin-bottom:45px; margin-top:20px;font-weight: bold;" ngStyle.xs="font-size:20px;margin-bottom:25px; margin-top:0px;">Search. Select. Save!</div>
      <div>
        <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column"  style="min-width: 200px !important;">
          <div fxFlex="calc(33%-20px)"  fxFlex.lt-sm="100" class="rsn">
            <img  src="./../../../../assets/img/Buy_Explore.png"/>
    
            <div style="width: 265px; float:left; position: relative; display: block;  height: 12px; margin-top : -100px; margin-bottom:  50px; margin-right:  15px; margin-left:  290px;"  class="dotted-line"
            fxShow.gt-sm="true" fxHide.lt-sm  fxHide.lt-md fxHide.lt-lg>        
            </div>              
            <div class="rsn-title"> Explore </div>
            <div class="mat-subheading-2 rsn-detl" >
              Browse and discover great deals. 
            </div>
          </div>
          <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
            <img  src="./../../../../assets/img/Buy_SecurelyPurchase.png"/>
            <div style="width: 265px; float:left; position: relative; display: block;  height: 12px; margin-top : -100px; margin-bottom:  50px; margin-right:  15px; margin-left:  290px;" class="dotted-line"
            fxShow.gt-sm="true" fxHide.lt-sm fxHide.lt-md fxHide.lt-lg >
            </div>
            <div class="rsn-title"> Securely Purchase</div>
            <div class="mat-subheading-2 rsn-detl">
              We accept all major credit cards and PayPal as payment.
            </div>
          </div>
          <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
            <img  src="./../../../../assets/img/Buy_GuaranteedItems.png"/>
            <div class="rsn-title"> Guaranteed Items </div>
            <div class="mat-subheading-2 rsn-detl">
               Sellers ship via USPS. Receive items as listed or your money back guaranteed.
            </div>
          </div>
        </div>
        <br>
        <button mat-flat-button color="accent" routerLink="/new-arrivals" 
          style="display: inline-block; font-family:century_gothicregular!important; margin:20px auto 40px;" class="bg-offset-color" ngStyle.xs="margin:0px auto 35px">
          Start Buying
        </button>
      </div>
    </div>
    <br>
  </ng-container>
