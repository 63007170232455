import { Injectable } from '@angular/core';
import { stringify } from 'flatted';
import { of, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Globals } from '../../classes/global.class';

declare const gtag: any;

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  trackingId: string;

  constructor() {
    this.trackingId = Globals.environment.googleAnalytics.trackingId;
    const srcTag = document.createElement('script');
    srcTag.async = true;
    srcTag.src = `https://www.googletagmanager.com/gtag/js?id=${this.trackingId}`;
    document.head.appendChild(srcTag);
    const initTag = document.createElement('script');
    initTag.text = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${this.trackingId}');`;
    document.head.appendChild(initTag);
  }

  public static getErrorString(fileName: string, functionName: string, error: any): string {
    return `
      File - ${fileName}
      Function - ${functionName}
      Error - ${typeof error === 'string' ? error : stringify(error) }
    `;
  }

  public static reportError(fileName: string, functionName: string, e: Error) {
    const errString = LoggingService.getErrorString(fileName, functionName, e);
    Globals.logError(e, errString, false);

    // const timestamp = new Date().getTime();
    // const id = `${timestamp}_${Math.floor(Math.random() * 1000000)}`;
    // const exception: IWedzeeException = {
    //   id,
    //   environment: 'client',
    //   fileName, functionName,
    //   error: typeof e === 'string' ? e : stringify(e),
    //   stack: !!e && !!e.stack ? e.stack : '',
    //   href: location.href,
    //   userId: !!Globals.user ? Globals.user.id || 'No user' : 'No user',
    //   timestamp: timestamp,
    //   navigator: {
    //     platform: navigator.platform,
    //     userAgent: navigator.userAgent,
    //     appVersion: navigator.appVersion,
    //     vendor: navigator.vendor
    //   },
    //   window: {
    //     opera: String((window as any).opera)
    //   }
    // };
    // exception.error = exception.error.substr(0, 12000);
    // FirestoreRefs.exceptions.doc(exception.id).set(exception);
  }

  trackPageView(url: string) {
    const firstSegment = url.split('/')[1];
    const pageTitle = !firstSegment ? 'Home' : firstSegment[0].toUpperCase() + firstSegment.substr(1);
    gtag('config', this.trackingId, {
      page_title : pageTitle,
      page_path: '/' + firstSegment,
      page_location: url
    });
  }

  trackTiming(eventName: string, startTimestamp: number, category?: string): void {
    gtag('event', 'timing_complete', {
      name: eventName,
      value: (new Date().getTime() - startTimestamp),
      event_category: category
    });
  }

  trackEvent(eventName: string, value?: string, category?: string, label?: string): void {
    gtag('event', eventName, {
      event_category: category,
      event_label: label,
      value
    });
  }
}

export function wzCatchObservableError(fileName: string, functionName: string, reThrow?: boolean): OperatorFunction<any, any> {
  return catchError((e: any) => {
    LoggingService.reportError(fileName, functionName, e);
    return !reThrow ? of(e) : throwError(e);
  });
}

export function wzLogError(fileName: string, functionName: string, error: any) {
  LoggingService.reportError(fileName, functionName, error);
}
