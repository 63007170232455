import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IListingLike } from 'wz-types/listings';

import { AppStore, FirestoreRefs, Globals, User } from '../classes';
import { wzCatchObservableError } from '../services/logging/logging.service';

type TLikeAction = 'toggleLike' | 'getListingLikes' | 'updateUserLikes';

@Injectable()
export class LikesByListingStore extends AppStore<{ listingId: string; likes: IListingLike[] }, TLikeAction> {
    fileName = 'likes-by-lising.store.ts';

    constructor(
        private auth: AngularFireAuth
    ) {
        super({
            objectName: 'likesByListing',
            objectIdKey: 'listingId',
            objectGetFn: () => of(undefined)
        });

        this.registerAction('toggleLike', {
            type: 'change',
            dispatch: (listingId: string) => this.toggleLike(listingId),
            reduce: (r: any, state) => {
                const listingId = typeof r === 'string' ? r : undefined;
                const listingResult = state[listingId || r.listingId] || { listingId: r.listingId, likes: <any>[] };
                listingResult.likes = !!listingId ? listingResult.likes.filter(l => l.userId !== Globals.user.id) : [...listingResult.likes, r];
                const isLiking = !listingId;
                User.doesLike[listingId || r.listingId] = isLiking;
                return listingResult;
            },
            map: (r: any, state) => (state[typeof r === 'string' ? r : r.listingId] || <any>{}).likes
        });

        this.registerAction('getListingLikes', {
            type: 'get',
            dispatch: (listingId: string) => forkJoin([this.getLikes(listingId), of(listingId)]),
            reduce: (r: [IListingLike[], string], state) => <any>{ listingId: r[1], likes: r[0] },
            map: (r: any, state) => state[r[1]].likes
        });

        this.registerAction('updateUserLikes', {
            type: 'get',
            dispatch: (userId?: string) => from(FirestoreRefs.listingLikes.ref.where('userId', '==', userId || Globals.user.id).get()).pipe(
                map((querySnap: any) => <any>{ listingId: userId || Globals.user.id, likes: querySnap.docs.map(d => d.data())})
            ),
            reduce: (r: any) => r,
            map: (r: any, state) => r.likes
        });
    }


    private toggleLike(listingId: string): Observable<any> {
        const id = this.getListingLikeId(listingId);
        const ref = FirestoreRefs.listingLikes.doc(id);
        return from(ref.delete()).pipe(
          map((deletedSuccess: undefined) => listingId),
          catchError((likeNotExistErr: any) => {
            const doc: IListingLike = {
                id,
                userName: Globals.user.username,
                userPhotoUrl: Globals.user.photoURL || Globals.defaultUserPhotoUrl,
                userId: Globals.user.id,
                timestamp: new Date().getTime(),
                listingId
            };
            return from(ref.set(doc)).pipe(map(() => doc));
          }),
          wzCatchObservableError(this.fileName, 'toggleLike()')
        );
    }

    private getLikes(listingId: string): Observable<IListingLike[]> {
        return from(FirestoreRefs.listingLikes.ref
            .where('listingId', '==', listingId)
            .orderBy('timestamp', 'asc')
            .get()).pipe(
            map((querySnap: any) => querySnap.docs.map(d => d.data())),
            wzCatchObservableError(this.fileName, 'getLikes()')
        );
    }

    private getListingLikeId(listingId: string): string {
        return `${listingId}_${Globals.user.id}`;
    }
}
