<div style="padding: 0 20px;">
  <div *ngIf="isMode.verifyEmail" [ngSwitch]="verificationStatus" class="small-page-container" style="width: 200px;min-width: 200px;">
    <ng-container *ngSwitchCase="'notYet'">
      <h3 class="mat-headline">Verifying your link...</h3>
      <mat-progress-bar mode="buffer"></mat-progress-bar>
    </ng-container>

    <ng-container *ngSwitchCase="'verified'">
      <h3 class="mat-headline">You're all set!</h3>
      <mat-label style="padding-left: 2px;">Your email has been verified.</mat-label>
      <br><br>
      <mat-label *ngIf="!isLoggedIn()">Please sign in to continue</mat-label>
      <br>
      <button style="margin-top: 15px;" *ngIf="!isLoggedIn()" mat-raised-button color="accent" (click)="signIn()">Sign in</button>
    </ng-container>
  </div>

  <div *ngIf="isMode.resetPassword" [ngSwitch]="!!isFormSubmitted" class="small-page-container">
    <h1 class="mat-headline">Password Reset</h1>

    <ng-container *ngSwitchCase="false">
        <mat-label>Enter your new password below.</mat-label>
        <br><br>
        <form *ngIf="!!resetPasswordForm" [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
          <mat-form-field style="min-width: 300px;">
            <input formControlName="email" matInput placeholder="Email Address" type="text">
          </mat-form-field>

          <mat-form-field style="min-width: 300px;">
            <input formControlName="password" matInput placeholder="Password" type="password">
            <mat-error *ngIf="!resetPasswordForm.get('password').valid">{{passwordRequirements}}</mat-error>
          </mat-form-field>

          <mat-form-field style="min-width: 300px;">
              <input formControlName="confirmPassword" matInput placeholder="Confirm Password" type="password">
              <mat-error *ngIf="!resetPasswordForm.get('confirmPassword').valid">This doesn't match the password above.</mat-error>
            </mat-form-field>

          <br>

          <!-- <mat-error *ngIf="!!noEmailError">{{noEmailError}}</mat-error> -->
          <!-- <br *ngIf="!!noEmailError"> -->

          <button mat-raised-button
            color="accent"
            [disabled]="!resetPasswordForm.valid"
            type="submit"
          >
            Submit
          </button>
        </form>
    </ng-container>

    <ng-container *ngSwitchCase="true">
        <h3 class="mat-headline">Your password was reset.</h3>
        <mat-label>Click 'Sign in' to log into your account.</mat-label>
        <br><br>
        <button mat-raised-button color="accent" routerLink="/">Shop Wedzee</button>
    </ng-container>

  </div>

  <div class="small-page-container" *ngIf="verificationStatus === 'invalid' || isCodeExpired">
    <h3 class="mat-headline">That link expired...</h3>
    <mat-label>Sorry! Please send another link to your email.</mat-label>
    <br><br>
    <button mat-raised-button color="accent" routerLink="/forgot-password">Request another link</button>
  </div>
</div>
