import { MainComponent } from './main.component';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminConsumerSharedModule } from '~shared/admin-consumer-shared.module';
import { WzErrorHandler } from '~shared/services/logging/error-handler.class';
import { Router, UrlSerializer, RouterModule } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ProductListFilterComponent } from './components/product-list-filter/product-list-filter.component';
import { CategoriesPageComponent } from './pages/categories-page/categories-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { ProductListPageComponent } from './pages/product-list-page/product-list-page.component';
import { TermsOfServicePageComponent } from './pages/terms-of-service-page/terms-of-service-page.component';
import { FeaturedListingsPageComponent } from './pages/featured-listings-page/featured-listings-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragScrollModule } from 'ngx-drag-scroll';


@NgModule({
  entryComponents: [
    ProductListFilterComponent
  ],
  declarations: [
    MainComponent,
    AppComponent,
    HomePageComponent,
    ProductListPageComponent,
    TermsOfServicePageComponent,
    PrivacyPolicyPageComponent,
    CategoriesPageComponent,    
    FeaturedListingsPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AdminConsumerSharedModule,
    AppRoutingModule,
    ComponentsModule,
    DragDropModule,
    DragScrollModule,
    RouterModule.forRoot([
      {path: '', component: AppComponent}
    ])
  ],
  providers: [
    {
      provide: MAT_BOTTOM_SHEET_DATA,
      useValue: {}
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [MainComponent]
})
export class AppModule { }
