import { Component, OnInit, HostListener } from '@angular/core';
import { Globals } from '~shared/classes';
import { ICategory } from 'wz-types/categories';

@Component({
  selector: 'wz-categories-page',
  templateUrl: './categories-page.component.html',
  styleUrls: ['./categories-page.component.scss']
})
export class CategoriesPageComponent implements OnInit {
  allcategories: any[];
  categories: any[];
  screenWidth: any;
  constructor(
  ) { }

  ngOnInit() {
    this.allcategories = Object.values(Globals.categoriesLookup);
    this.categories = this.allcategories.filter(item => item.parentCategoryId == null);
    this.screenWidth = window.innerWidth;
  }

  @HostListener ('window:resize', ['$event'])
  onResize (event) {
    this.screenWidth = 0;
    setTimeout(() => {
      this.screenWidth = event.target.innerWidth;
    }, 0);
  }

}
