import { Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IShoppingCartItemWithListing } from 'wz-types/shopping-cart';

import { Globals } from '../../../classes';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-shopping-cart-button',
  templateUrl: './shopping-cart-button.component.html',
  styleUrls: ['./shopping-cart-button.component.scss']
})
export class ShoppingCartButtonComponent implements OnInit {
  @ViewChild(MatMenuTrigger) cartTrigger: MatMenuTrigger;
  cartItems: IShoppingCartItemWithListing[] = [];
  numOfItems = 0;
  subTotal: number;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  openTimestamp: number;

  constructor(
    private zone: NgZone,
    private router: Router
    ) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!!this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }

  ngOnInit() {
    merge(Globals.shoppingCartUpdated$, Globals.userInstantiated$)
      .pipe(
        map(() => {
          if (!!Globals.user.ShoppingCart && !!Globals.user.ShoppingCart.items$) {
            Globals.user.ShoppingCart.items$.pipe(
              map((items: IShoppingCartItemWithListing[]) => {
                this.cartItems = items;
                this.numOfItems = items.length;
                this.subTotal = items.reduce((p, c) => {
                  return p + c.qty * (c.listing && c.listing.price ? c.listing.price : 0);
                }, 0);
              }),
              takeUntil(Globals.destroy$)
            ).subscribe();
          } else {
            this.cartItems = [];
            this.numOfItems = 0;
            this.subTotal = 0;
          }
        }),
        takeUntil(Globals.destroy$)
      ).subscribe();
      // this.subTotal = Number(localStorage.getItem('totalPrice'));
  }

  isAdminApp() {
    return Globals.isAdminApp;
  }

  goToCart() {
    const link = !!Globals.user.ShoppingCart.shipToAddressId && !!Globals.user.ShoppingCart.billToAddressId ?
      'review-order' :
      'shopping-cart';
    this.router.navigateByUrl(link);
  }
}
