<h1 class="no-margin no-padding" mat-dialog-title>{{title}}</h1>
<div mat-dialog-content style="overflow:hidden !important;">
  <br/><br/>
  <label for="message">{{message}}</label>
  <br/><br/>
  <mat-form-field class="full-width" [formGroup]="justificationFormGroup">
    <mat-label>{{justificationLabel}}</mat-label>
    <textarea
      formControlName="justification"
      matInput
      placeholder=""
      type="text"
      style="min-height: 80px;"
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions align="center">
  <button mat-flat-button color="accent" [disabled]="!justificationFormGroup.valid" (click)="close(justificationFormGroup.get('justification').value)" cdkFocusInitial>{{confirmBtnText}}</button>
  <button mat-stroked-button color="accent" (click)="close()">Cancel</button>
</div>