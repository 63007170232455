import { FormControl, Validators } from '@angular/forms';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { IBannerNotification, IInitialData, IWeddingColor } from 'wz-types';
import { ICategory, ICategoryWithSubcategories } from 'wz-types/categories';
import { IWedzeeSiteSettings } from 'wz-types/pages-policies.d';
import * as Sentry from '@sentry/angular';
import environment from '../environments/environment';
import { User } from './user.class';

export interface IMenuNavLink {
  name: string;
  routerLink: string[];
  doc?: any;
}

export class Globals {
  public static user: User;
  public static environment: any = environment;
  public static userInstantiated$: ReplaySubject<void> = new ReplaySubject(1);
  public static userUpdated$: Subject<void> = new Subject();
  public static shoppingCartInstantiated$: ReplaySubject<void> =
    new ReplaySubject(1);
  public static sellerAccountInstantiated$: ReplaySubject<void> =
    new ReplaySubject(1);
  public static sellerStripeAccountInstantiated$: ReplaySubject<void> =
    new ReplaySubject(1);
  public static shoppingCartUpdated$: Subject<void> = new Subject();
  public static sellerAccountUpdated$: Subject<void> = new Subject();
  public static signOut$: Subject<void> = new Subject();
  public static supportEmail = environment.supportEmail;
  public static destroy$: Subject<void> = new Subject();
  public static isAdminApp: boolean;
  public static isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  public static isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public static loader = {
    isLoading: false,
    message: '',
  };

  public static affiliateUrl =
    'https://www.affiliatly.com/af-1050455/affiliate.panel?mode=register';
  public static siteSettings: IWedzeeSiteSettings;
  public static colors: IWeddingColor[];
  public static categories: ICategoryWithSubcategories[];
  public static initialLoadData: IInitialData;
  public static isIos: boolean;
  public static defaultUserPhotoUrl =
    'https://firebasestorage.googleapis.com/v0/b/wedzee-dev.appspot.com/o/default_avatar.jpg?alt=media&token=019e3249-a31f-4d00-ba78-792c6828bda2';
  public static isInitialUserLoaded: boolean;
  public static menuNavLinks: IMenuNavLink[] = [];
  public static sellerDashboardMenuLinks: IMenuNavLink[] = [
    { name: 'Home', routerLink: ['seller/home'] },
    { name: 'Items I\'m Selling', routerLink: ['seller/my-items'] },
    { name: 'Orders', routerLink: ['seller/orders'] },
    { name: 'Sales History', routerLink: ['seller/sales-history'] },
    { name: 'Payment', routerLink: ['seller/payment'] },
    { name: 'My Seller Profile', routerLink: ['seller/profile'] },
  ];
  public static bannerNotifications: IBannerNotification[];
  public static bannerNotificationsInstantiated$: ReplaySubject<void> =
    new ReplaySubject(1);

  public static categoriesLookup: { [categoryId: string]: ICategory } = {};

  public static passwordRequirements = `Passwords must be 8 to 20 characters, must start with a letter, and contain at least one number.`;

  public static defaultSwiperConfig: SwiperConfigInterface = {
    observer: true,
    slidesPerView:
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <
      377
        ? 2.1
        : 1.97,
    keyboard: true,
    spaceBetween: 10,
    navigation: true,
    pagination: false,
    grabCursor: true,
    loop: false,
    scrollbar: false,
    preloadImages: false,
    lazy: true,
    /**
     * Paste the following script into your inspector console to generate breakpoints:
      x = [];
      var max = 5.5;
      var min = 2;
     for (let i = 0; i < 100; i++) {
       let additionalPx = (28.125 * i);
       let slidesPerView = 1 + (.11 * i);
       let initial = 180;
       if (initial + additionalPx < 382)  slidesPerView = min;
       if (initial + additionalPx >= 1400) slidesPerView = max;
       x.push({ [initial + additionalPx]: { slidesPerView } })
      };
      var sorted = x
        .sort((a, b) => {
          const aSlides = a[Object.keys(a)[0]].slidesPerView;
          const bSlides = b[Object.keys(b)[0]].slidesPerView;
          return aSlides - bSlides;
        })
        .filter((v, i) => {
          const numSlides = v[Object.keys(v)[0]].slidesPerView;
          return numSlides > 2 && numSlides < max;
        })
      y = sorted
        .reduce((p, c) => { return {...p, ...c} }, {});
      JSON.stringify(y);
     */
    // tslint:disable-next-line:max-line-length
    breakpoints: {
      413: { slidesPerView: 1.97 },
      461.25: { slidesPerView: 1.97 },
      630: { slidesPerView: 2.76 },
      855: { slidesPerView: 3.64 },
      1080: { slidesPerView: 4.52 },
      1305: { slidesPerView: 5.4 },
      489.375: { slidesPerView: 2.21 },
      517.5: { slidesPerView: 2.3200000000000003 },
      545.625: { slidesPerView: 2.4299999999999997 },
      573.75: { slidesPerView: 2.54 },
      601.875: { slidesPerView: 2.65 },
      658.125: { slidesPerView: 2.87 },
      686.25: { slidesPerView: 2.98 },
      714.375: { slidesPerView: 3.09 },
      742.5: { slidesPerView: 3.2 },
      770.625: { slidesPerView: 3.31 },
      798.75: { slidesPerView: 3.42 },
      826.875: { slidesPerView: 3.53 },
      883.125: { slidesPerView: 3.75 },
      911.25: { slidesPerView: 3.86 },
      939.375: { slidesPerView: 3.97 },
      967.5: { slidesPerView: 4.08 },
      995.625: { slidesPerView: 4.1899999999999995 },
      1023.75: { slidesPerView: 4.3 },
      1051.875: { slidesPerView: 4.41 },
      1108.125: { slidesPerView: 4.63 },
      1136.25: { slidesPerView: 4.74 },
      1164.375: { slidesPerView: 4.85 },
      1192.5: { slidesPerView: 4.96 },
      1220.625: { slidesPerView: 5.07 },
      1248.75: { slidesPerView: 5.18 },
      1276.875: { slidesPerView: 5.29 },
    },
  };

  public static getSubcategories = (categoryId: string) => {
    return <ICategoryWithSubcategories[]>(
      Object.values(Globals.categoriesLookup).filter(
        (c) => c.parentCategoryId === categoryId
      )
    );
  }

  public static noMaliciousCodeValidator = () =>
    Validators.pattern(/^([1-zA-Z0-1@._-\s]{1,255})$/)

  public static passwordValidator = () =>
    Validators.pattern(/^(?=[^\d_].*?\d)\w(\w|[!@#$%]){7,20}/)

  public static emailValidator = () =>
    Validators.pattern(
      /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    )

  public static phoneValidator = () =>
    Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)

  public static whitespaceValidator(control: FormControl): { [s: string]: boolean } {
      if (control.value != null) {
        if (control.value.indexOf(' ') !== -1) {
          return { 'whitespace': true };
        }
      }
      return null;
    }

    public static whitespacesOnlyValidator(control: FormControl): { [s: string]: boolean } {
      if (control.value != null) {
        if (!control.value.replace(/\s/g, '').length) {
          return { 'whitespace': true };
        }
      }
      return null;
    }

  public static setUser(user: User) {
    this.user = user;
    Sentry.setUser({ id: user.id, email: user.email });
  }

  public static startLoading(message?: string, disableScroll = false) {
    if (!disableScroll) {
      window.scrollTo(0, 0);
    }
    Globals.loader.isLoading = true;
    Globals.loader.message = !!message ? message : Globals.loader.message;
    Globals.isLoading$.next(true);
  }

  public static stopLoading(message?: string) {
    Globals.loader.isLoading = false;
    Globals.loader.message =
      message === Globals.loader.message || message === undefined
        ? undefined
        : Globals.loader.message;
    Globals.isLoading$.next(false);
  }

  public static screenWidth() {
    return Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
  }

  public static isWidth(width: number): {
    xl: boolean;
    lg: boolean;
    md: boolean;
    sm: boolean;
    xs: boolean;
  } {
    const w = width;
    return {
      xl: w > 1919,
      lg: w > 1279 && w < 1919,
      md: w > 959 && w < 1279,
      sm: w > 599 && w < 959,
      xs: w < 599,
    };
  }

  public static isWidthLessThan(width: number): {
    xl: boolean;
    lg: boolean;
    md: boolean;
    sm: boolean;
  } {
    const w = width;
    return {
      xl: true,
      lg: w < 1280,
      md: w < 960,
      sm: w < 600,
    };
  }

  public static removeFalsy: any = (obj: {}) => {
    Object.keys(obj).forEach((k) => {
      const val = obj[k];
      if (
        val === null ||
        val === undefined ||
        (Array.isArray(val) && val[0] === undefined)
      ) {
        delete obj[k];
      }
      if (
        val === 'null' ||
        val === 'undefined' ||
        (Array.isArray(val) && val[0] === 'undefined')
      ) {
        delete obj[k];
      }
    });
    return obj;
  }

  public static logError(
    e: any,
    consoleMessage: string,
    showReportDialog: boolean = true
  ) {
    const err = e.originalError || e.error || e;
    console.error(consoleMessage + ': ', err);
    if (this.user !== undefined && this.user !== null) {
      Sentry.setUser({ id: this.user.id, email: this.user.email });
    }
    const eventId = Sentry.captureException(err);
    if (showReportDialog) {
      if (this.user !== undefined && this.user !== null) {
        Sentry.showReportDialog({
          eventId: eventId,
          'user.email': this.user.email,
          'user.name': this.user.username,
        });
      } else {
        Sentry.showReportDialog({ eventId: eventId });
      }
    }
  }
}
