<mat-toolbar *ngIf="menuNavLinks as links" class="top-navbar no-padding" fxLayout="row" fxLayoutAlign="start center" style="height: 45px!important; width: 100vw!important;border-color:lightgray;">
  <div class="max-page-width no-margin no-padding" style="text-align:left;">
    <ng-container *ngFor="let link of links; let i = index;">
      <ng-container *ngIf="link?.doc as c">
         <ng-container [ngSwitch]="!!(c.subCategories?.length > 0)">
          <div class="menu-item primary-color" *ngSwitchCase="true"
            [matMenuTriggerFor]="megaMenu"
            #trigger="matMenuTrigger"
            wzDebounceHover
            (debounceHoverEnter)="openMegaMenu(c, trigger, $event); $event.target.focus()"
            (mouseleave)="mouseLeftButton($event)"
            mat-button [matMenuTriggerFor]="megaMenu"
            [matMenuTriggerData]="{ chunks$: megaMenuChunks$, trigger: trigger }"
            [routerLinkAbsolute]="c | categoryUrl">

            <div class="menu-item-text primary-color">{{ link?.name }}</div>
          </div>
          <div
            class="menu-item" *ngSwitchCase="false" (debounceHoverEnter)="$event.target.focus()" (mouseenter)="!!trigger ? trigger.closeMenu() : undefined" mat-button
            [routerLinkAbsolute]="c | categoryUrl">
            <div class="menu-item-text primary-color">{{ link?.name }}</div>
          </div>
         </ng-container>
      </ng-container>
      <div
        *ngIf="!link?.doc"
        [matMenuTriggerFor]="megaMenu"
        #trigger="matMenuTrigger"
        wzDebounceHover
        class="menu-item"
        (debounceHoverEnter)="openMegaMenu(undefined, trigger, $event); $event.target.focus()"
        [matMenuTriggerData]="{ chunks$: megaMenuChunks$, trigger: trigger }"
        mat-button
        [routerLink]="link?.routerLink"
      >
        <div class="menu-item-text primary-color">{{ link?.name }}</div>
      </div>
    </ng-container>
  </div>
</mat-toolbar>

<mat-menu #megaMenu [hasBackdrop]="false" [overlapTrigger]="false"  class="max-page-width mega-menu" >
  <ng-template matMenuContent let-chunks$="chunks$" let-trigger="trigger">
    <div class="mega-menu-container" (mouseleave)="trigger.closeMenu()"   >
      <div *ngFor="let chunk of chunks$ | async" style="vertical-align: top; display: inline-block; width: calc(100% / 4);" >
        <a *ngFor="let c of chunk"  [ngClass]="c.class" [routerLinkAbsolute]="c?.routerLink">
          {{c?.name}}          
        </a>
      </div>
    </div>
  </ng-template>
</mat-menu>
