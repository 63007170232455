import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SWIPER_CONFIG, SwiperModule } from 'ngx-swiper-wrapper';
import { AdminConsumerSharedModule } from '~shared/admin-consumer-shared.module';
import { Globals } from '~shared/classes/global.class';
import { WzAngularMaterialModule } from '~shared/wz-angular-material/wz-angular-material.module';

import { CategoryCarouselComponent } from './category-carousel/category-carousel.component';
import { ColorCarouselComponent } from './color-carousel/color-carousel.component';
import { DisputeDisclaimerComponent } from './dispute-disclaimer/dispute-disclaimer.component';
import { ImpersonateHandlerComponent } from './impersonate-handler/impersonate-handler.component';
// import { MoneyBackBannerComponent } from './money-back-banner/money-back-banner.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { ProductCarouselComponent } from './product-carousel/product-carousel.component';
import { ReviewCarouselComponent } from './review-carousel/review-carousel.component';
import { ProductListFilterComponent } from './product-list-filter/product-list-filter.component';
import { SelectComponent } from './select/select.component';
import { ConsumerSellOnWedzeePageComponent } from './sell-on-wedzee-page/sell-on-wedzee-page.component';
import { AffiliateProgramComponent } from './affiliate-program/affiliate-program.component';
import { ConsumerSellOnWedzeeComponent } from './sell-on-wedzee/sell-on-wedzee.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { TrustIconCarouselComponent } from './trust-icon-carousel/trust-icon-carousel.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { SubscribeMailingComponent } from './subscribe-mailing/subscribe-mailing.component';

// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto'
// };

@NgModule({
  declarations: [
    ProductCarouselComponent,
    ReviewCarouselComponent,
    ColorCarouselComponent,
    CategoryCarouselComponent,
    ProductListFilterComponent,
    TrustIconCarouselComponent,
    StarRatingComponent,
    DisputeDisclaimerComponent,
    MultiSelectComponent,
    SelectComponent,
    ImpersonateHandlerComponent,
    // MoneyBackBannerComponent,
    ConsumerSellOnWedzeePageComponent,
    AffiliateProgramComponent,
    ConsumerSellOnWedzeeComponent,
    ImageGalleryComponent,
    SubscribeMailingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AdminConsumerSharedModule,
    WzAngularMaterialModule,
    SwiperModule,
    FormsModule
  ],
  exports: [
    ProductCarouselComponent,
    ReviewCarouselComponent,
    ColorCarouselComponent,
    CategoryCarouselComponent,
    ProductListFilterComponent,
    TrustIconCarouselComponent,
    StarRatingComponent,
    DisputeDisclaimerComponent,
    MultiSelectComponent,
    SelectComponent,
    ConsumerSellOnWedzeePageComponent,
    ConsumerSellOnWedzeeComponent,
    FormsModule,
    ImageGalleryComponent,
    SubscribeMailingComponent
    // MoneyBackBannerComponent
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: Globals.defaultSwiperConfig
    }  ]
})
export class ComponentsModule { }
