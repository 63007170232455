import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioButton, MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { BehaviorSubject, EMPTY, empty, from as fromPromise, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, take, takeUntil, switchMap } from 'rxjs/operators';
import { IAddress, TEnterAddressOptions } from 'wz-types/address.d';
import { Location } from '@angular/common';

import { Globals } from '../../classes';

declare var google: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-enter-address',
  templateUrl: './enter-address.component.html',
  styleUrls: ['./enter-address.component.scss']
})
export class EnterAddressComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild('searchText') searchTextInput: ElementRef<HTMLInputElement>;
  @ViewChild('addressRadioGroup') addressRadioGroup: ElementRef<MatRadioGroup>;

  @Input() value: IAddress;
  @Input() isLoading = false;
  @Input() isBilling: boolean;
  @Input() isShipping: boolean;
  @Input() isDialog: boolean;
  @Input() hideSaveButton = false;
  @Input() showNextBtn: boolean;

  public tempAddress: IAddress = <any>{}; // This is a holder for values that are not yet valid. Once a valid address is entered, this is set as value
  initialValue: IAddress; // Initial value to be reset if edit is cancelled.

  @Output() addressChange: EventEmitter<IAddress | void> = new EventEmitter();
  @Output() validatedAddressChange: EventEmitter<IAddress | void> = new EventEmitter();
  @Output() save: EventEmitter<IAddress> = new EventEmitter();

  private validateSaveAndEmit$: BehaviorSubject<any> = new BehaviorSubject(undefined as any);

  nameForm: FormGroup;
  addressForm: FormGroup;

  autoCompleteForm: FormGroup;

  destroy$: Subject<void> = new Subject();
  userAddresses$: Observable<IAddress[]>;
  userHasAddresses: boolean;
  disableSaveButton = false;

  selectListAddressId: string;
  selectListButton: MatRadioButton;

  isEnteringAddress: boolean;
  isChangingAddress: boolean;

  isGooglePlaceInvalid: boolean;
  newAddressInvalid = false;
private sub: Subscription;
  stateAbbreviations = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS',
    'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV',
    'WI', 'WY', 'GU', 'PR', 'VI'
  ];

  constructor(
    private mapsApiLoader: MapsAPILoader,
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private http: HttpClient,
    private _location: Location
  ) { }

  ngOnInit() {
    this.init();

    if (this.hideSaveButton) {
      this.addressChange.pipe(
        filter((a: IAddress) => this.isAddressValid(a)),
        debounceTime(1000),
        distinctUntilChanged(),
        mergeMap((a) => {
          return this.http.post(`${Globals.environment.apiUrl}addresses/validate`, a).pipe(
            map(() => {
              this.newAddressInvalid = false;
              return { address: a, isValid: true };
            }),
            catchError(() => {
              this.newAddressInvalid = true;
              return of({ address: a, isValid: false });
            })
          );
        }),
        map((r: { address: IAddress, isValid: boolean }) => {
          if (r.isValid) {
            this.validatedAddressChange.next(this.tempAddress);
          }
        }),
        takeUntil(this.destroy$)
      ).subscribe();
    }

    this.validateSaveAndEmit$.pipe(
      filter((a: IAddress) => this.isAddressValid(a)),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() =>
          this.http.post(`${Globals.environment.apiUrl}addresses/validate`, this.tempAddress)
            .pipe(
                catchError((error) => {
                  this.newAddressInvalid = true;
                  this.disableSaveButton = false;
                  return EMPTY;
                })
            )
      ),
      mergeMap((a: IAddress) => Globals.user.saveAddress(a, true)),
      map((a: IAddress) => {
        a = Globals.removeFalsy(a);
        this.save.emit(a);
        Object.keys(this).forEach(key => this[key] instanceof FormGroup ? this[key].reset() : undefined);
        this.disableSaveButton = false;
        this.value = this.tempAddress;
        this.isChangingAddress = false;
        this.value = a;
      }),
      take(1),
      catchError((e: any) => {
        this.newAddressInvalid = true;
        this.disableSaveButton = false;
        return of(undefined);
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init() {
    if (this.isDialog) {
      this.isShipping = true;
    }
    if (typeof this.value === 'string' || !this.value) {
      this.value = undefined;
    }
    this.initialValue = this.value;
    this.isLoading = !!this.isLoading;
    this.addressForm = this.formBuilder.group({
      street_no_and_street1: [undefined],
      street1: [undefined],
      street_no: [undefined],
      city: [undefined, Validators.required],
      state: [undefined, Validators.required],
      zip: [undefined, Validators.required],
      country: ['US'],
      street2: [undefined]
    });

    this.autoCompleteForm = new FormGroup({
      searchText: new FormControl(undefined, Validators.required),
    });

    this.nameForm = this.formBuilder.group({
      name: [undefined, Validators.required],
      email: [undefined, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/)]]
    });
    if (this.isBilling) {
      this.nameForm.removeControl('email');
    }

    this.nameForm.valueChanges.pipe(
      map((formVals: any) => this.updateAddress(formVals)),
      takeUntil(this.destroy$)
    ).subscribe();

    this.addressForm.valueChanges.pipe(
      map((formVals: any) => {
        const { city, state, zip, country, street_no_and_street1, street2 } = formVals;
        let { street_no, street1 } = formVals;
        if ((!street_no || !street1) && street_no_and_street1) {
          street1 = street_no_and_street1;
          const words: string[] = street_no_and_street1.split(' ');
          const containsNumber = (v: string) => /[0-9]/g.test(v);
          const numsTo = words.filter((v, i, a) => containsNumber(v) && (i === 0 || containsNumber(a[i - 1])));
          street_no = words.slice(0, numsTo.length).join(' ').trim();
          street1 = words.slice(numsTo.length).join(' ').trim();
        }
        const newAddressData = {
          city, state, zip, country, street1, street_no, street2
        };
        this.updateAddress(newAddressData);
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    Globals.userInstantiated$.pipe(
      map(() => {
        if (!!Globals.user && !!Globals.user.id) {
          this.userAddresses$ = Globals.user.addresses$.pipe(map((addresses: any) => {
            this.userHasAddresses = !!addresses && addresses.length > 0;
            return addresses;
          }));
        }
        if (Globals.user && Globals.user.email && !!this.nameForm.get('email')) {
          this.nameForm.get('email').setValue(Globals.user.email);
        }
        if (Globals.user && Globals.user.SellerAccount && Globals.user.SellerAccount.firstName) {
          const { firstName, lastName } = Globals.user.SellerAccount;
          this.nameForm.get('name').setValue(`${firstName} ${lastName}`);
        }
      })
    ).subscribe();

    if (!!this.value) {
      this.isChangingAddress = false;
      this.updateAddressFormFromSelected(this.value);
    } else {
      this.isChangingAddress = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initGooglePlaces() {
    let autoComplete;
    fromPromise(this.mapsApiLoader.load()).pipe(
      mergeMap(() => {
        autoComplete = new google.maps.places.Autocomplete(this.searchTextInput.nativeElement, { types: ['address'] });
        return new Observable((subscriber: any) => {
          autoComplete.addListener('place_changed', () => subscriber.next());
        });
      }),
      map(() => {
        this.zone.run(() => {
          const googlePlace = autoComplete.getPlace();
          const currentVal = this.searchTextInput.nativeElement.value;
          if (currentVal !== googlePlace.formatted_address) {
            this.searchTextInput.nativeElement.value = googlePlace.formatted_address;
          }
          const address = this.parsePlacesResultIntoAddress(googlePlace);
          if (this.isGooglePlaceValidAddress(googlePlace)) {
            this.isGooglePlaceInvalid = false;
            this.updateAddressFormFromSelected(address);
          } else {
            this.isGooglePlaceInvalid = true;
          }
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  parsePlacesResultIntoAddress(placesResult: any): IAddress {
    const tObj: any = {};
    placesResult.address_components.forEach((c) => c.types.forEach((t: string) => tObj[t] = c.short_name));
    const result = {
      street_no: tObj.street_number,
      street1: tObj.route,
      city: tObj.sublocality || tObj.locality,
      state: tObj.administrative_area_level_1,
      zip: tObj.postal_code,
      country: tObj.country
    };
    return result;
  }

  isGooglePlaceValidAddress(googlePlace: any) {
    const componentTypes = googlePlace.address_components.map(c => c.types).reduce((p, c) => [ ...p, ...c ]);
    const hasType = (type: string) => componentTypes.indexOf(type) > -1;
    return ['street_number', 'route', 'postal_code'].every(t => hasType(t)) && ['locality', 'sublocality']
      .filter(t => hasType(t)).length > 0;
  }

  isAddressValid(a?: IAddress) {
    const address = a || this.tempAddress;
    const { street_no, street1, city, state, zip, name, email } = address || <any>{};
    let result = !!street1 && !!city && !!state && !!zip && zip.length >= 5 && !!name;
    if (!this.isBilling) {
      result = result && !!email;
    }
    return result;
  }

  disableSubmit() {
    return !(this.isAddressValid(this.tempAddress) && (this.isBilling || this.nameForm.valid)) || this.disableSaveButton;
  }

  updateAddress(a: any, emitSaveEvent?: boolean) {
    this.newAddressInvalid = false;
    this.tempAddress = { ...this.value, ...this.tempAddress, ...this.nameForm.getRawValue(), ...a };
    delete this.tempAddress.id;
    delete this.tempAddress.shippoAddressId;
    delete this.tempAddress.shippoIsValid;
    if (this.isAddressValid(this.tempAddress)) {
      this.value = this.tempAddress;
      this.addressChange.emit(this.tempAddress);
      if (emitSaveEvent) {
        this.disableSaveButton = true;
        this.validateSaveAndEmit$.next(this.value);
      }
    } else {
      this.addressChange.emit(undefined);
    }
  }

  selectedFromRadio(addresses: any, changeEvent: MatRadioChange) {
    this.selectListButton = changeEvent.source;
    const addr = addresses.find((address: any) => address.id === changeEvent.value);
    this.selectListAddressId = changeEvent.value;
    const a = Globals.removeFalsy(addr);
    this.save.emit(a);
    this.value = a;
    this.isChangingAddress = false;
  }

  updateAddressFormFromSelected(selectedAddress: IAddress) {
    this.addressForm.reset();
    const numberAndStreet1 = `${selectedAddress.street_no} ${selectedAddress.street1}`;
    this.autoCompleteForm.get('searchText').setValue(numberAndStreet1);
    this.addressForm.get('street_no_and_street1').setValue(numberAndStreet1);
    this.addressForm.get('street_no').setValue(selectedAddress.street_no);
    this.addressForm.get('street1').setValue(selectedAddress.street1);
    this.addressForm.get('city').setValue(selectedAddress.city);
    this.addressForm.get('state').setValue(selectedAddress.state);
    this.addressForm.get('zip').setValue(selectedAddress.zip);
    this.addressForm.get('country').setValue(selectedAddress.country);
    if (selectedAddress.street2) {
      this.addressForm.get('street2').setValue(selectedAddress.street2);
    }
    if (this.tempAddress.name) {
      this.nameForm.get('name').setValue(this.tempAddress.name);
    }
    if (this.tempAddress.email && !!this.nameForm.get('email')) {
      this.nameForm.get('email').setValue(this.tempAddress.email);
    }
  }

  keyupAddressField(keyupEvent: any) {
    const numberAndStreet1 = keyupEvent.target.value;
    const newStreetNumber = numberAndStreet1.split(' ').filter(w => !!w[0] && !isNaN(Number(w[0])) && !isNaN(Number(w[w.length - 1]))).join(' ');
    const newStreet1 = numberAndStreet1.substr(newStreetNumber.length).trim();
    this.addressForm.get('street_no_and_street1').setValue(numberAndStreet1);
    this.updateAddress({ street_no: newStreetNumber, street1: newStreet1 });
  }

  getSwitch(userAddresses: IAddress[]): TEnterAddressOptions {
    let result: TEnterAddressOptions = 'addressValid';
    const isValueValid = this.isAddressValid(this.value);
    if (this.isLoading) {
      result = 'isLoading';
    }
    if (isValueValid && !this.isChangingAddress) {
      result = 'addressValid';
    }
    if (this.isChangingAddress && (this.isEnteringAddress || !this.userHasAddresses)) {
      result = 'enteringAddress';
    }
    if (this.isChangingAddress && this.userHasAddresses && !(this.isEnteringAddress || !this.userHasAddresses)) {
      result = 'selectingMyAddresses';
    }
    return result;
  }

  clickedCancelEnterAddress() {
    this.isEnteringAddress = false;
    // this.isChangingAddress = !this.isAddressValid(this.value);
    // this.value = this.initialValue;
    if (!this.userHasAddresses) {
      this._location.back();
    }
  }

  clickAddOrChangeAddress(clickedAdd?: boolean) {
    this.addressForm.reset();
    this.autoCompleteForm.reset();
    this.tempAddress = undefined;
    this.isChangingAddress = true;
    if (!this.userHasAddresses || clickedAdd) {
      this.isEnteringAddress = true;
    }
    this.selectListButton = undefined;
    this.selectListAddressId = undefined;
    if (!this.value) {
      this.save.emit(undefined);
    }
  }

}
