<div class="how-to-sell-section">
  <div class="how-to-sell-content general-content-style" ngStyle.xs="text-align:center;">
    <h2>How to sell on Wedzee</h2>
    <p>Learn everything you need to know to start selling today.</p> 
  </div>
  <div class="how-to-sell-image">
    <img src="/assets/images/selling-photo.png" />
  </div>
</div>

<div class="list-your-item-on-wedzee general-content-style">
   <h2>List your items on Wedzee for free</h2>
   <p style="max-width: 500px; margin: 0 auto;">Sell your new, custom or pre-loved wedding items.
    Hundreds of new items are added each day.</p>
</div>

<!-- <div style="margin-top: 50px;" ngStyle.xs="margin-left:20px;margin-right:20px;">
  <div class="videoheader">Check out our video to see just how easy it is to use Wedzee.</div>
</div>
<div style="margin-top: 30px;">
  <iframe src="https://player.vimeo.com/video/537852552" style="border: 1px solid #CCC;" width="640" height="360" ngStyle.xs="width:320px;height:180px;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
</div> -->


<ng-container [ngSwitch]="isASeller()">
  <div class="text-center cta buy-sell-tab-content"> 
    <div style="font-size: 22px; color:#000000; font-weight: bold; font-family: 'century_gothicregular'; margin-bottom:45px; margin-top:20px;" ngStyle.xs="font-size:20px;margin-bottom:25px; margin-top:0px;font-weight: bold;">Sell it. Ship it. Get Paid.</div>
    <div>
      <div fxLayout="row wrap"  fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column">
        <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
          <img  src="./../../../../assets/img/Sell_SellIt.png" />
          <div style="width: 265px; float:left; position: relative; display: block;  height: 12px; margin-top : -100px; margin-bottom:  50px; margin-right:  15px; margin-left:  290px;" class="dotted-line"
          fxShow.gt-sm="true" fxHide.lt-sm fxHide.lt-md fxHide.lt-lg>
          </div>
          <div class="rsn-title">Sell It</div>
          <div class="mat-subheading-2 rsn-detl">
            Listings are free. Take good photos, clearly describe your item, and set a price.
          </div>
        </div>
        <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
          <img  src="./../../../../assets/img/Sell_ShipIt.png" />
          <div style="width: 265px; float: left; position: relative; display: block; height: 12px; margin-top: -100px; margin-bottom: 50px; margin-right: 15px; margin-left: 290px;"  class="dotted-line"
          fxShow.gt-sm="true" fxHide.lt-sm fxHide.lt-md fxHide.lt-lg>
          </div>
          <div class="rsn-title">Ship It</div>
          <div class="mat-subheading-2 rsn-detl">
            After your item sells, a shipping label will be emailed to you. Carefully pack it, and ship it.
          </div>
        </div>
        <div fxFlex="calc(33%-20px)" fxFlex.lt-sm="100" class="rsn">
          <img  src="./../../../../assets/img/Sell_GetPaid.png" />
          <div class="rsn-title">Get Paid</div>
          <div class="mat-subheading-2 rsn-detl">
            After the item is successfully delivered funds will be directly deposited, minus an 8% fee.
          </div>
        </div>
      </div>
      <br>
      <button *ngSwitchCase="false" wzMustSignIn activity="enroll as a seller." (signedInOutput)="becomeASeller()"
        mat-flat-button color="accent" style="margin:20px auto 40px" ngStyle.xs="margin:0px auto 40px" class="bg-offset-color">
        Start Selling
      </button>
      <button *ngSwitchCase="true" mat-flat-button color="accent" routerLinkAbsolute="seller/home"
      style="font-family: 'century_gothicregular' !important; margin:20px auto 40px;" class="bg-offset-color">
        Go to Seller Account
      </button>
    </div>
  </div>
  <br> 
</ng-container>

<div class="get-start-selling-section">
  <div class="get-start-selling-container general-content-style">
    <h2>Want help adding your listings?</h2>
    <p>Our team will be happy to help setup your listings. Drop your email below (no commitment, and it's free!). </p> 
    <div class="email-input">
      <form *ngIf="helpForm as form" [formGroup]="helpForm">
        <mat-form-field class="mat-field-sell-wedzee" style="margin-right: 15px;" ngStyle-xs="margin-right: 0px;" *ngIf="form.get('email') as ctrl">
          <mat-label>Email</mat-label>
          <input
            [formControl]="ctrl"
            matInput
            placeholder="Enter your email address..."
            
            type="text" style="width:250px !important;"
          />
          <mat-error *ngIf="!ctrl.valid">Email address is required</mat-error>
        </mat-form-field>
        <button [disabled]="form.invalid" (click)="askForHelp()" class="btn btn-large btn-purple" style="height: 50px;" ngStyle.xs="margin-top:0px;margin-bottom:10px;">Get Started</button>
      </form>
    </div>
  </div>
</div>

<div class="sell-your-item-section general-content-style">
  <h2 class="show-below-980">Create your listing</h2>
  <div class="sell-your-item-content ">
    <h2 class="hide-below-980">Create your listing</h2>
    <p>
      <b>Take pictures and describe your item</b> <br>
      Take good photos, and clearly describe your item.
    </p> 
    <p>
      <b>Set your price</b> <br>
      Price fairly. Search similar listings to what items like yours sold for.
    </p> 
  </div>
  <div class="sell-your-item-image">
    <img src="/assets/images/list_your_item.png" />
  </div>
</div>

<div class="ready-to-list-section">
  <div class="ready-to-list-bg">
    <div class="ready-to-list-container">
      <div class="ready-to-list-content general-content-style">
        <h2>Ready to list your item?</h2>
        <button class="btn hide-767 btn-large btn-purple" wzMustSignIn activity="enroll as a seller." (signedInOutput)="becomeASeller()">Start Selling</button>
      </div>
      <div class="ready-to-list-image">
        <span>SOLD</span>
        <img src="/assets/images/shoes-2.png" style="max-width: 230px" /> <br>
        <button class="btn show-767 btn-large btn-purple" wzMustSignIn activity="enroll as a seller." (signedInOutput)="becomeASeller()">Start Selling</button>
      </div>
    </div>
  </div>
</div>

<div class="sell-your-item-section general-content-style">
  <h2 class="show-below-980">Ship your item to the buyer</h2>
  <div class="sell-your-item-content">
    <h2 class="hide-below-980">Ship your item to the buyer</h2>
    <p>
      <b>Pack item with care</b> <br>
      We send you a pre-paid shipping label to print and attach to your shipment.
    </p> 
    <p>
      <b>Ship within 3 business days</b> <br>
      Once you ship your item, a confirmation code will be provided to you and the buyer.
    </p> 
  </div>
  <div class="sell-your-item-image">
    <img src="/assets/images/ship_your_item.png" />
  </div>
</div>

<div class="sell-your-item-section general-content-style" ngStyle.xs="padding-top:0px;">
  <h2 class="show-below-980">Item delivered, you get paid</h2>
  <div class="sell-your-item-content">
    <h2 class="hide-below-980">Item delivered, you get paid</h2>
    <p>
      <b>The buyer confirms delivery</b> <br>
      Buyers have 3 days to confirm.
    </p> 
    <p>
      <b>Payment directly deposited</b> <br>
      Funds are deposited to your account minus 8% for the transaction.
    </p> 
  </div>
  <div class="sell-your-item-image">
    <img src="/assets/images/get_paid.png" />
  </div>
</div>

<div class="wedzee-gurantee-section-parent">
  <div class="wedzee-gurantee-section general-content-style">
    <h2>Sellers are backed by the Wedzee Guarantee</h2>
    <h5>Buyer and seller protections ensure hassle-free transactions.</h5>
  </div>
</div>

<div class="wedzee-seller-review general-content-style">
  <h2>Recent reviews from Wedzee sellers</h2>
  <div class="seller-review-list">
    <div class="seller-review-item">
      <h4>Wedzee is my go-to place to sell wedding items</h4>
      <p style="height:100px;">“Wedzee is the easiest platform to use. Snap a few photos, post my items and I’m selling.”</p>
      <div class="review-star">
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
      </div>
      <div class="user-review-name">
        <p>
          December 2020 <br>
          <b>Social Media Review</b>
        </p>
      </div>
    </div>
    <div class="seller-review-item">
      <h4>Selling to people who actually want wedding items</h4>
      <p style="height:100px;">“I like how focused Wedzee is on the wedding items only. I get to sell to people who actually want wedding items.”</p>
      <div class="review-star">
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
      </div>
      <div class="user-review-name">
        <p>
          February 2021 <br>
          <b>Survey Review</b>
        </p>
      </div>
    </div>
    <div class="seller-review-item">
      <h4>Easiest way to sell used wedding items</h4>
      <p style="height:100px;">“Selling my dress was easier than I thought. Wedzee was seamless from listing to shipping.”</p>
      <div class="review-star">
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
        <svg class="star-round-active" viewBox="3.668 4.068 29.552 27.657">
          <path id="star-round-active" d="M 17.55896759033203 27.77294921875 C 18.10565185546875 27.45230102539062 18.78293800354004 27.45230102539062 19.32941627502441 27.77294921875 L 25.65020561218262 31.47920799255371 C 26.98092460632324 32.25973510742188 28.59711074829102 31.06127738952637 28.23697471618652 29.56108283996582 L 26.59589958190918 22.72672271728516 C 26.44123458862305 22.08234405517578 26.66377258300781 21.40587997436523 27.17076301574707 20.97931098937988 L 32.59481430053711 16.41395378112793 C 33.78998565673828 15.4080171585083 33.16864776611328 13.45922946929932 31.61168670654297 13.33091068267822 L 24.37564468383789 12.73445415496826 C 23.73373031616211 12.68155288696289 23.1728572845459 12.28038597106934 22.91535377502441 11.69009876251221 L 20.04824829101562 5.117951393127441 C 19.4373950958252 3.717595815658569 17.45119476318359 3.717595815658569 16.8403434753418 5.117951393127441 L 13.9731969833374 11.69009876251221 C 13.71569156646729 12.28038597106934 13.15477561950684 12.68155288696289 12.51294994354248 12.73445415496826 L 5.276761054992676 13.33091068267822 C 3.719989538192749 13.45922946929932 3.098523378372192 15.4080171585083 4.293636322021484 16.41395378112793 L 9.717851638793945 20.97931098937988 C 10.22483921051025 21.40587997436523 10.44731903076172 22.08234405517578 10.2926082611084 22.72672271728516 L 8.651553153991699 29.56108283996582 C 8.291354179382324 31.06127738952637 9.907566070556641 32.25973510742188 11.23842525482178 31.47920799255371 L 17.55896759033203 27.77294921875 Z">
          </path>
        </svg>
      </div>
      <div class="user-review-name">
        <p>
          March 2021 <br>
          <b>Social Media Review</b>
        </p>
      </div>
    </div>
  </div>
  <div class="user-review-btn">
    <button class="btn btn-xl btn-purple" wzMustSignIn activity="enroll as a seller." (signedInOutput)="becomeASeller()">Start Selling</button>
  </div>
</div>
