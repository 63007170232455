import { Pipe, PipeTransform } from '@angular/core';
import { IListing } from 'wz-types';

@Pipe({
  name: 'productUrl'
})
export class ProductUrlPipe implements PipeTransform {

  transform(p: IListing): any {
    return `product/${p.id}/${this.processName(p.name)}`;
  }

  processName(name: string): string {
    return (name || '').toLowerCase().trim().split(' ').join('-').replace(/[^-a-zA-Z0-9]+/g, '');
  }

}
