import { Component, Input } from '@angular/core';

@Component({
  selector: 'wz-money-back-banner',
  templateUrl: './money-back-banner.component.html',
  styleUrls: ['./money-back-banner.component.scss']
})
export class MoneyBackBannerComponent {
  @Input() hideExplanationMobile = false;
  @Input() isCartFooter = false;

  get explanation(): string {
    return 'Receive your items as described or get your money back.';
  }

  constructor() { }

}
