<div class="notification-banner-container">
  <ng-container *ngFor="let bannerNotification of bannerNotifications; index as i">
    <div class="notification-banner" *ngIf="bannerNotification && bannerNotification.isActive" [@slideInOut]>
      <ng-container [ngSwitch]="bannerNotification.messageSeverity">
        <i *ngSwitchCase="'info'" class="fa fa-info-circle fa-3x notification-banner-icon notification-banner-info" aria-hidden="true"></i>
        <i *ngSwitchCase="'warn'" class="fa fa-exclamation-triangle fa-3x notification-banner-icon notification-banner-warn" aria-hidden="true"></i>
        <i *ngSwitchCase="'danger'" class="fa fa-times-circle-o fa-3x notification-banner-icon notification-banner-danger" aria-hidden="true"></i>
      </ng-container>
      <div class="notification-banner-content">
        <h2 *ngIf="bannerNotification.headerMessage" 
          [ngClass]="{ 'notification-banner-info': bannerNotification.messageSeverity === 'info',
                       'notification-banner-warn': bannerNotification.messageSeverity === 'warn',
                       'notification-banner-danger': bannerNotification.messageSeverity === 'danger'}">
          {{bannerNotification.headerMessage}}
        </h2>
        <p *ngIf="bannerNotification.mainMessage">{{bannerNotification.mainMessage}}</p>
      </div>
      <button class="btn btn-purple btn-large notification-banner-acknowledge" type="button" (click)="handleButtonClick(i)">{{bannerNotification.buttonLabel}}</button>
      <span class="notification-banner-close" (click)="handleCloseClick(i)"><i class="fa fa-times fa-2" aria-hidden="true"></i></span>
    </div>
  </ng-container>
</div>