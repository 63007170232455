import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';

import { Globals, IMenuNavLink } from '../../classes/global.class';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-bottom-sheet-menu',
  templateUrl: './bottom-sheet-menu.component.html',
  styleUrls: ['./bottom-sheet-menu.component.scss']
})
export class BottomSheetMenuComponent implements OnInit {
  sellerDashboardItems = Globals.sellerDashboardMenuLinks;
  menuNavLinks: IMenuNavLink[] = [];
  socialLogoSrcs = {
    facebook: 'assets/img/social-logos/facebook.svg',
    instagram: 'assets/img/social-logos/instagram.svg',
    twitter: 'assets/img/social-logos/twitter.svg',
    pinterest: 'assets/img/social-logos/pinterest.svg'
  };

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetMenuComponent>,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const links = Globals.menuNavLinks;
    this.menuNavLinks = [
      ...links.slice(0, links.length - 1),
      { name: 'Shop by color', routerLink: ['wedding-colors'] },
      links[links.length - 1]
    ];
  }

  getMenuNavLinks(): IMenuNavLink[] {
    return Globals.menuNavLinks;
  }

  isOnSellerDashboard() {
    return location.pathname.split('/')[1] === 'seller';
  }

  close() {
    this.bottomSheetRef.dismiss();
  }

  getSubcategories(l: IMenuNavLink) {
    if (l && l.name === 'Shop by color') {
      return Globals.initialLoadData.weddingColors.sort((a, b) => a.name < b.name ? -1 : 1);
    } else if (l && l.doc) {
      return Globals.getSubcategories(l.doc.id);
    }
  }

}
