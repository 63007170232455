export const userRoles = {
    SELLER: 'seller',
    BUYER: 'buyer',
    ADMIN: 'admin',
    SUPERADMIN: 'superadmin'
};

export const carrierTypes = {
    usps: 'USPS',
    ups: 'UPS',
    fedex: 'FEDEX',
    dhl_express: 'DHL Express'
};
