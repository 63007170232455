import { Component, Input, OnInit } from '@angular/core';
import { IWeddingColor } from 'wz-types/home-page';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-wedding-color',
  templateUrl: './wedding-color.component.html',
  styleUrls: ['./wedding-color.component.scss']
})
export class WeddingColorComponent implements OnInit {
  @Input() doc: IWeddingColor;

  constructor() { }

  ngOnInit() {
  }

}
