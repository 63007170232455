<div style="max-width: 1240px!important; margin: auto;">
  <div class="full-width page-header-title">
    Shop by category
  </div>
  <div class="product-category-slider" *ngIf="screenWidth != 0 && screenWidth < 767"> 
    <drag-scroll [drag-scroll-y-disabled]="true">
      <ul>
        <li *ngFor="let option of categories;let i=index;" [routerLinkAbsolute]="option | categoryUrl">{{ option.name }}</li>
        <!-- <li>Under $200</li>
        <li>Under $100</li> -->
      </ul>
    </drag-scroll> 
	</div>
  <div style="width: 100%; display: inline-block;" *ngIf="screenWidth != 0 && screenWidth > 767">
    <button class="categoriesbutton" *ngFor="let option of categories;let i=index;" [routerLinkAbsolute]="option | categoryUrl">
      {{option.name}}
    </button>
    <!-- <button class="categoriesbutton">
      Under $200
    </button>
    <button class="categoriesbutton">
      Under $100
    </button> -->
  </div>
  <div class="full-width text-left">
    <lib-wz-allcategories *ngFor="let c of allcategories" [doc]="c" [routerLinkAbsolute]="c | categoryUrl"></lib-wz-allcategories>
  </div>
</div>

