import { CommonService } from '~shared/services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { Globals } from '../../classes';
import environment from '../../environments/public';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isCypress = (window as any).Cypress;
  socialUrls = Globals.environment.socialUrls;
  year = new Date().getFullYear();
  // logoImgSrc = 'assets/logo.png';
  logoImgSrc = 'assets/logofooter.png';
  get isDevelopment() {
    return environment.development;
  }

  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
  }

  isAdminApp() {
    return !!Globals && !!Globals.isAdminApp;
  }

  public isInIframe(): boolean {
    return this.commonService.isInIframe();
  }

  async signInWithToken() {
    const { token } = await this.http
      .get(`${environment.apiUrl}dev/get-custom-token`, { headers: new HttpHeaders('Authorization:flimmyflammy') })
      .toPromise() as any;
    this.afAuth.signInWithCustomToken(token);
  }
}
