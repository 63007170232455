import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[routerLinkAbsolute]'
})
export class RouterLinkAbsoluteDirective {
  @Input() routerLinkAbsolute: string;

  constructor(
    private router: Router
  ) { }

  @HostListener('click', ['$event']) onClick($event) {
    this.router.navigateByUrl(this.routerLinkAbsolute);
  }
}
