import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { LoginDialogComponent } from '~shared/dialogs';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    fileName = 'common.service.ts';

    constructor(
        private dialog: MatDialog
    ) { }

    public isInIframe(): boolean {
        try {
          return window.self !== window.top;
        } catch (e) {
          return true;
        }
    }

    public openAuthentication(isRegistration?: boolean) {
        const dialogRef = this.dialog.open(LoginDialogComponent, {
          hasBackdrop: true,
          width: '90%',
          maxWidth: '350px',
          position: { top: '100px' },
          data: { isRegistration }
        });

        dialogRef.afterClosed().pipe(
          take(1)
        ).subscribe();
    }
}
