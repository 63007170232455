export function addAutomationButton(testBtnId: string) {
    const testButton = document.createElement('button');
    testButton.id = testBtnId;
    testButton.innerText = 'AUTOMATED TESTING STRIPE CHECKOUT';
    testButton.style.position = 'fixed';
    testButton.style.bottom = '0';
    testButton.style.right = '0';
    document.body.appendChild(testButton);
}

export const testToken = {
    id: 'tok_visa',
    object: 'token',
    card: {
      id: 'card_1FI65pBWgLi8KNKGrG20b0vo',
      object: 'card',
      address_city: null,
      address_country: null,
      address_line1: null,
      address_line1_check: null,
      address_line2: null,
      address_state: null,
      address_zip: '43201',
      address_zip_check: 'unchecked',
      brand: 'Visa',
      country: 'US',
      cvc_check: 'unchecked',
      dynamic_last4: null,
      exp_month: 1,
      exp_year: 2023,
      funding: 'credit',
      last4: '4242',
      metadata: {},
      name: null,
      tokenization_method: null
    },
    client_ip: '174.103.102.191',
    created: 1568347749,
    livemode: false,
    type: 'card',
    used: false
  };
