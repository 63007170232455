import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(items: any[], args?: { searchText: string, fields?: string[] }): any {
    if (!items || items.length === 0) {
      return [];
    }
    if (!args.fields) {
      return items.filter(i => JSON.stringify(i).toLowerCase().indexOf(args.searchText.toLowerCase()) > -1);
    }

    const searchText = !!args ? args.searchText : undefined;
    const fields = !!args ? args.fields : [];
    if (!args.searchText) {
      return items;
    }

    const itemStrings = items.map((thisItem: any) => {
      let itemString = '';
      fields.forEach((field: string) => itemString += thisItem[field]);
      return itemString.toLowerCase();
    });

    const searchWords = searchText
      .split(' ')
      .map((word: string) => this.removeSpecialCharacters(word).toLowerCase());

    const matches = items.filter((item: any, index: number) => {
      const itemString = itemStrings[index];
      const hasAllSearchWords = searchWords.filter((word: string) => itemString.indexOf(word) > -1).length === searchWords.length;
      return hasAllSearchWords;
    });

    return matches;
  }

  private removeSpecialCharacters(sample: string) {
    return sample.replace(/[^\w\s]/gi, '');
  }

}
