import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzDollarInput]'
})
export class DollarInputDirective implements OnInit, OnDestroy {
  @Input() ctrl: AbstractControl;
  private destroy$: Subject<void> = new Subject();
  private keyup$: Subject<void> = new Subject();

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit() {
    this.keyup$.pipe(
      debounceTime(500),
      map(() => this.updateValue()),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('blur')
  onBlur() {
    this.updateValue(true);
  }

  @HostListener('keyup')
  onKeyup() {
    this.keyup$.next();
  }

  updateValue(isBlur?: boolean) {
    let value = (this.element.nativeElement as HTMLInputElement).value;
    if (!!value && !isNaN(Number(value)) && isBlur) {
      value = Number(value).toFixed(2);
      (this.element.nativeElement as HTMLInputElement).value = value;
    } else if (!value) {
      value = '';
      (this.element.nativeElement as HTMLInputElement).value = value;
    }
    if (!!this.ctrl) {
      this.ctrl.setValue(value);
    }
  }

}
