import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from '~shared/classes';
import { AlertService } from '~shared/services';
import { HttpClient } from '@angular/common/http';
import { IHelpCenterRequest } from 'wz-types';
import { filter, map, take, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'wz-subscribe-mailing',
  templateUrl: './subscribe-mailing.component.html',
  styleUrls: ['./subscribe-mailing.component.scss']
})
export class SubscribeMailingComponent implements OnInit {

  helpForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private alertSrv: AlertService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.helpForm = this.formBuilder.group({
      email: [undefined, [Validators.required, Globals.emailValidator()]],
    });
  }

  joinMailingList() {
    const userId = Globals.user && Globals.user.id ? Globals.user.id : null;
    this.alertSrv.confirm('Ready to join the mailing list?', '').pipe(
      filter(r => !!r),
      mergeMap(() => this.http.post(`${Globals.environment.apiUrl}users/mailing-list`, { ...this.helpForm.getRawValue(), userId })),
      map((r: IHelpCenterRequest) => {
        this.alertSrv.alert('Success', `Thank you for joining our mailing list!`);
        this.router.navigateByUrl('/');
      }),
      take(1)
    ).subscribe(() => {
      this.helpForm.get('email').setValue(null);
    });
  }

}
