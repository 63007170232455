import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { TWedzeeTrackingStatus } from 'wz-types';
import { IListing } from 'wz-types/listings';
import { IOrderDetailOrderItem, IWedzeeOrder } from 'wz-types/orders';
import { IShipment, IShippingLabel } from 'wz-types/shippo-api';
import { Globals } from '~shared/classes';

import { OrdersStore } from '../../stores';
import { AlertService } from './../../services/alert/alert.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-order-item-with-tracking',
  templateUrl: './order-item-with-tracking.component.html',
  styleUrls: ['./order-item-with-tracking.component.scss']
})
export class OrderItemWithTrackingComponent implements OnInit {
  @Input() order: IWedzeeOrder;
  @Input() listing: IListing;
  @Input() showActionButtons = true;
  @Input() isSellerPage = false;
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  item: IOrderDetailOrderItem;

  buyerCanCancel = false;

  labels: IShippingLabel[];

  shipments: IShipment[];

  isTrackingExpanded: boolean;

  constructor(
    private alertSrv: AlertService,
    private ordersSrv: OrdersStore
  ) { }

  ngOnInit() {
    this.item = this.order.items.find(i => i.listingId === this.listing.id) || this.item;
    this.labels = (this.order.labels || []).filter(l => this.item.shippoLabelIds.indexOf(l.object_id) > -1);
    this.buyerCanCancel = !this.item.isCancelled && this.item.trackingStatus === 'processing' && this.order.createdTimestamp < new Date().getTime() - 604800000;
  }

  cancelItem() {
    const confirmMsg = this.isSellerPage ?
    'The buyer will be issued a refund and applicable shipping labels will be returned. This may hurt your reputation on Wedzee!' :
    'You will be issued a refund for this item.';

    if (Globals.user && Globals.user.id === this.listing.sellerId) {
      this.alertSrv.confirmWithJustification('Cancel item?', confirmMsg, 'Confirm', 'Please provide a reason for cancellation.').pipe(
        filter(reason => !!reason),
        mergeMap((reason) => Globals.user.SellerAccount.cancelOrderItem(this.order.id, this.listing.id, reason)),
        map(() => {
          this.item.isCancelled = true;
          this.buyerCanCancel = false;
          this.alertSrv.successToast('Order item canceled');
          this.canceled.emit();
        }),
        take(1)
      ).subscribe();
    } else if (Globals.user && Globals.user.id === this.order.buyerUserId) {
      this.alertSrv.confirm(
        'Cancel item?',
        confirmMsg
      ).pipe(
        filter(d => !!d),
        mergeMap(() => this.ordersSrv.dispatch('cancelItem', this.order.id, this.listing.id)),
        map(() => {
          this.item.isCancelled = true;
          this.buyerCanCancel = false;
          this.alertSrv.successToast('Order item canceled');
          this.canceled.emit();
        }),
        take(1)
      ).subscribe();
    } else {
      this.alertSrv.alert('Must sign in', 'You must be signed in as the buyer to cancel this item.');
    }
  }

  getDisputeTooltip(item: IOrderDetailOrderItem): String {
    if (item.canDispute) {
      return 'If this item has not been delivered as promised, send us your complaint';
    } else if (!!item.disputeId) {
      return 'There is currently a dispute open for this item';
    } else {
      return 'This item is not yet eligible for dispute';
    }
  }

  getCancelTooltip(): String {
    if (!this.buyerCanCancel) {
      return 'This item has not been shipped within 7 days and is eligible for cancellation';
    }
    return 'Items may only be cancelled if the seller fails to ship within 7 days';
  }

  trackingTooltip(status: TWedzeeTrackingStatus) {
    switch (status) {
      case 'delivered':
        return `The item's been delivered.`;
      case 'failed':
        return `There's an issue with this item. Please contact Wedzee support.`;
      case 'in_transit':
        return `The item has shipped and is on the way to it's final destination.`;
      case 'processing':
        return 'The order has been placed and is waiting for the seller to ship it.';
    }
  }

  formatTracking(status: TWedzeeTrackingStatus): string {
    return !status ? '' : status.split('_').map(i => i[0].toUpperCase() + (i || '').slice(1)).join(' ');
  }
}
