import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxChars'
})
export class MaxCharactersPipe implements PipeTransform {

  constructor(
  ) {}

  transform(value: string, characters: number): any {
    return `${value.slice(0, characters)}${value.length > characters ? '...' : ''}`;
  }

}
