<h1 mat-dialog-content>{{title}}</h1>
<div mat-dialog-content>
  <mat-selection-list #categoriesMatSelectList>
    <mat-list-option *ngFor="let category of allCategories" [value]="category" [selected]="isPreviouslySelected(category.id)">
      {{category.name}}
    </mat-list-option>
  </mat-selection-list>
  <mat-progress-bar *ngIf="isLoading" mode="buffer"></mat-progress-bar>

  <h3 class="mat-headline" *ngIf="!isLoading && allCategories.length === 0">No categories to select yet!</h3>

</div>
<button style="float: left" mat-raised-button mat-dialog-close>
  Cancel
</button>
<button (click)="submitSelections()" style="float: right" color="accent" mat-raised-button>
  Ok
</button>
