<div id="divReviewCarousel" class="swiper-main" *ngIf="loadSlider">
  <div class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper">
      <div class="swiper-slide">          
        <div class="user-review-section">
          <a target="_blank" href="https://www.google.com/maps/contrib/103247289396483847483?hl=en-US&sa=X&ved=2ahUKEwjrw5jw6I_wAhUxU98KHTWhC6AQvvQBegQIARAk">
            <span class="quote">
              <img src="/assets/images/quotation-marks.png" />
            </span>
            <div class="review">
              <div class="review-title">Great!</div>
              Def recommend for selling your wedding items.
            </div>
            <div class="user-review-info">
              <span class="rating">
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
              </span>  
              <label>Google Review</label>
            </div>
          </a>
        </div>
      </div>
      <div class="swiper-slide">          
        <div class="user-review-section">
          <a target="_blank" href="https://www.google.com/maps/contrib/114151012383871837674?hl=en-US&sa=X&ved=2ahUKEwjrw5jw6I_wAhUxU98KHTWhC6AQvvQBegQIARA8">
            <span class="quote">
              <img src="/assets/images/quotation-marks.png" />
            </span>
            <div class="review">
              <div class="review-title">Wedzee is AMAZING!</div>
              Awesome place to shop for everything weddings. My experience was perfect. 
            </div>
            <div class="user-review-info">
              <span class="rating">
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
              </span>  
              <label>Google Review</label>
            </div>
          </a>
        </div>
      </div>
      <div class="swiper-slide">          
        <div class="user-review-section">
          <a target="_blank" href="https://www.google.com/maps/contrib/109100064840497430974?hl=en-US&sa=X&ved=2ahUKEwjrw5jw6I_wAhUxU98KHTWhC6AQvvQBegQIARBW">
            <span class="quote">
              <img src="/assets/images/quotation-marks.png" />
            </span>
            <div class="review">
              <div class="review-title">Made back $2,000!</div>
              Made back $2,000 on previous wedding items. Great customer service as well!
            </div>
            <div class="user-review-info">
              <span class="rating">
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
              </span>  
              <label>Google Review</label>
            </div>
          </a>
        </div>
      </div>
      <div class="swiper-slide">          
        <div class="user-review-section">
          <a target="_blank" href="https://www.google.com/maps/contrib/110815883331517240900?hl=en-US&sa=X&ved=2ahUKEwjrw5jw6I_wAhUxU98KHTWhC6AQvvQBegQIARBj">
            <span class="quote">
              <img src="/assets/images/quotation-marks.png" />
            </span>
            <div class="review">
              <div class="review-title">Very, very happy.</div>
              Every planning bride should check out Wedzee. Very happy and would definitely recommend.
            </div>
            <div class="user-review-info">
              <span class="rating">
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
              </span>  
              <label>Google Review</label>
            </div>
          </a>
        </div>
      </div>
      <div class="swiper-slide">          
        <div class="user-review-section">
          <a target="_blank" href="https://www.google.com/maps/contrib/108932126508814177884?hl=en-US&sa=X&ved=2ahUKEwjrw5jw6I_wAhUxU98KHTWhC6AQvvQBegQIARBw">
            <span class="quote">
              <img src="/assets/images/quotation-marks.png" />
            </span>
            <div class="review">
              <div class="review-title">Sold my items quickly.</div>
              Wedzee was a breeze to use and I was able to sell my items quickly.
            </div>
            <div class="user-review-info">
              <span class="rating">
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
                <img src="/assets/images/review-star.png" />
              </span>  
              <label>Google Review</label>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="swiper-scrollbar"></div>
    <div class="swiper-pagination"></div>
  </div>
  <div class="swiper-btn review-swipper-btn swiper-button-prev" ngStyle.xs="margin-right: 20px;"><img src="/assets/images/LeftArrow_Active.png" /></div>
  <div class="swiper-btn review-swipper-btn swiper-button-next" ngStyle.xs="margin-right: 20px;"><img src="/assets/images/RightArrow_Active.png" /></div>
</div>

