<div class="enter-address-component" *ngIf="userAddresses$ | async as addresses" [ngSwitch]="getSwitch(addresses)">

  <ng-container *ngSwitchCase="'addressValid'">
    <div [innerHtml]="value | streetAdress" class="address-text"></div>
    <button class="btn btn-link" style="margin-bottom: 20px; margin-top: 15px;" (click)="clickAddOrChangeAddress()"> 
      Edit
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'isLoading'">
    <div class="loading-animation address-loading-line" style="width: 45%;"></div>
    <div class="loading-animation address-loading-line" style="width: 80%;"></div>
    <button mat-stroked-button [disabled]="true">
      Change
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'selectingMyAddresses'">
    <mat-radio-group class="address-selection" #addressRadioGroup [selected]="selectListButton" (change)="selectedFromRadio(addresses, $event)">
      <mat-radio-button style="width: 300px; margin-bottom: 8px;" class="text-overflow-ellipsis" *ngFor="let a of addresses" [value]="a.id">
        <span [innerHTML]="a | streetAdress"></span>
      </mat-radio-button>
    </mat-radio-group>

    <br>
    <button class="btn btn-sm" style="margin-top: 10px;" type="button" (click)="clickAddOrChangeAddress(true)">
      <mat-icon>add</mat-icon>
      Add a new address
    </button>

  </ng-container>

  <ng-container *ngSwitchCase="'enteringAddress'">
    <form [formGroup]="nameForm">
      <mat-form-field class="full-width" *ngIf="nameForm.get('name') as ctrl">
        <mat-label>Full name</mat-label>
        <input placeholder="Enter first and last name..." matInput formControlName="name" />
        <mat-error *ngIf="ctrl.invalid">*Please enter a name for shipping</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width" *ngIf="nameForm.get('email') as ctrl">
        <mat-label>Email</mat-label>
        <input placeholder="Enter your email address..." matInput formControlName="email" />
        <mat-error *ngIf="ctrl.invalid">*Please enter an email address for shipping</mat-error>
      </mat-form-field>
    </form>

    <form *ngIf="autoCompleteForm" [formGroup]="autoCompleteForm" class="full-width">
      <mat-form-field *ngIf="autoCompleteForm.get('searchText') as ctrl" class="full-width">
        <mat-label>Address</mat-label>
        <input (keyup)="keyupAddressField($event)" (focus)="initGooglePlaces()" #searchText formControlName="searchText" matInput placeholder="Enter an address..." type="text">
        <button mat-button *ngIf="ctrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="ctrl.setValue(undefined);">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-error *ngIf="!!isGooglePlaceInvalid">*Please enter a valid street address</mat-error>
    </form>

    <form *ngIf="addressForm as form" [formGroup]="addressForm" class="full-width">
      <mat-form-field *ngIf="form.get('street2') as ctrl" class="full-width">
        <mat-label>Apartment/Suite #</mat-label>
        <input formControlName="street2" matInput placeholder="Apartment/suite number" type="text">
      </mat-form-field>

      <mat-form-field *ngIf="form.get('city') as ctrl" class="full-width">
        <mat-label>City</mat-label>
        <input formControlName="city" matInput placeholder="Enter city..." type="text">
        <mat-error *ngIf="!ctrl.valid">*Please enter a city</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="form.get('state') as ctrl" style="display: inline-block; width: 50%; padding-right: 12px;">
        <mat-label>State</mat-label>
        <mat-select id="enter-address-state" formControlName="state">
          <mat-option *ngFor="let a of stateAbbreviations" [value]="a">{{a}}</mat-option>
        </mat-select>
        <mat-error *ngIf="!ctrl.valid">*Please select a state</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="form.get('city') as ctrl" style="display: inline-block; width: 50%; padding-left: 12px;">
        <mat-label>Zip code</mat-label>
        <input formControlName="zip" matInput placeholder="Enter zip code..." type="text">
        <mat-error *ngIf="!ctrl.valid">*Please enter a zip code</mat-error>
      </mat-form-field>

      <div *ngIf="newAddressInvalid" style="padding-top: 8px; padding-bottom: 8px;">
        <mat-error >* The address you entered was not found. Please try again.</mat-error>
      </div>
      <ng-container *ngIf="!hideSaveButton" [ngSwitch]="isBilling ? 'billing' : isShipping ? 'shipping' : 'neither'">
        <div class="form-btn-row">
          <button style="float: right;" type="button" class="btn btn-purple" [disabled]="disableSubmit()" color="accent" (click)="updateAddress(value, true)">
            <ng-container *ngIf="showNextBtn">
              <span *ngSwitchCase="'shipping'">
                {{ isDialog ? 'Save address' : 'Next'}}
              </span>
            </ng-container>
            <ng-container *ngIf="!showNextBtn">
              <span *ngSwitchCase="'shipping'">
                {{ isDialog ? 'Save address' : 'Ship here'}}
              </span>
            </ng-container>
            <span *ngSwitchCase="'billing'">Bill to this address</span>
            <span *ngSwitchCase="'neither'">Save address</span>
          </button>

          <button style="float: left; min-width:0px; padding-left:0px; padding-right: 0px;" type="button" mat-flat-button (click)="clickedCancelEnterAddress()">
            Back
          </button>
        </div>
      </ng-container>

    </form>
  </ng-container>
</div>

