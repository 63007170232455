import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';

import { AuthService } from '../../services/auth/auth.service';
import { Globals } from './../../classes';
import { wzCatchObservableError } from './../../services/logging/logging.service';

interface IAuthHandlerQueryParams {
  mode: 'verifyEmail' | 'resetPassword';
  lang: 'en';
  apiKey: string;
  oobCode: string;
}

interface ICheckCodeResponse { data: { email: string, fromEmail: any }; operation: 'VERIFY_EMAIL'; }


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-auth-handler',
  templateUrl: './auth-handler.component.html',
  styleUrls: ['./auth-handler.component.scss']
})
export class AuthHandlerPageComponent implements OnInit {
  queryParams: IAuthHandlerQueryParams;
  checkCodeResponse: ICheckCodeResponse;

  verificationStatus: string;
  isCodeExpired: boolean;
  isMode = {
    verifyEmail: false,
    resetPassword: false,
  };

  isFormSubmitted: boolean;

  userEmail: string;

  resetPasswordForm: FormGroup;
  passwordRequirements = Globals.passwordRequirements;

  constructor(
    private formBuilder: FormBuilder,
    private authSrv: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const self = this;
    self.queryParams = <IAuthHandlerQueryParams>this.route.snapshot.queryParams;
    this.isMode.verifyEmail = self.queryParams.mode === 'verifyEmail';
    this.isMode.resetPassword = self.queryParams.mode === 'resetPassword';
    if (this.isMode.verifyEmail) {
      this.initVerifyEmail(self.queryParams);
    } else if (this.isMode.resetPassword) {
      this.initResetPassword(self.queryParams);
    }
  }

  initVerifyEmail(params: IAuthHandlerQueryParams) {
    const self = this;
    self.authSrv.checkEmailVerificationCode(params.oobCode).pipe(
      mergeMap((response: ICheckCodeResponse) => {
        self.checkCodeResponse = response;
        return self.authSrv.applyEmailVerificationCode(params.oobCode);
      }),
      map(() => self.verificationStatus = 'verified'),
      wzCatchObservableError('verify-email-page.component.ts', 'ngOnInit()'),
      catchError((error: any) => {
        self.verificationStatus = 'invalid';
        if (error.code === 'auth/invalid-action-code') {
          // The action code is invalid. This can happen if the code is malformed, expired, or has already been used.
        }
        if (error.code === 'auth/expired-action-code') {
          self.isCodeExpired = true;
        }
        return observableOf(undefined);
      })
    ).pipe(take(1)).subscribe();
  }

  initResetPassword(params: IAuthHandlerQueryParams) {
    const self = this;
    return self.authSrv.verifyPasswordResetCode(params.oobCode).pipe(
      map((verifiedEmail: string) => {
        self.userEmail = verifiedEmail;
        self.resetPasswordForm = self.formBuilder.group({
          email: [{ value: self.userEmail, disabled: true }],
          password: [undefined, [Validators.required, Globals.passwordValidator()]],
          confirmPassword: [undefined, [Validators.required, Globals.passwordValidator(), (control: AbstractControl) => self.confirmPasswordValidator(control)]]
        });

      }),
      catchError((error: any) => {
        if (error.code === 'auth/expired-action-code') {
          self.isCodeExpired = true;
        }
        return observableOf(undefined);
      })
    ).subscribe();
  }

  confirmPasswordValidator(control: AbstractControl): ValidatorFn {
    const self = this;
    let validationErrors = null;
    if (!!self.resetPasswordForm && control.value !== self.resetPasswordForm.get('password').value) {
      validationErrors = { doesNotMatch: true };
    }
    return validationErrors;
  }


  submitResetPasswordForm(): void {
    const self = this;
    self.authSrv.resetPassword(self.queryParams.oobCode, self.resetPasswordForm.get('password').value).pipe(
      map((res: any) => {
        self.isFormSubmitted = true;
      })
    ).subscribe();
  }

  isLoggedIn() {
    return Globals.user !== undefined && Globals.user.isLoggedIn();
  }

  signIn() {
    AuthService.presentLoginDialog$.next('signIn');
  }
}
