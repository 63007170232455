<nav class="sticky-on-mobile" style="text-align: center; max-width: 100vw!important;">
  <mat-toolbar  [ngSwitch]="!!isAdminApp()" color="primary" style="margin-top:0px !important; margin-bottom:10px !important; width:100%;"
  [ngClass]="{'nav-iframe': isInIframe() == true}">
    <a *ngSwitchCase="false" mat-icon-button (click)="openBottomMenu()"  class="hamburgervertical" [ngClass]="{'menu-iframe': isInIframe() == true}"
      [fxShow.gt-md]="false" [fxShow.lt-xl]="false" [fxShow.lt-md]="true">
      <mat-icon *ngIf="isInIframe() === false">menu</mat-icon>
    </a>

    <ng-container *ngSwitchCase="true">
      <button *ngIf="isUserLoggedIn()" (click)="getDrawer()?.toggle()" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
    </ng-container>

    <img routerLink="/" class="logo-img logoposition"
      [src]="logoImgSrc" *ngIf="isInIframe() === false"
      />

    <button mat-stroked-button  *ngIf="isInIframe() === true"
            class="btn-back" style="font-weight: bold !important;"
            (click)="back()">
      <i class="fas fa-arrow-left"></i> Back
    </button>

    <span *ngSwitchCase="true">Admin</span>

    <span style="margin-left: 20px !important; margin-right: 50px !important; width: 45% !important; min-width: 200px !important;" fxHide.lt-md>
      <wz-search fxFlex="auto" *ngIf="!isAdminApp()" fxShow.lt-md="false"></wz-search>
    </span>


    <span *ngIf="isDev" class="dev">DEV</span>
    <span *ngIf="isBeta" class="beta">BETA</span>


    <span style="flex: 1 1 auto;"></span>

    <div class="right-btn-container" style="margin-right: 20px !important;">
      <div style="border-right:2px solid #E0E0E0;margin-right:20px;padding-right:35px;display:inline-flex;">
        <button class="wdz-btn" mat-flat-button fxHide.lt-md *ngIf="!isAdminApp() && !isASeller()"
                wzMustSignIn
                activity="enroll as a seller." [onSignedIn]="becomeASeller(router)">
          Start Selling
        </button>
      </div>

      <a mat-button fxHide.gt-sm fxHide.lt-md *ngIf="!isAdminApp() && !isASeller()" wzMustSignIn activity="enroll as a seller."
         [onSignedIn]="becomeASeller(router)" style="margin-left: -20px;" ngStyle.xs="margin-left:-50px"> Start selling</a>

      <a mat-button fxHide.lt-md *ngIf="!isUserLoggedIn() && !isAdminApp()" class="wdz-link" (click)="openAuthentication(true)">Register</a>


      <button mat-stroked-button
              class="btn-signin" style="font-weight: bold !important;"
              *ngIf="!isUserLoggedIn()" (click)="openAuthentication()">
        Sign In
      </button>
    </div>

    <ng-container *ngIf="isUserLoggedIn()">
      <button mat-button class="user-button" style="height: 100%; max-width: 100px; padding:0;"
        [ngClass]="{'user-button-iframe': isInIframe() == true}"
        *ngIf="getUser() as user" [matMenuTriggerFor]="userDropdown"
        [matBadge]="sellerNotifications?.length > 0 ? sellerNotifications?.length : ''">
        <img style="margin-left: 15px; max-height: 40%; border-radius: 50%;"
          [src]="user.photoURL || defaultUserImgSrc" />
        <mat-icon class="caret">arrow_drop_down</mat-icon>
        <mat-menu #userDropdown overlapTrigger="false" yPosition="below" xPosition="after">
          <div style="width: 190px; padding: 15px; text-align: left; display: block!important;">
            <img style="height: 35px; border-radius: 50%; margin: 8px; float: left;"
              [src]="user.photoURL || defaultUserImgSrc" />
            <div>
              <h4 class="mat-subheading-1 no-margin no-padding">
                @{{ user.username }}
              </h4>
              <div class="mat-caption no-margin no-padding">{{
                  user.email
                }}</div>
            </div>
          </div>
          <div style="padding-bottom: 4px; display: block!important;">
            <div style="height: 35px; border-radius: 50%; margin: 2px; float: left; margin-left: 13px;"></div>
            <button color="accent" mat-flat-button routerLink="profile">
              My Account
            </button>
          </div>
          <button mat-menu-item routerLink="my-orders"><span>My Purchased Items</span></button>
          <ng-container [ngSwitch]="isASeller()">
            <div *ngSwitchCase="true">
              <button class="seller-btn" mat-menu-item routerLink="seller/home">
                <span style="padding-right: 20px;"
                  [matBadge]="sellerNotifications?.length > 0 ? sellerNotifications?.length : ''"
                  matBadgePosition="above after">
                  My Seller Dashboard
                </span>
              </button>
              <a mat-menu-item style="padding-left: 30px" *ngFor="let l of sellerMenuLinks" [routerLink]="l.routerLink"
                routerLinkActive="active-link">
                {{l?.name}}
              </a>
            </div>
            <button *ngSwitchCase="false" mat-menu-item routerLinkAbsolute="become-a-seller"><span>Start
                Selling</span></button>
          </ng-container>
          <hr />
          <button mat-menu-item (click)="signOut()"><span>Sign Out</span></button>
        </mat-menu>
      </button>
    </ng-container>
    <wz-shopping-cart-button class="shoppingcartbuttonposition" >
    </wz-shopping-cart-button>
  </mat-toolbar>
  <div *ngIf="!isAdminApp() && isInIframe() === false">
    <wz-navbar-menu  fxHide.sm fxHide.xs fxshow.xl fxShow.lg></wz-navbar-menu>
  </div>

  <div *ngIf="!isAdminApp()" [fxShow.gt-sm]="false"    style="width: 100vw!important; text-align: center; margin-top: -11px !important;" >
    <mat-toolbar color="primary" class="mat-elevation-z2"
      style="background-color: white!important; height: 55px!important; width: 100vw!important; text-align: center;">
      <wz-search [mobile]="true" class="searchpanelposition" [ngClass]="{'searchpanelpositioniframe': isInIframe() == true}"></wz-search>
      <!-- <span style="flex: 1 1 auto;"></span>
      <wz-shopping-cart-button fxHide ></wz-shopping-cart-button> -->
    </mat-toolbar>




  </div>
 
</nav>
