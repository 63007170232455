<div class="product-category-container" [ngClass]="{'product-category-container-iframe': isInIframe() == true}">
	<!-- Product Category Slider -->
	<div class="product-category-slider" *ngIf="screenWidth != 0 && screenWidth < 767"> 
		<drag-scroll [drag-scroll-y-disabled]="true">
			<ul>
				<li [ngClass]="{'active': category.active}" (click)="activeTab(category)" *ngFor="let category of mainMenuCategories" [routerLinkAbsolute]="category | categoryUrl">{{category.name}}</li>
			</ul>
		</drag-scroll> 
	</div> 
	<!-- <div class="product-category-slider" fxHide.gt-xs>
		<ul>
			<li *ngFor="let category of mainMenuCategories" [routerLinkAbsolute]="category | categoryUrl">{{category.name}}</li>
		</ul>
	</div> -->
	<!-- Product Page Title -->
	<div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px" class="full-width product-header-section text-left">
		<div fxFlex.gt-xs="1 1 230px" fxShow.lt-lg="false"></div>
		<div fxFlex="1 1 80%" fxFlex.lt-md="1 1 100%" class="product-title-row">
			<ng-container *ngIf="(queryParams$ | async) as p">
				<div *ngIf="p.categoryId" class="mat-display-1 header" fxLayout="row" fxLayout.lt-sm="column-reverse">
					<ng-container>{{getCategory(p.categoryId).name}} </ng-container>
					<span>({{listingQueryResult.numOfResults$ | async}} results)</span>
				</div>
				<div *ngIf="p.searchText" style="margin-bottom: 30px; margin-left: 6px;"
					ngStyle.xs="margin-bottom: 30px; margin-left: 8px;" class="mat-title">Results matching
					"{{p.searchText}}"</div>
			</ng-container>
			<div *ngIf="(mainColor$ | async) as c" class="mat-display-1 header" fxLayout="row" fxLayout.lt-sm="column-reverse">
				<ng-container>{{c.name + ' weddings'}} </ng-container> <span>({{listingQueryResult.numOfResults$ | async}} results)</span>
			</div>
			<div *ngIf="isNewArrivals" class="mat-display-1 header" fxLayout="row" fxLayout.lt-sm="column-reverse">
				<ng-container>New arrivals </ng-container> <span>({{listingQueryResult.numOfResults$ | async}} results)</span>
			</div>
		</div>
		<div fxFlex="1 1 20%" fxFlex.lt-md="1 1 100%" class="product-filter-row">
			<button class="border-btn" style="float:right;" (click)="openBottomSheetFilter()" [fxShow.gt-sm]="false">
				Filter
			</button>
			<mat-select class="relevance-dropdown" ngStyle.lt-md="float: left; margin-left: 5px;" [value]="selectedOrderBy"
				style="border-radius:5px;float:right;max-width:150px;padding:8px;text-align:left;"
				(selectionChange)="updateQueryParams({ orderBy: $event.value })">
				<mat-option value="relevance">Relevance</mat-option>
				<mat-option value="priceLowToHigh">Price low to high</mat-option>
				<mat-option value="priceHighToLow">Price high to low</mat-option>
				<mat-option value="mostRecent">Most recent</mat-option>
			</mat-select>
		</div>
	</div> 

	<!-- Product Page Content -->
	<div class="full-width text-left product-list-section" fxLayout="row" fxLayoutGap="30px">

		<!-- Product Filter --> 
		<div fxFlex.gt-xs="1 1 230px" fxShow.lt-md="false">
			<wz-product-list-filter #productListFilter [initialParams]="queryParams$ | async" (updated)="updateQueryParams($event)">
			</wz-product-list-filter>
		</div>

		<!-- Product List -->
		<ng-container *ngIf="(listingQueryResult.listings$ | async) as listings"
			[ngSwitch]="!!(listingQueryResult.isLoading$ | async) ? 'isLoading' : 'isNotLoading'">
			<div fxFlex="1 1 100%" #productListContainer ngStyle.xs="text-align:center;"
				style="text-align: left!important; margin-top:-8px;">
				<div *ngSwitchCase="'isLoading'" class="full-width text-center product-list-container">
					<div class="product-item" *ngFor="let p of getSkeletonProducts()">
						<wz-product-thumb [isSkeleton]="true"></wz-product-thumb>
					</div>
				</div>
				<ng-container *ngSwitchCase="'isNotLoading'">
					<div *ngIf="!listings || (!!listings && listings.length === 0)" class="full-width text-center"
						style="padding-top: 100px; margin-bottom: 100px;">
						<div class="not-found-text">
							No items found
						</div>
					</div>
					<div class="product-list-container">
						<div class="product-item" *ngFor="let p of listings">
							<wz-product-thumb [doc]="p" [id]="p.id"></wz-product-thumb>
						</div>
						<div class="product-item product-full-width" fxHide.gt-sm style="margin-top: 10px;">
							<div class="product-info-block">
								<h2>Buy and Sell All Things Weddings On Wedzee.</h2>
								<h4>Browse Over 100 Unique Categories</h4>
								<p>Find exactly what you need for the day of your wedding.</p>
								<h4>Products Protected by Wedzee Guarantee</h4>
								<p>Buyer and seller protections ensures hassle-free transactions.</p>
								<!-- <h4>We Suggest Products for Your Style</h4>
								<p>Take our style quiz and tell us what you’re looking for. We’ll suggest products based on your answers.</p> -->
							</div>
						</div>
					</div>
					<mat-paginator [hidePageSize]="true" [length]="listingQueryResult.numOfResults$ | async"
						[pageSize]="(queryParams$ | async).pageSize" [pageIndex]="(queryParams$ | async).pageIndex"
						(page)="updateQueryParams({ pageIndex: $event.pageIndex, pageSize: $event.pageSize }); scrollTop()">
					</mat-paginator>
				</ng-container>
			</div>
		</ng-container>
	</div>
</div>