<ng-container *ngIf="!isAdminApp()">
  <ng-container [ngSwitch]="!!(numOfItems > 0)">
    <button
      wzDebounceHover
      (debounceHoverEnter)="!!cartTrigger ? cartTrigger.openMenu() : undefined"
      [matMenuTriggerFor]="shoppingCartDropdown"
      mat-icon-button
      (click)="goToCart()"
    >
      <mat-icon *ngSwitchCase="false">shopping_cart</mat-icon>
      <mat-icon *ngSwitchCase="true" [matBadge]="numOfItems" matBadgeColor="accent">shopping_cart</mat-icon>
    </button>
  </ng-container>
  <mat-menu
    backdropClass="cart-menu-backdrop"
    #shoppingCartDropdown
    overlapTrigger="false"
    yPosition="below"
    xPosition="before"
  >
    <div *ngIf="numOfItems > 0" (mouseleave)="cartTrigger.closeMenu();" class="no-margin no-padding">
      <mat-list>
        <mat-list-item *ngFor="let i of cartItems" [routerLink]="['product', i?.listing?.id]" routerLinkActive="active-link" class="wz-menu-item">
          <img matListAvatar style="border-radius: 0!important;" [src]="i?.listing?.thumbnailImg" alt="">
          <h3 matLine> <b>{{i?.listing?.name}}</b> </h3>
          <p matLine>
            <span class="text-overflow-ellipsis"> {{i?.listing?.description}} </span>
          </p>
        </mat-list-item>
      </mat-list>
      <div class="full-width children-inline-block">
        <button mat-button (click)="goToCart()" color="accent" style="text-align: left; font-size: large; width: 100%; margin: 8px auto; border-radius: 0;">
          Checkout
          <span style="float: right!important; color: black;margin-left: 20px;">Subtotal ${{subTotal | number:'1.2-2'}}</span>
        </button>
      </div>
    </div>
  </mat-menu>
</ng-container>
