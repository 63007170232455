import { CommonService } from './../../services/common/common.service';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation  } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { delay, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { ISellerNotification } from 'wz-types';

import { Globals } from '../../classes/global.class';
import { AuthService } from '../../services/auth/auth.service';
import { BottomSheetMenuComponent } from './../bottom-sheet-menu/bottom-sheet-menu.component';
declare var $: any;
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-navbar',
  templateUrl: './wz-navbar.component.html',
  styleUrls: ['./wz-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WzNavbarComponent implements OnInit {
  public static drawer: any;
  logoImgSrc = 'assets/wdz-logo.png';
  defaultUserImgSrc = 'assets/img-default/user.jpg';
  isDev: boolean;
  isBeta: boolean;
  sellerNotifications: ISellerNotification[] = [];
  sellerMenuLinks = Globals.sellerDashboardMenuLinks;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  constructor(
    private authSrv: AuthService,
    private bottomSheet: MatBottomSheet,
    private commonService: CommonService,
    public router: Router
  ) { }

  public back(): void {
    history.go(-1);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!!this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }

  ngOnInit() {
    const self = this;
    self.isDev = ['development', 'local-development'].includes(Globals.environment.environmentName);
    self.isBeta = Globals.environment.environmentName === 'beta';
    merge(Globals.sellerAccountInstantiated$, Globals.sellerAccountUpdated$).pipe(
      delay(1000),
      filter(() => !!Globals.user && !!Globals.user.SellerAccount && !!Globals.user.SellerAccount.id),
      mergeMap(() => Globals.user.SellerAccount.getNotifications()),
      map((notifications: ISellerNotification[]) => this.sellerNotifications = notifications),
      takeUntil(Globals.destroy$)
    ).subscribe();

    AuthService.presentLoginDialog$.pipe(
      map((method: 'signIn' | 'register') => {
        const isRegistration = method === 'register';
        self.openAuthentication(isRegistration);
      }),
      takeUntil(Globals.destroy$)
    ).subscribe();
  }

  public isInIframe(): boolean {
    return this.commonService.isInIframe();
  }

  isUserLoggedIn() {
    return !!Globals.user && !!Globals.user.email;
  }

  isASeller() {
    return !!Globals.user && !!Globals.user.SellerAccount && !!Globals.user.SellerAccount.hasAcknowledgedSellerAgreement;
  }

  becomeASeller(router: Router) {
    return () => {
      router.navigateByUrl('/become-a-seller');
    };
  }

  isAdminApp() {
    return Globals.isAdminApp;
  }

  getUser() {
    return Globals.user;
  }

  getDrawer() {
    return WzNavbarComponent.drawer;
  }

  openBottomMenu(): void {
    const self = this;
    self.bottomSheet.open(BottomSheetMenuComponent);
  }

  openAuthentication(isRegistration?: boolean) {
    this.commonService.openAuthentication(isRegistration);
  }

  signOut() {
    this.authSrv.signOut().pipe(
      take(1),
      map(() => {
        if (this.isInIframe()) {
          this.router.navigateByUrl('categories').then(() => location.reload());
        } else {
          this.router.navigateByUrl('').then(() => location.reload());
        }
      })
    ).subscribe();
  }
}
