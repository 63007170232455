import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuerySnapshot } from '@angular/fire/firestore';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IAdminOrderDetail, ILabelWithOrderItems, IOrderDataFromApi, IWedzeeOrder } from 'wz-types/orders';

import { AppStore, FirestoreRefs, Globals } from '../classes';
import { wzCatchObservableError } from '../services/logging/logging.service';


export type TOrderAction = 'cancelItem' |
  'getOrdersByIds' | 'getOrdersByListingId' | 'getOrdersBySeller' | 'getAllOrders' | 'searchOrders' | 'updateShipping' | 'getPublicOrder'
    | 'getAdminOrderDetail' | 'updateAdminNotes';

@Injectable()
export class OrdersStore extends AppStore<IWedzeeOrder, TOrderAction> {
    private fileName = 'orders.store.ts';

    constructor(
        private http: HttpClient
    ) {
        super({
            objectName: 'order',
            objectIdKey: 'id',
            objectGetFn: (id: string) => this.getOrderFromServer(id)
        });

        this.registerAction('getOrdersByIds', {
            type: 'get',
            dispatch: (ids: string[]) => forkJoin(ids.map(id => this.get(id))),
            reduce: r => r,
            map: r => r
        });

        this.registerAction('getOrdersBySeller', {
            type: 'get',
            dispatch: (sellerId: string) => from(FirestoreRefs.orders.ref.where('sellerUserIds', 'array-contains', sellerId).get()).pipe(
                map((snap: QuerySnapshot<IWedzeeOrder>) => snap.docs.map(d => d.data())),
                wzCatchObservableError(this.fileName, 'getOrder()')
            ),
            reduce: r => r,
            map: r => r
        });

        this.registerAction('getAllOrders', {
            type: 'get',
            dispatch: (itemsPerPage?: number, pageNumber?: number) => this.http.get(
                `${Globals.environment.apiUrl}orders/paginate/${encodeURI(JSON.stringify({
                    itemsPerPage: itemsPerPage || 20,
                    pageNumber: pageNumber || 0
                }))}`
            ) as any,
            reduce: (r: { orders: IWedzeeOrder[], total: number; }) => r.orders,
            map: r => r
        });

        this.registerAction('searchOrders', {
            type: 'get',
            dispatch: (searchBy: string, text: string) => this.http.get(
                `${Globals.environment.apiUrl}orders/search/${encodeURI(searchBy)}/${encodeURI(text)}`
            ),
            reduce: (r: IWedzeeOrder[]) => r,
            map: r => r
        });


        this.registerAction('getPublicOrder', {
            type: 'get',
            dispatch: (orderId: string, billingZip: string) => this.http.get(`${Globals.environment.apiUrl}orders/public/${orderId}/${billingZip}`).pipe(
                wzCatchObservableError(this.fileName, 'getPublicOrder()')
            ),
            reduce: (r: IOrderDataFromApi) => r.order,
            map: r => r
        });

        this.registerAction('cancelItem', {
            type: 'get',
            dispatch: (orderId, listingId) => this.http.get(
                `${Globals.environment.apiUrl}order-items/cancel/${orderId}/${listingId}`
            ) as any,
            reduce: (r: { orders: IWedzeeOrder[], total: number; }) => r.orders,
            map: r => r
        });

        this.registerAction('getOrdersByListingId', {
            type: 'get',
            dispatch: (listingId) => from(FirestoreRefs.orders.ref.where('listingIds', 'array-contains', listingId).get()).pipe(
                map((qSnap: QuerySnapshot<IWedzeeOrder>) => qSnap.docs.map(d => d.data()))
            ) as any,
            map: r => r
        });

        this.registerAction('updateAdminNotes', {
            type: 'change',
            dispatch: (orderId: string, adminNotes: string) => this.http.post(
                `${Globals.environment.apiUrl}orders/update-admin-notes/${orderId}`, {
                    adminNotes
                }
            ),
            reduce: (r: IWedzeeOrder[]) => r,
            map: r => r,
            discardResponse: true
        });
    }

    public getAdminOrderDetail(orderId: String): Observable<IAdminOrderDetail> {
        return <any>this.http.get(`${Globals.environment.apiUrl}orders/${orderId}/admin-detail`).pipe(
            wzCatchObservableError(this.fileName, 'getAdminOrderDetail()')
        );
    }

    /**
    * Used to validate order data entered by users. If valid, returns true. If not, false.
    */
    public validatePublicOrder(orderId: string, billingZip: string): Observable<boolean> {
        return <any>this.http.get(`${Globals.environment.apiUrl}orders/public/validate/${orderId}/${billingZip}`).pipe(
        map((res: { isValid: boolean }) => res.isValid),
        catchError((e: any) => of(false))
        );
    }

    public getLabelsWithItems(orderId: string, sellerId): Observable<ILabelWithOrderItems[]> {
        return this.http.get(`${Globals.environment.apiUrl}orders/labels-with-items/${orderId}/${sellerId}`).pipe(
        wzCatchObservableError(this.fileName, 'getLabelsWithItems()')
        );
    }

    private getOrderFromServer(id: string): Observable<IWedzeeOrder> {
        return from(FirestoreRefs.orders.doc(id).get()).pipe(
            map(s => s.data() as any)
        );
    }
}
