import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-free-shipping',
  templateUrl: './free-shipping.component.html',
  styleUrls: ['./free-shipping.component.scss']
})
export class FreeShippingComponent implements OnInit {
  @Input() customText = 'FREE shipping';

  constructor() { }

  ngOnInit() {
  }

}

