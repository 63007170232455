import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISellerProfileData, ISellerUser } from 'wz-types';

import { AppStore, Globals } from '../classes';
import { ListingsStore } from './listings.store';

type TSellerAction = 'getSellerProfileData';

@Injectable()
export class SellersStore extends AppStore<ISellerUser, TSellerAction> {
    private cacheHeaders: HttpHeaders;

    constructor(
        private http: HttpClient,
        private listingsStore: ListingsStore
    ) {
        super({
            objectName: 'seller',
            objectIdKey: 'id',
            objectGetFn: (id: string) => this.http.get(`${Globals.environment.apiUrl}seller/${id}`) as any
        });
        const apiUrl = Globals.environment.apiUrl;
        this.cacheHeaders = new HttpHeaders({ cacheInMemory: '1' });

        this.registerAction('getSellerProfileData', {
            type: 'get',
            dispatch: (idOrName: string) => this.http.get(`${apiUrl}page-data/seller-profile/${idOrName}`),
            reduce: (d: ISellerProfileData) => {
                this.listingsStore.insert(d.listings);
                const result = { ...d, listings: null };
                return result;
            },
            map: (r) => r
        });
    }
}
