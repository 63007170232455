import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wzDebounceHover]'
})
export class DebounceHoverDirective implements OnInit {
  @Output() debounceHoverEnter = new EventEmitter();
  @Output() debounceHoverLeave = new EventEmitter();
  private hoverEnter$ = new Subject();

  ngOnInit() {
      this.hoverEnter$.pipe(
          debounceTime(150),
          takeUntil(this.debounceHoverLeave),
          map((e: any) => {
              this.debounceHoverEnter.emit(e);
            })
      ).subscribe();
  }


  @HostListener('mouseenter', ['$event'])
  hoverEnterEvent(event) {
      this.hoverEnter$.next(event);
  }

  @HostListener('mouseleave')
  hoverLeaveEvent() {
      this.debounceHoverLeave.next();
      this.ngOnInit();
  }
}
